import React, { ChangeEvent } from 'react'
import { Button, Label,SelectDropdown } from "@sentaraui/optimahealth_web";
import './SearchFormStyle.scss';
import { SearchFormProps } from '../interface/SearchForm';
import AutoComplete from '../autocomplete/Autocomplete';
import { useAutoCompleteCityViewModel, useAutoCompleteLocationViewModel, useAutoCompletePraticeNameViewModel, useAutoCompleteZipcodeViewModel } from '../hooks/useAutocomplete';


const SearchForm: React.FC<SearchFormProps> = ({ onSearch, sendValueToHome, autoCompleteStateData, OPTION, formData, setFormData  }) => {

    const { fetchPraticeNameData } = useAutoCompletePraticeNameViewModel();
    const { fetchLocationData } = useAutoCompleteLocationViewModel();
    const { fetchCityData } = useAutoCompleteCityViewModel();
    const { fetchZipcodeData } = useAutoCompleteZipcodeViewModel();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData:any) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        onSearch(formData);
        sendValueToHome(String(true));
    };


    const handleAutoCompleteSelect = (name: string, value: string) => {
        setFormData((prevData:any) => ({ ...prevData, [name]: value }));
    };

    const handlePracticSearch = async (value: any, callBack: any) => {
        if (value === '') return;
        let autoCompletePracticeList = await fetchPraticeNameData(value);

        if (!autoCompletePracticeList) {
            return autoCompletePracticeList;
        }
        // just to explain API call
        callBack(autoCompletePracticeList.data);
    }

    const handleCitySearch = async (value: any, callBack: any) => {
        if (value === '') return;
        let autoCompleteCityList = await fetchCityData(value);
        if (!autoCompleteCityList) {
            return autoCompleteCityList;
        }
        // just to explain API call

        // setOptions
        callBack(autoCompleteCityList.data);
    }
    const handleLocationSearch = async (value: any, callBack: any) => {
        if (value === '') return;
        let autoCompleteLocationList = await fetchLocationData(value);
        if (!autoCompleteLocationList) {
            return autoCompleteLocationList;
        }
        // just to explain API call

        // setOptions
        callBack(autoCompleteLocationList.data);
    }

    const handleZipcodeSearch = async (value: any, callBack: any) => {
        if (value === '') return;
        let autoCompleteZipcodeList = await fetchZipcodeData(value);
        if (!autoCompleteZipcodeList) {
            return autoCompleteZipcodeList;
        }
        // just to explain API call

        // setOptions
        callBack(autoCompleteZipcodeList.data);
    }

    return (
        <div>
            <div className='row'>
                <div id='middle-tab-text'>
                    View / Edit Practices
                </div>
            </div>
            <div className='row' id='practise-form'>
                <div className='col-11'>
                    <div className='row' id='first-row'>
                        <div className='col-6 ' id='form-label'>
                            <Label htmlFor="txt-practice" className="menu-label">
                                Practice Name
                            </Label>
                            <AutoComplete
                                id="txt-practice"
                                dataTestId="practise_field"
                                className="form-input"
                                options={OPTION}
                                onSearch={handlePracticSearch}
                                noOptionText='No Option'
                                optionKey='value'
                                optionCount={6}
                                placeholder="Practice Name"
                                onSelect={(value: string) => handleAutoCompleteSelect('practiceName', value)} // Handle selection
                                selectedValue={formData.practiceName}
                            />
                        </div>
                        <div className='col-6' id='location-row'>
                            <Label className="pb-2 menu-label" dataTestId="inputLabel_1" htmlFor="txt-location">
                                Location Name
                            </Label>
                            <AutoComplete
                                id="txt-location"
                                dataTestId="location_field"
                                options={OPTION}
                                className="form-input"
                                noOptionText='No Option'
                                onSearch={handleLocationSearch}
                                optionKey='value'
                                optionCount={6}
                                placeholder='Location Name'
                                onSelect={(value: string) => handleAutoCompleteSelect('locationName', value)} // Handle selection
                                selectedValue={formData.locationName}
                            />
                        </div>
                    </div>
                    <div className='row' id='search-row'>
                        <div className='col-4' id='srch-row'>
                            <Label htmlFor="txt-city" className="menu-label">
                                City
                            </Label>
                            <AutoComplete
                                id="txt-city"
                                dataTestId="city_field"
                                className="form-input"
                                options={OPTION}
                                noOptionText='No Option'
                                onSearch={handleCitySearch}
                                optionKey='value'
                                optionCount={6}
                                placeholder='City'
                                onSelect={(value: string) => handleAutoCompleteSelect('locationCity', value)} // Handle selection
                                selectedValue={formData.locationCity}
                            />
                        </div>
                        <div className='col-4' id='dropdown-row' >
                            <Label htmlFor="txt-dropdown" className="menu-label">
                                State
                            </Label>
                            <SelectDropdown
                                className="form-select form-input"
                                dataTestId="custom-select"
                                id="txt-dropdown"
                                onChange={handleChange}
                                name="locationState"
                                value={formData.locationState}
                            >
                                <React.Fragment key=".0">
                                    <option value="" disabled selected>Select State</option>
                                    {
                                        autoCompleteStateData?.map((optItem: any) => (
                                            <option key={optItem.Id} value={optItem.Id} className='state-value'>
                                                {optItem.value}
                                            </option>
                                        ))
                                    }
                                </React.Fragment>
                            </SelectDropdown>
                        </div>
                        <div className='col-4' id='zip-row'>
                            <Label htmlFor="txt-zip" className="menu-label">
                                Zip
                            </Label>
                            <AutoComplete
                                id="txt-zip"
                                dataTestId="zip_field"
                                options={OPTION}
                                noOptionText='No Option'
                                onSearch={handleZipcodeSearch}
                                optionKey='value'
                                className="form-input"
                                optionCount={6}
                                placeholder='Zip'
                                onSelect={(value: string) => handleAutoCompleteSelect('locationZip', value)} // Handle selection
                                selectedValue={formData.locationZip}
                            />
                        </div>
                    </div>
                </div>
                <div  className='col-1' id='search-btn'>
                <Button className="btn" id="srch-btn" onClick={handleSubmit}>
                    <Label className ='btn-text'>Search</Label>
                </Button>
            </div>
            </div>
        </div>
    )
}

export default SearchForm