import { useEffect, useState } from 'react';
import { useGetPracticeManagersByIdQuery, useRemovePracticeManagerMutation } from '../services/practiceManagersApi';
import { PracticeManager } from '../interface/PracticeManager';
import ToastNotification from '../common/toast/toastNotification';

const REMOVE_PRE_SUCCESS_MESSAGE = "Authoritative Source: ";
const REMOVE_POST_SUCCESS_MESSAGE = " removed successfully.";
const REMOVE_FAILURE_MESSAGE = "Failed to remove authoritative source.";

export const useAuthoritativeSourceViewModel = (practiceId: number | null, onClose: () => void) => {
    const { data, refetch } = useGetPracticeManagersByIdQuery(practiceId);
    const authoritativesSourceData = data ?? [];
    const [isNewModalOpen, setIsNewModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [isItemSelected, setIsItemSelected] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newAuthoritativeSource, setNewAuthoritativeSource] = useState<any>(null);
    const [removePracticeManager] = useRemovePracticeManagerMutation();
    const [selectedItem, setSelectedItem] = useState<any>(null);

    useEffect(() => {
       refetch();
    }, [refetch]);

    const handleRowClick = (id: number, item: PracticeManager) => {
        setSelectedRow(id);
        setSelectedItem(item);
        setIsItemSelected(false);
    };

    const handleRemove = () => {
        setShowModal(true);
        setIsModalOpen(true);
    };

    const confirmRemove = async () => {
        if (practiceId === null || selectedItem === null) return;

        try {
            await removePracticeManager({ practiceId, managerId: selectedItem.personID, mtype: selectedItem.typeId }).unwrap();
            ToastNotification('success',`${REMOVE_PRE_SUCCESS_MESSAGE} ${selectedItem.firstName} ${selectedItem.lastName} ${REMOVE_POST_SUCCESS_MESSAGE}`);
            setIsModalOpen(false);
            onClose();
        } catch (error) {
            ToastNotification('error',`${REMOVE_FAILURE_MESSAGE} ${selectedItem.firstName} ${selectedItem.lastName}`);
        }
    };

    const handleNewClick = () => {
        setIsNewModalOpen(true);
        setNewAuthoritativeSource(practiceId);
    };

    const closeModal = () => {
        setIsNewModalOpen(false);
        onClose();
    };

    const commonHeader = [
        { text: 'Last Name' },
        { text: 'First Name' }
    ];

    return {
        commonHeader,
        authoritativesSourceData,
        isNewModalOpen,
        selectedRow,
        isItemSelected,
        showModal,
        isModalOpen,
        newAuthoritativeSource,
        selectedItem,
        handleRowClick,
        handleRemove,
        confirmRemove,
        handleNewClick,
        closeModal,
        setShowModal,
        setIsModalOpen
    };
};