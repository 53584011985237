import { configureStore } from '@reduxjs/toolkit';
import { practicesApi } from '../services/practicesApi';
import { staffApi } from '../services/staffApi';
import { locationApi } from '../services/locationApi';
import { autoCompleteApi } from '../services/autoCompleteApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import { practiceManagersAPI } from '../services/practiceManagersApi';
import { homeApi } from '../services/homeApi';
import { clinicianDetailsApi } from '../services/clinicianDetailsApi';
import authReducer from '../slices/authSlice';
import { educationApi } from '../education/api/educationApi';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [clinicianDetailsApi.reducerPath]: clinicianDetailsApi.reducer,
    [practicesApi.reducerPath]: practicesApi.reducer,
    [staffApi.reducerPath]: staffApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [autoCompleteApi.reducerPath]: autoCompleteApi.reducer,
    [practiceManagersAPI.reducerPath]: practiceManagersAPI.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [educationApi.reducerPath]: educationApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      practicesApi.middleware,
      staffApi.middleware,
      locationApi.middleware,
      autoCompleteApi.middleware,
      practiceManagersAPI.middleware,
      homeApi.middleware,
      clinicianDetailsApi.middleware,
      educationApi.middleware,
    ),
});
setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
