import { Tabs, TabsItem, Button } from '@sentaraui/optimahealth_web';
import './staffEditPage.scss';
import { useStaffEditViewModel } from './useStaffEditViewModel';
import ClinicianInfo from '../clinicianInfo/clinicianInfo';
import { useEffect, useState } from 'react';
import { IPracticeStaffMember, IStaff } from '../../interface/staff';
import { PhysicianDetails } from '../physicianDetails/physicianDetails';
import HospitalRoles from '../hospitalRoles/hospitalRoles';
import PhysicianProfile from '../physicianProfile/physicianProfile';
import {EditLocationButtons as actionTypes} from '../location/actionTypes';
import Loader from '../../common/spinner/loader';

interface IStaffEditPageProps {
    name: string;
    staffId: number;
    practiceId: number;
    practiceName: string;
    selectedStaff: IStaff;
    onClose: () => void;
}
export const StaffEditPage: React.FC<IStaffEditPageProps> = ({ staffId, practiceId, name, onClose, selectedStaff }) => {
    
     const [practiceStaffMember, setPracticeStaffMember] = useState<IPracticeStaffMember | null>(null);
     const [isSentara,setIsSentara] = useState<boolean>(false);
    const { activeTab, handleTabClick, handleButtonClick, data, isSpiner } = useStaffEditViewModel({ onClose, staffId, practiceId, isSentara, practiceStaffMember });

     const isButtonDisabled = (action: actionTypes) => {
        return action === 'Submit' && !isSentara;
    };
    
    useEffect(() => {
        if (data?.staffInfo && selectedStaff?.personId) {
            // Assuming data is a single object and not an array
            const personId = data.staffInfo.personId;
            if (personId === selectedStaff.personId) {
                setPracticeStaffMember(data); // Assuming `data` itself is the staff member object
            }
        }
    }, [data, selectedStaff?.personId])

    useEffect(() => {
        if (data?.staffInfo && staffId) {
            // Assuming data is a single object and not an array
            const personId = data.staffInfo.personId;
            if (personId === staffId) {
                setPracticeStaffMember(data); // Assuming `data` itself is the staff member object
            }
        }
    }, [data, staffId])
    const tabContent: Record<string, React.ReactNode> = {

        ClinicalInfo: <div className="p-4 pb-3"><ClinicianInfo staffId={staffId} practiceStaffMember={practiceStaffMember} /></div>,
        HospitalRoles: <div className="p-3"><HospitalRoles practiceStaffMember={practiceStaffMember} /></div>,
        PhysicianProfile: <div className="p-3"><PhysicianProfile practiceStaffMember={practiceStaffMember} isSentara={isSentara} setIsSentara={setIsSentara}/></div>,
        PhysicianDetails: <div className="p-4 pb-3"><PhysicianDetails personId= {staffId} practiceStaffMember={practiceStaffMember}/></div>,
        // SQCN: <div className="p-3"><SqcnDetails sqcnDetailsData={practiceStaffMember} /></div>,
        // SMG: <div className='p-3'><Smg practiceStaffMember={practiceStaffMember} /></div>
    };

    return (
        <div data-testId="staff-edit-page" className='main-tab-wrapper'>
            <div className="modal-title" id='title'>
                <span className='staff-prac-name'>Staff Edit: {name}({data?.practiceName})</span>
                <span>Staff ID: {staffId}</span>
                <button id='close-btn' onClick={onClose}>x</button>
            </div>
            <div className='staff-edit-body'>
                <div id='staff-edit-top-tab'>
                    <Tabs
                        activeTab={activeTab}
                        tabConditions={{
                            ClinicalInfo: true,
                            hospitalRoles: true,
                            physicianProfile: true,
                            physicianDetails: true,
                            Smg: true
                        }}
                        tabIdHandler={handleTabClick}
                    >
                        {Object.keys(tabContent).map((tabId: any) => (

                            <TabsItem
                                key={tabId}
                                tabId={tabId}
                                tabName={
                                    tabId === 'SMG' || tabId === 'SQCN' ? tabId : tabId.replace(/([A-Z])/g, ' $1').trim() // Convert camelCase to normal text
                                }
                                onClick={() => handleTabClick(tabId)}
                            />

                        ))}
                    </Tabs>
                </div>
                <div className="tabs-content-below">
                    {tabContent[activeTab]}
                </div>
            </div>
            <hr />
            <div className='button-wrapper'>
            <span className='staff-edit-warning'>This staff member can only be updated through Sentara’s clinical credentialing system..</span>
            {Object.values(actionTypes).map((action) => (
                    <div key={action}>
                        <Button
                            className="list-button"
                            id="primary"
                            data-testid={`staff-${action.toLowerCase()}-button`}
                            onClick={handleButtonClick(action)}
                            disabled={!isSentara && isButtonDisabled(action)}
                        >
                            {action}
                        </Button>
                    </div>
                ))}

            </div>
            {isSpiner && <Loader />}
        </div>
    );
}



