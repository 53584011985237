import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IPhysicianSpecialtyStatusType,IPhysicianExperienceTypeMaster} from '../interface/staff';
        
const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/cliniciandetails/`;
const tag = 'ClinicianDetailsTag';
export const clinicianDetailsApi = createApi({
    reducerPath: 'clinicianDetailsApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
    tagTypes: [tag],
    endpoints: (builder) => ({
        getspecialtystatustypes: builder.query<IPhysicianSpecialtyStatusType, void>({
            query: () => 'getspecialtystatustypes',
            providesTags: [tag],
        }),
        getSpecialtyTypes: builder.query<IPhysicianSpecialtyStatusType, void>({
            query: () => 'getspecialtytypes',
            providesTags: [tag],
        }),
        getExperienceInfoTypes: builder.query<IPhysicianExperienceTypeMaster, void>({
            query: () => 'getexperiencetypes',
            providesTags: [tag],
        }),
    }),
});

export const { useGetspecialtystatustypesQuery, useGetSpecialtyTypesQuery, useGetExperienceInfoTypesQuery} = clinicianDetailsApi;