import { useEffect } from "react";
import { Filters } from "../interface/Filter";
import { useGetPracticesQuery, useLazyGetPracticesQuery, useRemovePracticeMutation } from "../services/practicesApi";
import ToastNotification from "../common/toast/toastNotification";
import { HandleErrorMessage } from "../common/errorMessage";

export const useLazyPracticeViewModelQuery = () => {

    const [fetchPracticeList,{ data, error, isLoading, isSuccess }] = useLazyGetPracticesQuery();
    
    useEffect(()=>{
        if(error){
            HandleErrorMessage(error);
        }
    },[error])

    useEffect(()=>{
        if(isSuccess){
            ToastNotification("success","succcessfully fetched");
        }
    },[isSuccess])

    return { fetchPracticeList,  data, error, isLoading, isSuccess};
}


export const usePracticeViewModelQuery = (filters:Filters, fire:boolean, setFire:(value: boolean) => void,) => {
    
    const  { data, error, isLoading, isSuccess, refetch } = useGetPracticesQuery(filters,{skip:fire});
    useEffect(() =>{
        if(error){
            HandleErrorMessage(error);
        }
    },[error])
    
    useEffect(() => {
        if(isSuccess && data){
            setFire(true);
        }
    },[isSuccess, data, setFire])
    

    return { data,  error, isSuccess, isLoading, refetch };
}

export const usePracticeViewModelDeleteQuery = (practiceId:number | null) => {
    const  [ removePractice ] = useRemovePracticeMutation();

    return { removePractice };
}