import { IEditPractice } from "../components/interface/Practice";
import {
  IPracticeStaffMember,
  IStaffLocationItem,
} from "../components/interface/staff";
import { ILocation } from "../components/models/location/location";

export const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const DAYS_OF_WEEK_SHORT = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];

export const initialLocationState: ILocation = {
  locationID: 0,
  practiceID: 0,
  name: "",
  type: "1",
  effectiveStart: null,
  effectiveStop: null,
  address1: "",
  address2: "",
  address3: "",
  city: "",
  state: "",
  Region: "",
  zip: "",
  phone: "",
  fax: "",
  isPrimary: false,
  publicTrans: false,
  eCare: false,
  myChart: false,
  schedule: [
    { day: "Mon", startTime: "", stopTime: "" },
    { day: "Tue", startTime: "", stopTime: "" },
    { day: "Wed", startTime: "", stopTime: "" },
    { day: "Thu", startTime: "", stopTime: "" },
    { day: "Fri", startTime: "", stopTime: "" },
    { day: "Sat", startTime: "", stopTime: "" },
    { day: "Sun", startTime: "", stopTime: "" },
  ],
  clearArray: false,
  isNew: true,
  internalComments: "",
  externalComments: "",
  labCorpFacilityId: "",
  suppressSentaraDotCom: false,
  isAdmin: false,
  location: "",
  sqcnRegionalServiceOrgId: 0,
  locationSqcn: {
    locationId: 0,
    sqcnRegionalServiceOrgId: null,
    regionalServiceListItems: [],
  },
  smgManagersForLocation: [
    {
      personId: null,
      name: "",
      smgManagerTypeId: null,
      smgManagerType: "",
      locationId: null,
    },
  ],
  locationSmg: {
    locationId: 0,
    smgSpecialtyListItems: [
      {
        disabled: false,
        selected: false,
        text: "",
        value: "",
        group: {
          disabled: false,
          name: "",
        },
      },
    ],
    smgHospitalListItems: [
      {
        disabled: false,
        selected: false,
        text: "",
        value: "",
        group: {
          disabled: false,
          name: "",
        },
      },
    ],
    weatherRegionListItems: [
      {
        disabled: false,
        selected: false,
        text: "",
        value: "",
        group: {
          disabled: false,
          name: "",
        },
      },
    ],
  },
  smgManagerListItems: [
    {
      value: 0,
      text: "",
      managerDetails: {
        smgManagerTypeId: null,
        personId: null,
        name: "",
        locationId: null,
        smgManagerType: "",
      },
    },
  ],
};

export const managerDetails: {
  [key: string]: { managerType: number; typeName: string };
} = {
  "Location Manager": { managerType: 1, typeName: "Location Manager" },
  "Team Coordinator": { managerType: 2, typeName: "Team Coordinator" },
  "MD Lead / PPL": { managerType: 3, typeName: "MD Lead / PPL" },
  "Clinical Chief / AMD": { managerType: 4, typeName: "Clinical Chief / AMD" },
  Director: { managerType: 5, typeName: "Director" },
  "Regional Director": { managerType: 6, typeName: "Regional Director" },
  "Vice President": { managerType: 7, typeName: "Vice President" },
  "Super User": { managerType: 8, typeName: "Super User" },
  "SQCN Care Manager": { managerType: 9, typeName: "SQCN Care Manager" },
  "SQCN Quality Coordinator": {
    managerType: 10,
    typeName: "SQCN Quality Coordinator",
  },
};

export const initialPracticeData: IEditPractice = {
  practiceID: 0,
  name: "",
  description: "",
  practiceType: "",
  taxID: "",
  vendorIds: "",
  suppressSentaraDotCom: false,
  isAdminAD: false,
  superGroupName: "",
  practiceSqcn: {
    mcKessonOrgId: "",
    mcKessons: [],
    mcKesson: 0,
    sqcnCareManagerId: 0,
    emrListItems: [],
    sqcnEmrId: 0,
    deactivationListItems: [],
    sqcnDeactivationReasonId: 0,
    activationDate: new Date(),
    deactivationDate: new Date(),
    claimStatus: false,
    affiliate: false,
    edI837Status: false,
    emrStatus: false,
    deferred: false,
  },
  locations: [],
  superGroups: [],
  superGroupId: null,
};

export const initialStaffLocation: IStaffLocationItem = {
  locationID: 0,
  locationName: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  fax: "",
  isPrimaryLocation: false,
  isPrimaryPractice: false,
  practiceID: 0,
};

export const dateObject = {
  formatString: "",
  text: "",
  date: "",
  dbValue: "",
  isEmpty: false,
  emptyIsMin: false
}

export const initialPracticeStaffMember: IPracticeStaffMember = {
  staffInfo: {
    lastName: "",
    firstName: "",
    fullName: "",
    ssn: "",
    dob: new Date(),
    emailAddress: "",
    phone: "",
    pager: "",
    fax: "",
    staffPhone: "",
    directFax: "",
    gender: [
      {
        gender: "",
      },
    ],
    relationship: [
      {
        relation: "",
      },
    ],
    role: [
      {
        role: "",
      },
    ],
    title: "",
    personId: 0,
    loginId: "",
    sqcnmember: false,
    mktOverride: false,
    inGetProof: false,
    providerPcpflag: false,
    npi: "",
    isEmployee: false,
    isNonEmployee: false,
    isAdminAD: false,
  },
  physicianInfo: {
    ethnicOrigin: "",
    relationship: "",
    languages: [
      {
        code: "",
        description: "",
        epi: 0,
        isDeleted: false,
        isDirty: false,
        isNew: false,
        isValid: false,
        languageId: 0,
        personId: 0,
        physicianLanguageTypeId: 0,
      },
    ],
    privileges: [
      {
        facility: "",
        statusCode: "",
        statusDescription: "",
        appointmentDate: undefined,
        reappointmentDate: undefined,
      },
    ],
    hospitalistRoles: [
      {
        hospitalistId: 0,
        description: "",
        code: "",
        startDate: dateObject,
        endDate: dateObject,
        physicianHospitalTypeId: 0,
      },
    ],
    inactivePracticingStatus: undefined,
    smgStartDate: undefined,
    smgEndDate: undefined,
    smgStatus: "",
    homeAddress: "",
    homeCity: "",
    homeState: "",
    homeZip: "",
    practicingStatus: [{ value: 0, label: "" }],
    since: "",
    bioCard: "",
    status: [{ value: 0, label: "" }],
    commitmentTraining: "",
    safteyChampianTraining: "",
    content: "",
    start: "",
    end: "",
    videoLink: "",
    videoLinkText: "",
    website: "",
    websiteLinkText: "",
    webSiteUrl: "",
    webSiteUrlText: "",
    epi: 0,
    supressFromSentaraDotCom: "",
    videoUrl: "",
    videoUrlText: "",
    photo: "",
    smgContent: "",
    noteList: [
      {
        noteId: 0,
        noteTypeId: 0,
        parentId: 0,
        parentTypeId: 0,
        noteText: "",
        modUserId: 0,
        modDate: new Date(),
      },
    ],
    participatesInResearch: false,
    sentara: false,
    commitmentTrainingDate: undefined,
    safetyChampionTrainingDate: undefined,
    personId: 0,
    isAdminAD: false,
    newAppointmentRequests: false,
  },
  notes: [
    {
      noteId: 0,
      noteTypeId: 0,
      parentId: 0,
      parentTypeId: 0,
      noteText: "",
      modUserId: 0,
      modDate: new Date(),
    },
  ],
  practiceId: 0,
  practiceName: "",
  locationId: 0,
  photoMaxW: "",
  photoMaxH: "",
  newRecord: false,
  sqcnInfo: {
    partyId: "",
    availableRoles: [{ sqcnRoleId: 0, description: "" }],
    currentRoles: [{ sqcnRoleId: 0, description: "" }],
    mcKesson: undefined,
    mcKessons: [{ text: "", value: "" }],
    sqcnActivationDate: undefined,
    sqcnDeactivationDate: undefined,
    sqcnEmail: "",
  },
  smg: {
    fteStatus: "",
    fteStatusValue: 0,
    countType: "",
    podPhoneNumber: "",
    idxNumber: "",
    grouper10Label: "",
    pmb: false,
    ppmb: false,
    comments: "",
    deleted: 0,
    isNewSMG: false,
  },
};