import React from 'react';
import { IPhysicianPrivilegeInfo, IPracticeStaffMember } from '../../../interface/staff';
import './privileges.scss';
import { Table } from '@sentaraui/optimahealth_web';
import {UsePrivilegesViewModel}  from './usePrivilegesViewModel';
interface PrivilegesTableProps {
    practiceStaffMember: IPracticeStaffMember | null;
}

const PrivilegesTable: React.FC<PrivilegesTableProps> = ({ practiceStaffMember }) => {
  const { headerMapping } = UsePrivilegesViewModel();
  const privileges: IPhysicianPrivilegeInfo[] = practiceStaffMember?.physicianInfo.privileges || [];
    return (
      <div className='privilage-table-content'>
        <Table className='privilage-table'
        dataTestId ='privilage-table'
           header = {headerMapping.map((item: any) => {
            return { text: item.name };
          })}
          rows={privileges.map((privilege: IPhysicianPrivilegeInfo) => [
            { text: privilege.facility },
            { text: privilege.statusCode },
            { text: privilege.statusDescription },
            { text: privilege.appointmentDate ? new Date(privilege.appointmentDate).toLocaleDateString() : '' },
            { text: privilege.reappointmentDate ? new Date(privilege.reappointmentDate).toLocaleDateString() : '' }
        ])}
        />
        </div>
    );
};

export default PrivilegesTable;

