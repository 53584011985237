export enum ListLocationButtons {
    Assign = 'Assign',
    Edit = 'Edit',
    Remove = 'Remove',
}
export enum EditLocationButtons {
    Submit = 'Submit',
    Cancel = 'Cancel',
}
export enum RemoveLocationButtons {
    Remove = 'Remove',
    Cancel = 'Cancel',
}