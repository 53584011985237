import { IRemoveStaffLocation, IStaffLocationItem } from "../../../interface/staff";
import { Label,Button } from "@sentaraui/optimahealth_web";
import './removeLocationStyle.scss';
import { RemoveLocationButtons } from "../actionTypes";
import { useStaffApiRemoveLocationHook } from "../../../hooks/useStaffApiHooks";
import { useState } from "react";
import ToastNotification from "../../../common/toast/toastNotification";
import Loader from "../../../common/spinner/loader";
import { HandleErrorMessage } from "../../../common/errorMessage";

interface RemoveLocationProps {
    staffId:number;
    staffName: string;
    onClose: () => void;
    selectedLocation: IStaffLocationItem | null;
}
export const RemoveLocation: React.FC<RemoveLocationProps> = (props) => {
    const [isSpinner, setIsSpinner] = useState<boolean>(false);
    const {removeLocation} = useStaffApiRemoveLocationHook();
    
    const handleButtonClick =  (action: string) => async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (action === 'Cancel') {
            props.onClose();
        }
        if (action === 'Remove') {
            try
            {
                const data: IRemoveStaffLocation = {
                    staffId: props.staffId,
                    staffName: props.staffName,
                    locationId: props.selectedLocation?.locationID ?? 0,
                    locationName: props.selectedLocation?.locationName ?? "",
                    isError:false
                };
                setIsSpinner(true);
                const response:any = await removeLocation(data).unwrap();
                if(response){
                    ToastNotification("success", "data successfully deleted");
                }else{
                    
                    ToastNotification("error", "data not deleted");
                }
            }
            catch (error: unknown) {
                if (error instanceof Error) {
                    HandleErrorMessage(error, 'remove location');
                } else {
                    HandleErrorMessage(new Error('An unknown error occurred'), 'remove location');
                }
            }finally{
                setIsSpinner(false);
                props.onClose();
            }
        }
    }


    return (
        <div>
            <Label className="location-remove-label">
                {`Are you sure you want to remove  ${props.selectedLocation?.locationName}?`}
            </Label>
            <hr />
            <div className='button-wrapper'>
                {Object.values(RemoveLocationButtons).map((action) => (
                    <div key={action}>
                        <Button
                            className="list-button"
                            id="primary"
                            data-testid={`staff-${action.toLowerCase()}-button`}
                            onClick={handleButtonClick(action)}
                        >
                            {action}
                        </Button>
                    </div>
                ))}
            </div>
            {isSpinner && <Loader/>}
        </div>
    );
}