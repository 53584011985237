import { useState } from 'react'
import { useEducationHooks } from '../api/useEducationHooks'

const useEducationViewController = (personId: { personId: number }) => {
  const { data: educationData } = useEducationHooks(personId)
  const [isVisible, setIsVisible] = useState(false);
  const handlePopUp = () => {
    setIsVisible(!isVisible);
  };

  return {
    educationData,
    isVisible,
    handlePopUp
  }
}

export default useEducationViewController