import React from 'react';
import './deleteModalStyle.scss'

interface DeleteModalProps {
    header: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ header, message, onConfirm, onCancel }) => {
    return (
        <div>
            <div data-testid="delete-modal">
                <div className='popup-header'>
                    <span>{header}</span>
                    <button id='close-btn' onClick={onCancel}>x</button>
                </div>
                <p>{message}</p>
                <hr className="divider" />
                <div className="button-popup-container">
                <button className='btn-popup-footer'onClick={onConfirm}>Yes</button>
                    <button className='btn-popup-footer' onClick={onCancel}>No</button>
                </div>
            </div>
        </div>
    );
};

export default DeleteModal;