import React from 'react';
import './confirmationModalStyles.scss'

interface ConfirmationModalProps {
    header: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    confirmText: string;
    cancelText: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ header, message, onConfirm, onCancel, confirmText, cancelText }) => {
    return (
        <div>
            <div>
                <div className="popup-header">
                    <span>{header}</span>
                    <button id='close-btn' onClick={onCancel}>x</button>
                </div>
                <p>{message}</p>
                <hr className="divider" />
                <div className="button-popup-container">
                <button className='btn-popup-footer'onClick={onConfirm}>{confirmText}</button>
                    <button className='btn-popup-footer' onClick={onCancel}>{cancelText}</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
