import { LogLevel, PublicClientApplication, EventType, AuthenticationResult } from "@azure/msal-browser";


export interface AuthConfig {
    clientId: string;
    authority?: string;
    redirectUri?: string;
    postLogoutRedirectUri?: string;
    clientCapabilities?: string[];
    cacheLocation?: string;
    storeAuthStateInCookie?: boolean;
    logLevel?: LogLevel;
    piiLoggingEnabled?: boolean; 
    enableLogger : boolean;
}

class MsalService {
    private msalInstance: PublicClientApplication;
    private authConfig: AuthConfig = {
        clientId: process.env.REACT_APP_AZUREAD_CLIENTID ?? "", // This is the ONLY mandatory field that you need to supply.
        authority: process.env.REACT_APP_AZUREAD_AUTHORITY ?? "https://login.microsoftonline.com/common", // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: process.env.REACT_APP_AZUREAD_REDIRECTURI ?? "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: process.env.REACT_APP_AZUREAD_POSTLOGOUTREDIRECTURI ?? "/", // Indicates the page to navigate after logout.
        clientCapabilities: process.env.REACT_APP_AZUREAD_CLIENTCAPABILITIES?.split(",") as [] ?? ['CP1'],  // this lets the resource owner know that this client is capable of handling claims challenge.
        cacheLocation: process.env.REACT_APP_AZUREAD_AUTHCACHELOCATION ?? 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: process.env.REACT_APP_AZUREAD_AUTHSTATEINCOOKIE === 'true', // Set this to "true" if you are having issues on IE11 or Edge
        logLevel: LogLevel.Error, // Set this to "Error" if you don't want to see verbose logs in the console
        piiLoggingEnabled: process.env.REACT_APP_AZUREAD_PIILOGGINGENABLED === 'true', // Set this to "true" if you are looking for PII data in the logs
        enableLogger: process.env.REACT_APP_AZUREAD_ENABLELOGGER === 'true' // Set this to "true" to enable MSAL logs
    };
    constructor(authConfig?: AuthConfig) {
        console.log("MsalService: constructor()");
        this.authConfig = authConfig || this.authConfig;
        
        const msalAuthConfig = {
            auth: {
                clientId: this.authConfig.clientId, // This is the ONLY mandatory field that you need to supply.
                authority: this.authConfig.authority,
                redirectUri: this.authConfig.redirectUri,
                postLogoutRedirectUri: this.authConfig.postLogoutRedirectUri,
                clientCapabilities: this.authConfig.clientCapabilities
            },
            cache: {
                cacheLocation: this.authConfig.cacheLocation,
                storeAuthStateInCookie: this.authConfig.storeAuthStateInCookie
            },
            system: {
                enableLogger: this.authConfig.enableLogger,
                loggerOptions:
                {
                    logLevel: this.authConfig.logLevel,
                    loggerCallback: (level: any, message: any, containsPii: any) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                        }                  
                    },                     
                    piiLoggingEnabled: this.authConfig.piiLoggingEnabled
                },
            },
        };

        this.msalInstance = new PublicClientApplication(msalAuthConfig);

        this.msalInstance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload && 'account' in event.payload) {
                const account = (event.payload as AuthenticationResult).account;
                this.msalInstance.setActiveAccount(account);
                this.msalInstance.loginRedirect();
            }

            if (event.eventType === EventType.LOGOUT_SUCCESS) {
                if (this.msalInstance.getAllAccounts().length > 0) {
                    this.msalInstance.setActiveAccount(this.msalInstance.getAllAccounts()[0]);
                    console.log("MsalService: LOGOUT_SUCCESS");
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                console.error("MsalService: LOGIN_FAILURE", JSON.stringify(event));
            }
        });
    }

    public getInstance(): PublicClientApplication {
        return this.msalInstance;
    }
}

export default MsalService;
