import { useEffect } from "react";
import { useEditQuery, useGetLocationTypeListQuery, useLazyGetValidateZipCodeQuery, useSaveMutation } from "../services/locationApi";
import { ILocation } from "../models/location/location";
import { HandleErrorMessage } from "../common/errorMessage";

export const useLocationApiSave = () => {
    const [saveLocation, { data, error,isError, isSuccess }] = useSaveMutation() || {};
    
    return  {saveLocation, data, error, isError,isSuccess} ;
} 


export const useLocationApiValidateZipCode = () => {
    const [validateZipcode,{data,  error}] = useLazyGetValidateZipCodeQuery();
    useEffect(() => {
        if (error) {
            HandleErrorMessage(error);
        }
    }, [error]);
    
    return  {validateZipcode, data, error} ;
} 


export const useLocationApiEditQuery = (selectedLocationDetails: ILocation, isEdit: boolean) => {
    const { data, error, isLoading, isSuccess } = useEditQuery({practiceId:selectedLocationDetails.practiceID,locationId:selectedLocationDetails.locationID},{skip: !isEdit}) || {};
    useEffect(() => {
        if (error) {
            HandleErrorMessage(error);
        }
    }, [error]);
   
    return  { data, error, isLoading, isSuccess} ;
} 

export const useLocationApiGetLocationTypeListHook = () => {
    const {data, error, isSuccess} = useGetLocationTypeListQuery() || {};
    useEffect(() => {
        if (error) {
            HandleErrorMessage(error);
        }
    }, [error]);
    
    return  { data, isSuccess} ;
} 