import React from "react";
import useClinicalInterestsViewModel from "./useClinicalInterestsViewModel";
import { Table, Button } from "@sentaraui/optimahealth_web";
import "./clinicalInterests.scss";
import { IClinicalInterestByEPI } from "../../../interface/staff";

interface ClinicalInterestProps {
    personId: number;
    isVisible: boolean;
    setOpenComponent: (component: string) => void;
}

const TABLE_HEADERS = [
    { text: 'Clinical Interest' },
    { text: 'Importance' }
];

export const ClinicalInterests = ({ personId, isVisible,setOpenComponent }: ClinicalInterestProps) => {
    const {
        clinicalData,
        handlePopUp
    } = useClinicalInterestsViewModel(personId);

    return (
        <div>
            <div className='main-container'>
                <Button
                    className="staff-clinical-button"
                    data-testid="staff-clinical-button"
                    onClick={() => {
                        handlePopUp();
                        setOpenComponent('clinicalInterests');
                    }}
                >
                    <span className={`staff-specialities-arrow ${isVisible ? 'down' : 'right'}`}>{isVisible ? '▼' : '▶'} Clinical Interests</span>
                </Button>
                {isVisible && (
                    <div className="clinical-popup">
                        <Table
                            className='experienceInfo-table'
                            header={TABLE_HEADERS}
                            rows={clinicalData?.map((clinicalInterest: IClinicalInterestByEPI) => [
                                { text: clinicalInterest.description },
                                { text: clinicalInterest.weight }
                            ])}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};