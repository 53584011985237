import React from "react";
import { Tabs, TabsItem, PrimaryButton } from '@sentaraui/optimahealth_web';
import EditPractice from "./editPractice/editPractice";
import SqcnTab from "./sqcnTab/sqcnTab";
import { useEditPracticeComponentViewModel } from "./useEditPracticeComponentViewModel";
import Loader from "../../common/spinner/loader";
import './editPracticeComponentStyles.scss';

interface EditPracticeComponentProps {
    practiceId: number;
    handleCloseModal: () => void;
    setFire: (value: boolean) => void;
}

const EditPracticeComponent: React.FC<EditPracticeComponentProps> = ({
    practiceId,
    handleCloseModal,
    setFire
}) => {
    const {
        isLoading,
        editPracticeData,
        setEditPracticeData,
        handleSave,
        activeTab,
        handleTabClick,
    } = useEditPracticeComponentViewModel({ practiceId });

    const handleSaveHandler = async () => {
        if(await handleSave()){
            handleCloseModal();
            setFire(false);
        }
    };
    return (
        <div>
            {isLoading && <Loader />}
            <div className='practice-main-wrapper'>
                <div id='practice-tab-button'>
                    <Tabs
                        activeTab={activeTab}
                        tabConditions={{
                            general: true,
                            SQCN: true,
                        }}
                        tabIdHandler={handleTabClick}
                    >
                        <TabsItem
                            content=""
                            tabId="general"
                            tabName="General" />
                        <TabsItem
                            content=""
                            tabId="SQCN"
                            tabName="SQCN" />
                    </Tabs>
                </div>
                {
                    activeTab === 'general' &&
                    <EditPractice
                        editPracticeData={editPracticeData}
                        setEditPracticeData={setEditPracticeData}
                    />
                }
                {
                    activeTab === 'SQCN' &&
                    <SqcnTab
                        editPracticeData={editPracticeData}
                        setEditPracticeData={setEditPracticeData}
                    />
                }
            </div >
            <div className='horizontal-line' />
            <div className='edit-practice-button'>
                <PrimaryButton
                    className="primary-button"
                    id="save-button"
                    data-testid="save-button"
                    onClick={handleSaveHandler}
                >Save
                </PrimaryButton>
            </div>
        </div>);
}
export default EditPracticeComponent;