import { useState, useEffect } from "react";
import { IPracticeStaffMember } from "../../interface/staff";

interface HospitalRolesViewModelProps {
    practiceStaffMember: IPracticeStaffMember | null;
}

export const useHospitalRolesViewModel = ({ practiceStaffMember }: HospitalRolesViewModelProps) => {
    const [hospitalRoles, setHospitalRoles] = useState<any[]>([]);

    useEffect(() => {
        if (practiceStaffMember?.physicianInfo.hospitalistRoles) {
            setHospitalRoles(practiceStaffMember?.physicianInfo.hospitalistRoles);
        }
    }, [practiceStaffMember]);

    return {
        hospitalRoles,
    };
};