import React from 'react'
import './headerStyle.scss'
import { Image } from '@sentaraui/optimahealth_web'
import { CustomSelectOption } from './customSelectOption';

const Header = () => {
    return (
        <div className='header-container'>
            <div className='logo'>
                <span><Image
                    alttext="sentara-logo"
                    className="sentara-logo"
                    dataTestId="imgID"
                    path='images/mdOffice_logo.png'
                /></span>
                <span><Image
                    alttext="mdoffice-logo"
                    className="mdoffice-logo"
                    dataTestId="imgID"
                    path="images/mdOffice_blue.png"
                /></span>
            </div>
            <div className='rt-header-content'>
                <CustomSelectOption />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span className='title'>Practice Manager</span>
                    <span style={{ "color": "rgb(204, 171, 90)", "fontSize": "22px", "paddingLeft": "2px", "marginTop":"13%" }}>②</span>
                </div>
            </div>
        </div>
    )
}

export default Header