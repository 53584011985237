import { useEffect } from "react";
import { useGetPracticeStatesQuery,   useLazyGetLocationNamesQuery,  useLazyGetPracticeCitiesQuery,  useLazyGetPracticeNamesQuery, useLazyGetPracticeZipsQuery } from "../services/autoCompleteApi";
import { HandleErrorMessage } from "../common/errorMessage";

export const useAutoCompleteStateViewModel = () => {

    const { data, error, isLoading } = useGetPracticeStatesQuery();
    
    return {  data,  error, isLoading};
}

export const useAutoCompletePraticeNameViewModel = () => {

    const [fetchPraticeNameData,{ data, error, isLoading, isSuccess }] = useLazyGetPracticeNamesQuery();
    
    useEffect(()=>{
        if(error){
            HandleErrorMessage(error);
        }
    },[error])

    return { fetchPraticeNameData,  data, error, isLoading, isSuccess};
}

export const useAutoCompleteLocationViewModel = () => {

    const [fetchLocationData,{ data, error, isLoading, isSuccess }] = useLazyGetLocationNamesQuery();
    
    useEffect(()=>{
        if(error){
           HandleErrorMessage(error);
        }
    },[error])
    
    return { fetchLocationData, data , error, isLoading, isSuccess};
}

export const useAutoCompleteCityViewModel = () => {

    const [fetchCityData,{ data, error, isLoading, isSuccess }] = useLazyGetPracticeCitiesQuery();
    
    useEffect(()=>{
        if(error){
            HandleErrorMessage(error);
        }
    },[error])

    return { fetchCityData, data , error, isLoading, isSuccess};
}

export const useAutoCompleteZipcodeViewModel = () => {

    const [fetchZipcodeData,{ data, error, isLoading, isSuccess }] = useLazyGetPracticeZipsQuery();
    
    useEffect(()=>{
        if(error){
            HandleErrorMessage(error);
        }
    },[error])

    return { fetchZipcodeData, data , error, isLoading, isSuccess};
}