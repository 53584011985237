import { useState } from 'react';

const extractPhysicianInfo = (practiceStaffMember: any) => {
    const physicianInfo = practiceStaffMember?.physicianInfo?.[0] || {};
    return {
        practicingStatus: physicianInfo.practicingStatus ?? [],
        since: physicianInfo.since ?? '',
        biocard: physicianInfo.biocard ?? '',
        commitmentTraining: physicianInfo.commitmentTraining?.toString() ?? '',
        safteyChampianTraining: physicianInfo.safteyChampianTraining ?? '',
        status: physicianInfo.status ?? [],
        content: physicianInfo.content ?? '',
        start: physicianInfo.start ?? '',
        end: physicianInfo.end ?? '',
        videoLink: physicianInfo.videoLink ?? '',
        videoLinkText: physicianInfo.videoLinkText ?? '',
        website: physicianInfo.website ?? '',
        websiteLinkText: physicianInfo.websiteLinkText ?? ''
    };
};

export const usePhysicianProfileData = (practiceStaffMember: any) => {
    const [physicianData, setPhysicianData] = useState(extractPhysicianInfo(practiceStaffMember));
    return { physicianData, setPhysicianData };
};

export const storePhysicianData = (physicianData: any, practiceStaffMember: any) => {
    const defaultData = extractPhysicianInfo(practiceStaffMember);
    return {
        practicingStatus: physicianDataValue( physicianData.practicingStatus , defaultData.practicingStatus),
        since: physicianDataValue( physicianData.since , defaultData.since),
        biocard: physicianDataValue( physicianData.biocard , defaultData.biocard),
        commitmentTraining: physicianDataValue( physicianData.commitmentTraining , defaultData.commitmentTraining),
        safteyChampianTraining: physicianDataValue(physicianData.safteyChampianTraining , defaultData.safteyChampianTraining),
        status: physicianDataValue(physicianData.status , defaultData.status),
        content: physicianDataValue(physicianData.content , defaultData.content),
        start: physicianDataValue(physicianData.start , defaultData.start),
        end: physicianDataValue(physicianData.end , defaultData.end),
        videoLink: physicianDataValue(physicianData.videoLink , defaultData.videoLink),
        videoLinkText: physicianDataValue(physicianData.videoLinkText , defaultData.videoLinkText),
        website: physicianDataValue(physicianData.website , defaultData.website),
        websiteLinkText: physicianDataValue(physicianData.websiteLinkText , defaultData.websiteLinkText)
    };
};


const physicianDataValue = ((value1: string, value2: string) => {
    return value1 || value2
})