import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Filters } from '../interface/Filter';
import { IPractice, Practice, SuperGroupItem } from '../interface/PracticesLists';
import { IEditPractice } from '../interface/Practice';
import { prepHeaders } from './prepHeaders';
import { ILocation } from '../models/location/location';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/`;
const tag = 'practicesTag';

export const practicesApi = createApi({
  reducerPath: 'practicesApi',
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl, prepareHeaders: prepHeaders }),
  tagTypes: [tag],
  endpoints: (builder) => ({
    getPractices: builder.query<Practice[], Filters>({
      query: (body) => ({
        url: 'practice/find',
        method: 'POST',
        body
      }),
      keepUnusedDataFor: 1.0000,
      providesTags: [{type:tag, id: 'LIST'}],
      transformResponse(res: any, meta, arg) {
        let practices = res["practices"] as IPractice[];
        
        const uniqPractices = practices.reduce((acc: any[], practice: any) => {
          const existingPractice = acc.find((p) => p.practiceId === practice.practiceID);
          if (existingPractice) {
              existingPractice.locations = [...existingPractice.locations,{locationName:practice.locationName,locationId:practice.locationID,address1:practice.addrLine1 +' '+ practice.addrLine2 +' '+ practice.addrLine3,address2:practice.addrLine2, address3:practice.addrLine3,city:practice.city,state:practice.state,phoneNumber:practice.phoneNumber,faxNumber:practice.faxNumber,postalCode:practice.postalCode}];
              return acc;
          }
          return [...acc, {practiceId:practice.practiceID,practiceName:practice.practiceName,locations: [{locationName:practice.locationName,locationId:practice.locationID,address1:practice.addrLine1 +' '+ practice.addrLine2 +' '+ practice.addrLine3,address2:practice.addrLine2, address3:practice.addrLine3,city:practice.city,state:practice.state,phoneNumber:practice.phoneNumber,faxNumber:practice.faxNumber,postalCode:practice.postalCode}]}];
      }, []);
      return uniqPractices;
     
      }
    }),
    removePractice: builder.mutation<void, number>({
      query: (practiceId) => ({
        url: `practice/deletepractice?practiceId=${practiceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [tag],
    }),

    editPractice: builder.query<IEditPractice, {practiceId: number}>({
      query: ({ practiceId }) => `practice/editpractice?practiceId=${practiceId}`,
      transformResponse: (response: IEditPractice) => response,
      providesTags: [tag],
    }),

    removeLocation: builder.mutation<string, { practiceId: number; locationId: number }>({
      query: ({ practiceId, locationId }) => ({
        url: `location/removefrompractice?practiceId=${practiceId}&locationId=${locationId}`,
        method: 'GET',
      }),
      invalidatesTags: [tag],
    }),

    updateLocation: builder.mutation<void, { practiceId: number; locationId: number; location: ILocation }>({
      query: ({ practiceId, locationId, location }) => ({
        url: `practices/${practiceId}/locations/${locationId}`,
        method: 'PUT',
        body: location,
      }),
      invalidatesTags: [tag],
    }),
    
    addPractice: builder.mutation<string, IEditPractice>({
      query: (newPractice) => ({
        url: `practice/savepractice`,
        method: 'POST',
        body: newPractice,
      }),
      invalidatesTags: [tag],
      
    }),

    getSuperGroups: builder.query<SuperGroupItem[], void>({
      query: () => 'practice/getsupergroups',
      providesTags: [tag],
    }),

    getAddPractices: builder.query<boolean, void>({
      query: () => 'practice/canaddpractice',
      providesTags: [tag],
    }),

    saveEditPractice: builder.mutation<void, IEditPractice>({
      query: (practice) => ({
        url: 'practice/updatepractice',
        method: 'POST',
        body: practice,
      }),
      invalidatesTags: [tag],
    }),
  }),
});

export const {
  useLazyGetPracticesQuery,
  useGetPracticesQuery,
  useRemovePracticeMutation,
  useEditPracticeQuery,
  useRemoveLocationMutation,
  useUpdateLocationMutation,
  useAddPracticeMutation,
  useGetSuperGroupsQuery,
  useGetAddPracticesQuery,
  useSaveEditPracticeMutation,
} = practicesApi;