import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepHeaders } from "../../services/prepHeaders";
import { IEducation } from "./IEducation";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/staff/`;
const tag = "EducationTag";

export const educationApi = createApi({
    reducerPath: "educationApi",
    tagTypes: [tag],
    baseQuery: fetchBaseQuery({ baseUrl,prepareHeaders: prepHeaders}),
    endpoints: (builder) => ({
        getEducation: builder.query<IEducation[],{ personId: number }>({
            query: ({personId}) => `educationinfo?personId=${personId}`,
            transformResponse(res: IEducation[]) {
                return res
              },
            providesTags: [tag],
        }),
    }),
});

export const { useGetEducationQuery } = educationApi;