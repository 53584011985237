import React from "react";
import './tableStyle.scss'; // Import the SCSS file

type TableCell = {
  text: any;
  colspan?: number;
};

type TableRow = TableCell[];

export type TableProps = {
  header?: TableRow;
  rows?: TableRow[];
  className?: string;
  rowClickHandler?: (rowIndex: number) => void; // New optional prop for row click handler
  rowsChildren?: React.ReactNode;
  headerChildren?: React.ReactNode;
  noDataFound?: string;
};

const Table: React.FC<TableProps> = ({
  header,
  rows,
  className,
  rowClickHandler,
  headerChildren,
  rowsChildren,
  noDataFound = "No data found",
}) => {
  return (
    <table className={`table table-custom-style ${className}`}>
      <thead>
        <tr>
          {headerChildren ||
            header?.map((cell) => (
                <th scope="col" colSpan={cell?.colspan}>
                  {cell?.text}
                </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {rowsChildren ||
          (rows?.length !== 0 &&
            rows?.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                onClick={() => rowClickHandler && rowClickHandler(rowIndex)} // Add click event handler here
              >
                {row?.map((cell, cellIndex) => (
                  <React.Fragment key={cellIndex}>
                    <td colSpan={cell?.colspan}>{cell?.text}</td>
                  </React.Fragment>
                ))}
              </tr>
            ))) || (
            <tr>
              <td
                className="text-center"
                colSpan={header?.length}
                data-testid="noDataFound"
              >
                {noDataFound}
              </td>
            </tr>
          )}
      </tbody>
    </table>
  );
};

export default Table;
