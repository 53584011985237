import React, { useEffect, useRef } from 'react';
import './AutocompleteStyle.scss';
import { Input } from "@sentaraui/optimahealth_web";
import { IAutoCompleteItem } from '../interface/autocompleteModel';

type autoCompleteProps = {
    id: string;
    dataTestId: string;
    options: IAutoCompleteItem[];
    noOptionText: string;
    onSearch?: any;
    onSelect?: (selected: any) => void; // Add onSelect prop
    optionKey: string;
    optionCount: number;
    className?: string;
    placeholder?: string;
    selectedValue?: string; 
}

const AutoComplete = (props: autoCompleteProps) => {
    const { options, noOptionText, onSearch, onSelect, optionKey, optionCount, id, dataTestId , className, placeholder, selectedValue } = props;
    const [searchText, setSearchText] = React.useState(selectedValue);
    const [selected, setSelected] = React.useState(selectedValue);
    const [allOption, setAllOption] = React.useState<IAutoCompleteItem[]>(options || []);
    const [showDropdown, setShowDropdown] = React.useState(true); // State to control dropdown visibility
    const [highlightedIndex, setHighlightedIndex] = React.useState(-1); // Index of the currently highlighted option
    const autocompleteRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleOutsideClick = (e: MouseEvent) => {
            if (autocompleteRef.current) // && !autocompleteRef.current.contains(e.target))
            {
                setShowDropdown(false);
            }
        };
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick)
        };
    }, []);

    useEffect(() => {
        setSearchText(selectedValue ?? "");
    }, [selectedValue]);

    const selectHandle = async (val: any) => {
        setSelected(val[optionKey]);
        setSearchText(val[optionKey]); // Set search text to selected value
        setShowDropdown(false); // Close the dropdown
        if (onSelect) {
            onSelect(val[optionKey]); // Call the onSelect prop with the selected value
        }
        setAllOption([] as IAutoCompleteItem[]); // Close the dropdown after selection
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                setHighlightedIndex((prevIndex) =>
                    prevIndex < allOption.length - 1 ? prevIndex + 1 : 0
                );
                break;
            case 'ArrowUp':
                event.preventDefault();
                setHighlightedIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : allOption.length - 1
                );
                break;
            case 'Enter':
                if (highlightedIndex >= 0 && highlightedIndex < allOption.length) {
                    selectHandle(allOption[highlightedIndex]);
                }
                break;
            case 'Tab':
                setShowDropdown(false);
                break;
            default:
                break;
        }
    };

    const handleChange = async (event: any) => {
        const value = event.target.value;
        setSearchText(value);
        if (onSelect) {
            onSelect(value);
        }
        setSelected(""); // Clear selected value
        setHighlightedIndex(-1); // Reset highlighted index
        // for api call and
        if (onSearch) {
            await onSearch(event.target.value, (data: IAutoCompleteItem[]) => setAllOption(data));
            setSearchText(event.target.value);
            setShowDropdown(true);
            return;
        }
        // Show the dropdown
        if (!value && onSelect) {
            onSelect(""); // Call onSelect with an empty value if search text is cleared
        }
    };

    return (
        <div className='autoCommplete' ref={autocompleteRef}>
            <Input id={id} dataTestId={dataTestId} className={`inputBox ${className ?? ''}`} placeholder={placeholder}
                onClick={() => { setSelected(""); setShowDropdown(false) }}
                value={selected || searchText}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            <div
                className='autocomplete-dropdown'
                style={{
                    display: allOption?.length > 0 && showDropdown && searchText ? "flex" : "none",
                    maxHeight: 'fit-content',
                    position: 'absolute',
                    marginTop: '33px',
                    width: 'fit-content',
                    backgroundColor: '#f4f4f4',
                    color:'#000000'
                }}
            >
                {allOption?.length === 0 ? (
                    <div> {noOptionText} </div>
                ) : (
                    allOption?.map((option: any, index: number) => (
                        <td
                            key={`${option.id}`}
                            onClick={() => selectHandle(option)}
                            onMouseEnter={() => setHighlightedIndex(-1)} // Reset highlighted index on mouse enter
                            className={`dropdown-item ${index === highlightedIndex ? 'highlighted' : ''}`}
                        >
                            {option[props.optionKey]}
                        </td>
                    ))
                )}
            </div>
        </div>
    );
};

export default AutoComplete;
