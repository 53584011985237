import React from 'react';
import './assignLocationModalStyle.scss';
import { Label, Checkbox } from '@sentaraui/optimahealth_web';

interface ConfirmationModalProps {
    header: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    confirmText: string;
    checked: boolean;
    handleCheckboxInputChange: (e:any) => void;
}

const AssignLocationModal: React.FC<ConfirmationModalProps> = ({ header,
    message,
    onConfirm,
    onCancel,
    confirmText,
    checked,
    handleCheckboxInputChange
}) => {
    return (
        <div>
            <div>
                <div className="popup-header">
                    <span>{header}</span>
                    <button id='close-btn' onClick={onCancel}>x</button>
                </div>
                <p>{message}</p>
                <div className='label-checkbox'>
                    <Label
                        className="primary-label"
                        htmlFor="primary">
                        Primary Location?
                    </Label>
                    <Checkbox
                        id="primary"
                        name="isPrimaryLocation"
                        className="form-check-input mt-0"
                        type="checkbox"
                        disabled={false}
                        dataTestId="primary"
                        checked={checked}
                        onChange={handleCheckboxInputChange}
                    />
                </div>
                <hr className="divider" />
                <div className="button-popup-container">
                    <button className='btn-popup-footer' onClick={onConfirm}>{confirmText}</button>
                </div>
            </div>
        </div>
    );
};

export default AssignLocationModal;
