import React from 'react';
import { Table } from '@sentaraui/optimahealth_web';
import './supergroupStyle.scss';
import { ISuperGroupItem, ISupergroupProps } from '../interface/SuperGroup';
import { useSupergroupViewModel } from './supergroupViewModel';

const Row: React.FC<{ text: string | boolean, datatestid: string }> = ({ text, datatestid }) => (
  <div
    data-testid={datatestid}
  >
    {text?.toString() || ''}
  </div>
);

export const Supergroup: React.FC<ISupergroupProps> = ({ onClose }) => {
  const { superGroupData } = useSupergroupViewModel();
  const tableHeader = [
    { text: 'Type' },
    { text: 'Name' },
    { text: 'IsSMG' },
  ];

  return (
    <div id='container'>
      <div className='supergroup-modal-header' id='title'>
        <span>Super Groups</span>
        <button id='btn-close' onClick={onClose}>x</button>
      </div>
      <div id='table'>
        <Table
          className="table-supergroup inner-table"
          header={tableHeader}
          rows={superGroupData?.map((row: ISuperGroupItem, index: number) => [
            { text: <Row text={row.superGroupType} datatestid="supergroup-row-1" /> },
            { text: <Row text={row.superGroupName} datatestid="supergroup-row-2" /> },
            { text: <Row text={row.isSMG} datatestid="supergroup-row-3" /> },
          ])
          }
        />
      </div>
    </div>
  )
}