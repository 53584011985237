import { ChangeEvent, useEffect, useState } from "react";
import { useGetLocationListQuery } from "../../../services/locationApi";
import { IAssigneLocation, ISearchFilterLocation } from "../../../interface/Filter";
import { useAssignLocationQuery } from "../../../services/staffApi";
import { IStaffLocation, IStaffLocationItem } from "../../../interface/staff";
import { useSaveAssignLocationHooks } from "./useSaveAssignLocationHooks";
import ToastNotification from "../../../common/toast/toastNotification";
import { HandleErrorMessage } from "../../../common/errorMessage";

interface SearchLocationViewModelProps {
    staffId: number;
    practiceId: number;
    setSearchModel: (value: boolean) => void;
}

const initialSearchLocation: ISearchFilterLocation = {
    locationName: '*',
    city: '',
    state: '',
    zip: '',
    practiceId: 0,
    excludeStaffId: 0,
    excludePracticeId: 0,
};

const assignLocationInitialState: IAssigneLocation = {
    isError: false,
    isPrimaryLocation: false,
    locationId: 0,
    locationName: '',
    staffId: 0,
    staffName: '',
};

const fieldToHeaderMapping = {
    locationName: 'Location Name',
    address1: 'Address 1',
    address2: 'Address 2',
    city: 'City',
    state: 'State',
    zip: 'Zip',
    phone: 'Phone',
    fax: 'Fax',
};

export const useSearchLocationViewModel = ({
    staffId,
    practiceId,
    setSearchModel,
}: SearchLocationViewModelProps) => {
    const [isSkip, setIsSkip] = useState<boolean>(false);
    const [triggerQuery, setTriggerQuery] = useState<boolean>(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
    const [isRowSelected, setIsRowSelected] = useState<boolean>(false); const [selectedLocation, setSelectedLocation] = useState<IStaffLocationItem | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [searchLocationData, setSearchLocationData] = useState<IStaffLocation>({} as IStaffLocation);
    const [searchLocation, setSearchLocation] = useState<ISearchFilterLocation>({
        ...initialSearchLocation,
        excludeStaffId: staffId,
        practiceId: practiceId,
    });
    const [assignLocation, setAssignLocation] = useState<IAssigneLocation>(assignLocationInitialState);
    const [isSpinner, setIsSpinner] = useState<boolean>(false);

    const { data: fetchLocationData, isSuccess: isLocationFetchSuccess } = useAssignLocationQuery(
        { staffId, locationId: selectedLocation?.locationID ?? 0 },
        { skip: !triggerQuery }
    );

    const { data, isSuccess } = useGetLocationListQuery(searchLocation, { skip: isSkip });
    const saveAssignedLocation = useSaveAssignLocationHooks(setIsSpinner);

    useEffect(() => {
        if (isSuccess && data && !isSkip) {
            setSearchLocationData(data);
        }
    }, [isSkip, isSuccess, data]);

    useEffect(() => {
        if (triggerQuery && isLocationFetchSuccess && fetchLocationData) {
            setAssignLocation(fetchLocationData);
        }
    }, [triggerQuery, isLocationFetchSuccess, fetchLocationData]);

    const handleRowClick = (index: number, location: IStaffLocationItem) => {
        let rows = document.getElementsByClassName('search-location-table')[0].getElementsByTagName("tr");
        for (let i = 0; i < rows.length; i++) {
            if (i === index && rows.item(i) !== null)
                highlightTableRow(rows[index + 1]);
        };
        setSelectedRowIndex(index);
        setSelectedLocation(location);
        setIsRowSelected(true);
    };

    function highlightTableRow(rowIndex: any) {
        // Remove highlight from previously selected row, if any
        const previouslySelectedRow = document.querySelector('tr.selected');
        if (previouslySelectedRow) {
            previouslySelectedRow.classList.remove('selected');
        }
        // Highlight the clicked row
        rowIndex.classList.add('selected');
    }

    const checkboxHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        if (selectedLocation) {
            const updatedLocation = {
                ...selectedLocation,
                [name]: checked,
            } as IStaffLocationItem;
            setSelectedLocation(updatedLocation);
            setAssignLocation((prevAssignLocation) => ({
                ...prevAssignLocation,
                isPrimaryLocation: updatedLocation.isPrimaryLocation ?? false,
            }));
        }
    };

    const onSubmitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        setIsSkip(false);
    };

    const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSearchLocation((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setIsSkip(true);
    };

    const handleModalClose = () => {
        setTriggerQuery(false);
        setIsModalOpen(false);
    };

    const buttonClickHandler = () => {
        setTriggerQuery(true);
        setIsModalOpen(true);
    };

    const onConfirmHandler = async () => {
        try{
            if (selectedLocation) {
                setIsSpinner(true);
                await saveAssignedLocation.saveAssignedLocation(assignLocation).unwrap();
                ToastNotification("success", "assign location succcessfully saved");
                setIsModalOpen(false);
                setSearchModel(false);
            }
        }
        catch (error: unknown) {
            if (error instanceof Error) {
                HandleErrorMessage(error, 'assign location');
            } else {
                HandleErrorMessage(new Error('An unknown error occurred'), 'assign location');
            }
        }
    };

    return {
        searchLocation,
        isSkip,
        setSelectedLocation,
        selectedLocation,
        isRowSelected,
        setIsRowSelected,
        fieldToHeaderMapping,
        inputChangeHandler,
        onSubmitHandler,
        handleRowClick,
        selectedRowIndex,
        checkboxHandler,
        onConfirmHandler,
        buttonClickHandler,
        handleModalClose,
        searchLocationData,
        isModalOpen,
        assignLocation,
        isSpinner,
    };
};
