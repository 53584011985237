import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PracticeManager } from '../interface/PracticeManager';
import { prepHeaders } from './prepHeaders';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/practice/`; 
const tag = 'PracticeTag';

export const practiceManagersAPI: any = createApi({
    reducerPath: 'practiceManagerApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl, prepareHeaders: prepHeaders}),
    tagTypes: [tag],
    endpoints: (builder) => ({
        getPracticeManagersById: builder.query<PracticeManager[], void>({
        query: (practiceId) => `practicemanagers?practiceId=${practiceId}`,
        providesTags: [tag],
      }),
      getNewPracticeManagersById: builder.query<PracticeManager[], void>({
        query: (practiceId) => `getnewpracticemanager?practiceId=${practiceId}`,
        providesTags: [tag],
      }),
    removePracticeManager: builder.mutation<boolean, { practiceId: number, managerId: number, mtype: string }>({
        query: ({ practiceId, managerId, mtype }) => ({
          url: `removepracticemanager?practiceId=${practiceId}&managerId=${managerId}&mtype=${mtype}`,
          method: 'GET',
          providesTags: [tag],
        }),
      }),
    addPracticeManager: builder.mutation<boolean, { practiceId: number, managerId: number, type: number }>({
        query: ({ practiceId, managerId, type }) => ({
          url: `addpracticemanager?practiceId=${practiceId}&managerId=${managerId}&type=${type}`,
          method: 'GET',
          providesTags: [tag],
        }),
      }),
  }),
});

export const {
    useGetPracticeManagersByIdQuery,
    useGetNewPracticeManagersByIdQuery,
    useAddPracticeManagerMutation,
    useRemovePracticeManagerMutation
} = practiceManagersAPI;