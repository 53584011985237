import { useState } from "react";

export const UsePrivilegesViewModel = () => {

    const [isVisible, setIsVisible] = useState(false);
    const [triggerQuery, setTriggerQuery] = useState(false);
    const handlePopUp = () => {
        setIsVisible(!isVisible);
        if (!isVisible) {
            setTriggerQuery(true);
        }
    };
    const headerMapping = [
        {
            key: 'facility',
            name: 'Facility'
        },
        {
            key: 'statusCode',
            name: 'Status Code'
        },
        {
            key: 'statusDescription',
            name: 'Status Description'
        },
        {
            key: 'appointmentDate',
            name: 'Appointment Date'
        },
        {
            key: 'reappointmentDate',
            name: 'Reappointment Date'
        }
    ]
    const formatValue = (value: any) => {
        if (value instanceof Date) {
            return value.toLocaleDateString(); // Format the date as a string
        }
        return value;
    };

    return {
        isVisible,
        handlePopUp,
        triggerQuery,
        headerMapping,
        formatValue,
    }
};

