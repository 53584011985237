import { useEffect } from "react";
import { useGetEducationQuery } from "./educationApi";
import { HandleErrorMessage } from "../../common/errorMessage";

export const useEducationHooks = (personId:{personId: number}) => {
  
    const { data, error } = useGetEducationQuery(personId);

    useEffect(() => {
        if(error) {
            HandleErrorMessage(error);
        }
    },[error])


    return {data}
}