/**
 * `app.tsx` is the root component of the React application.
 *
 * This component is responsible for rendering the main layout of the application, including any navigation components, main content, footers, etc.
 *
 * It also wraps the application in any context providers, such as the `ReduxProvider` for state management or the `Router` for routing.
 *
 * The `App` component is exported as the default export so it can be imported and used in the `index.tsx` file where the React application is rendered.
 */

import { FC } from 'react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import AppRoutes from '../route/appRoutes';


interface AppProps {
    instance: IPublicClientApplication;
}

const App: FC<AppProps> = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>  
        <div className="app" data-testid="app">
            <AppRoutes/>
        </div>
        </MsalProvider>
    );
};

export default App;
