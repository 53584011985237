import React from 'react'
import EducationViewModel from './viewmodel/educationViewModel'
import { Button } from "@sentaraui/optimahealth_web";
import useEducationViewController from './viewmodel/useEducationViewController';
type paramProps = {
  isVisible: boolean;
  setOpenComponent: (component: string) => void;
  personId: number
}
const EducationView = (props: paramProps) => {
  const { handlePopUp } = useEducationViewController({ personId: props.personId });
  return (
    <div data-testid="viewTestId">
      <Button
        className="staff-specialities-button"
        data-testid="staff-experience-button"
        onClick={() => {
          handlePopUp();
          props.setOpenComponent('education');
        }}
      >
        <span className={`staff-specialities-arrow ${props.isVisible ? 'down' : 'right'}`}>{props.isVisible ? '▼' : '▶'} Education</span>
      </Button>
      {props.isVisible && <EducationViewModel personId={props.personId} />}
    </div>
  )
};

export default EducationView;