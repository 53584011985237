import React, { useState, useEffect } from 'react';
import { Tabs, TabsItem } from '@sentaraui/optimahealth_web';
import './locationComponentStyle.scss';
import { ILocation } from '../models/location/location';
import LocationButtons from './locationButtons/locationButtons';
import LocationDetails from './locationDetails/locationDetails';
import ManagersTab from './managersTab/managersTab';
import { initialLocationState } from '../../constants/constants';
import { useLocationApiEditQuery, useLocationApiSave } from './useLocationApi';
import Loader from '../common/spinner/loader';
import ToastNotification from '../common/toast/toastNotification';
import { HandleErrorMessage } from '../common/errorMessage';

interface LocationComponentProps {
    isEdit: boolean;
    selectedLocationDetails: ILocation;
    handleClose: () => void;
    setSelectedLocationDetails: (location: ILocation) => void;
    isTemporarySave?: boolean;
    onLocationSave: (location: ILocation) => void;
    setFire: React.Dispatch<React.SetStateAction<boolean>>;
    fire: boolean;
}

const LocationComponent: React.FC<LocationComponentProps> = ({
    isEdit,
    selectedLocationDetails,
    setSelectedLocationDetails,
    handleClose,
    isTemporarySave = false, // Default to false
    onLocationSave,
    setFire,
}) => {
    const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
    const {saveLocation} = useLocationApiSave();
    const [activeTab, setActiveTab] = useState('general');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isSpiner, setIsSpiner] = useState<boolean>(false);
    const { data, isSuccess, isLoading } = useLocationApiEditQuery(selectedLocationDetails, isEdit) || {};

    useEffect(() => {
        if(isLoading) setIsSpiner(true);
        if (isSuccess && data) {
            setSelectedLocationDetails({ ...(data) })
            setIsSpiner(false);
        }
    }, [isSuccess, data,setSelectedLocationDetails,isLoading, isSpiner, setIsSpiner]);
    
    const handleTabChange = (tabId: any) => {
        setActiveTab(tabId);
    };

    const handleSave = async () => {
        if (errors['zip'] !== undefined && errors['zip'] !== '') return;
        if (!validation()) return;
        setIsSpiner(true);
        try{
            if (isTemporarySave) {
                const editedLocation = {
                    ...initialLocationState,
                    ...selectedLocationDetails,
                };
                onLocationSave(editedLocation); // Call the callback function with the current location
                setSelectedLocationDetails(initialLocationState); // Reset the selectedLocationDetails state
                handleClose();
            } else {
                const response:any = await saveLocation(selectedLocationDetails).unwrap();
                ToastNotification("success", response);
                handleClose();
                setFire(false);
            }
        }catch (error: unknown) {
            if (error instanceof Error) {
                HandleErrorMessage(error, 'save location');
            } else {
                HandleErrorMessage(new Error('An unknown error occurred'), 'save location');
            }
        }finally{
            setIsSpiner(false);
        }   
    };

    const validation = () => {
        const { name, address1, city, state, zip } = selectedLocationDetails;
        if (!name || !address1 || !city || !state || !zip) {
            setErrors({
                name: !name ? 'Name is required' : '',
                address1: !address1 ? 'Address is required' : '',
                city: !city ? 'City is required' : '',
                state: !state ? 'State is required' : '',
                zip: !zip ? 'Zip is required' : '',
            });
            return false;
        }
        return true;
    }

    return (
        <><div id='main-wrapper'>
            <div id='location-top-tab'>
                <Tabs
                    activeTab={activeTab}
                    tabConditions={{
                        general: true,
                        managers: isEdit,
                    }}
                    tabIdHandler={handleTabChange}
                >
                    <TabsItem
                        content=""
                        tabId="general"
                        tabName="General" />
                    <TabsItem
                        content=""
                        tabId="managers"
                        tabName="Managers" />
                </Tabs>
            </div>
            {
                activeTab === 'general' &&
                <LocationDetails
                    isEdit={isEdit}
                    setSelectedLocationDetails={setSelectedLocationDetails}
                    selectedLocationDetails={selectedLocationDetails}
                    errors={errors}
                    setErrors={setErrors}
                />
            }
            {
                activeTab === 'managers' &&
                <ManagersTab
                    setSelectedLocationDetails={setSelectedLocationDetails}
                    selectedLocationDetails={selectedLocationDetails} 
                    inputValues= {inputValues}
                    setInputValues={setInputValues}
                    />
            }
        </div >
            <div className='horizontal-line'></div>
            <LocationButtons
                handleSave={handleSave}
                handleClose={handleClose} />
            {isSpiner && <Loader />}
        </>
    );
};
export default LocationComponent;
