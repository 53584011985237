import React from "react";
import { useSpecialitiesViewModel } from "./useSpecialitiesViewModel";
import "./specialities.scss";
import { Table, Button } from "@sentaraui/optimahealth_web";

interface SpecialitiesProps {
    personId: number;
    isVisible: boolean;
    setOpenComponent: (component: string) => void;
}


export const Specialities = ({ personId, setOpenComponent, isVisible }: SpecialitiesProps) => {
    const {
        data,
        handlePopUp,
        colHeaders,
        getSpecialtyValues
    } = useSpecialitiesViewModel(personId);
    const rows = data?.map((specialty: any) =>
        getSpecialtyValues(specialty).map((value: string) => ({ text: <p>{value}</p> }))
    );
    return (
        <div>
            <Button
                className="staff-specialities-button"
                data-testid="staff-specialities-button"
                onClick={() => {
                    handlePopUp();
                    setOpenComponent('specialities');
                }}
            >
                <span className={`staff-specialities-arrow ${isVisible ? 'down' : 'right'}`}>{isVisible ? '▼' : '▶'} Specialities</span>
            </Button>
            {isVisible && (
                <div className="specialities-popup">
                    <Table className="specialities-table"
                        data-testid="specialities-table"
                        header={colHeaders.map((item: any) => {
                            return { text: item.value };
                          })}
                          rows={rows}

                    />
                </div>
            )}

        </div>
    );
};