import { useEffect, useState } from "react";
import { IStaffLocationItem, IUpdateStaffLocation } from "../../../interface/staff";
import { EditLocationButtons as editAction } from "../actionTypes";
import { useStaffApiUpdateLocationHook, } from "../../../hooks/useStaffApiHooks";
import ToastNotification from "../../../common/toast/toastNotification";

export const UseViewEditLocation = (staffId:number, staffName:string,selectedLocation: IStaffLocationItem, onClose: () => void) => {
    const [isSpiner, setIsSpiner] = useState<boolean>(false);
    const [locationDetails, setLocationDetails] = useState<IStaffLocationItem>(selectedLocation);
    const {updateLocation, error, isSuccess} = useStaffApiUpdateLocationHook() || {};
    const handleButtonClick = (action: editAction) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (action === editAction.Cancel) {
            onClose();
        }
        if (action === editAction.Submit) {
            const data: IUpdateStaffLocation = {
                staffId: staffId,
                staffName: staffName,
                locationId: locationDetails?.locationID ?? 0 ,
                locationName: locationDetails?.locationName ?? "",
                isPrimaryLocation: locationDetails?.isPrimaryLocation ?? false,
                isError:false
            };
            
            setIsSpiner(true);
            updateLocation(data);
        }
    };

    useEffect(() => {
        if (error) {
            let errMsg = "";
            if (error.hasOwnProperty('error')) {
                errMsg = (error as any).error;
            } else if (error.hasOwnProperty('data')) {
                let errData = (error as any).data;
                errMsg = errData.hasOwnProperty('title') ? errData.title : errData
            }
            setIsSpiner(false);
            ToastNotification("error",errMsg);
        }
        if (isSuccess) {
            setIsSpiner(false);
            ToastNotification("success", "data successfully updated");
            onClose();
        }
    }, [error, isSuccess, onClose]);

    const handleToggleChange = (name: string, value: boolean) => {
        setLocationDetails( {...locationDetails, isPrimaryLocation: value });
    };

    
    return {
        locationDetails,
        handleButtonClick,
        handleToggleChange,
        isSpiner,
    };
};