import { DefaultTheme } from "styled-components";

export const DEFAULT_THEME: DefaultTheme = {
  accordion: {
    background: "#f5f0ee",
    text: "#171717",
  },
  accordionFAQ: {
    backgroundColor: "#fff",
    iconStroke: "#227066",
    text: "#227066",
  },
  alert: {
    danger: {
      background: "rgb(237, 7, 7)",
      border: "rgb(241, 174, 181)",
      text: "#fff",
    },
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    info: {
      background: "rgb(207, 244, 252)",
      border: "rgb(158, 234, 249)",
      text: "rgb(5, 81, 96)",
    },
    success: {
      background: "rgb(209, 231, 221)",
      border: "rgb(163, 207, 187)",
      text: "rgb(10, 54, 34)",
    },
    warning: {
      background: "rgb(255, 243, 205)",
      border: "rgb(255, 230, 156)",
      text: "rgb(102, 77, 3)",
    },
  },
  breadcrumbs: {
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    icon: "#d4d4d4",
    text: "#171717",
  },
  button: {},
  buttonDropdown: {
    active: {
      background: "#fcfafa",
      border: "#eee7e4",
      text: "#171717",
    },
    background: "#0000",
    border: "#eee7e4",
    disabled: {
      background: "#a7c6c2",
      border: "#a7c6c2",
      text: "#fff",
    },
    focused: {
      background: "#fcfafa",
      border: "#eee7e4",
      text: "#171717",
    },
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    hovered: {
      background: "#fcfafa",
      border: "#eee7e4",
      text: "#171717",
    },
    text: "#212529",
  },
  buttonLink: {
    background: "#fff",
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    header: {
      background: "#fff",
      text: "#262626",
      icon: "#262626",
    },
    icon: "#227066",
    text: "#227066",
    underline: "#227066",
  },
  buttonPrimary: {
    active: {
      background: "#0d5b51",
      border: "#227066",
      text: "#a7c6c2",
    },
    background: "#227066",
    border: "#227066",
    disabled: {
      background: "#a7c6c2",
      border: "#a7c6c2",
      text: "#fff",
    },
    focused: {
      background: "#0d5b51",
      border: "227066",
      text: "fff",
    },
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    hovered: {
      background: "#0d5b51",
      border: "#227066",
      text: "#fff",
    },
    text: "#fff",
  },
  buttonSecondary: {
    active: {
      background: "#fff",
      border: "#4e8d85",
      // boxShadow: '#a7c6c2'
      text: "#4e8d85",
    },
    background: "#fff",
    border: "#227066",
    disabled: {
      background: "#fff",
      border: "#a7c6c2",
      text: "#a7c6c2",
    },
    focused: {
      background: "#fff",
      border: "0d5b51",
      text: "0d5b51",
    },
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    hovered: {
      background: "#fff",
      border: "#0d5b51",
      text: "#0d5b51",
    },
    text: "#227066",
  },
  buttonTabs: {
    background: "#f5f0ee",
    border: "#f5f0ee",
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    selected: {
      background: "#333131",
      border: "#333131",
      text: "#fff",
    },
    text: "#333131",
  },
  callButton: {
    icon: "#333131",
  },
  chatBubble: "#333131",
  checkbox: {
    background: "#fff",
    border: "#8d8585",
    fontFamily: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
    selected: {
      checkbox: "#227066",
    },
    text: "#171717",
  },
  commonText: {
    disclaimers: {
      content: "#000",
      contentFontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
      title: "#000",
      titleFontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    },
    footerDisclaimers: "#333131",
    footerDisclaimersFontFamily:
      '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
    headings: {
      h1: "#171717",
      h1FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"Literata_medium", Georgia, Times, serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
      h2: "#333131",
      h2FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
      h3: "#333131",
      h3FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
      h4: "#333131",
      h4FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
      h5: "#333131",
      h5FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
      h6: "#333131",
      h6FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
    },
    labels: "#333131",
    labelsFontFamily: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
    links: "#227066",
    linksFontFamily: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
    paragraphs: "#333131",
    paragraphsFontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
  },
  contactInfo: {
    text: "#227066",
  },
  dropdownButton: {
    border: "#171717",
    background: "#fff",
    dropdownIcon: "#171717",
    icon: "#171717",
    text: "#171717",
  },
  footerLinks: {
    background: "#f5f0ee",
    fontFamily: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
    text: "#4b4849",
  },
  globalError: {
    backgroundColor: "#fef2f2",
    borderColor: "#fecaca",
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    text: "#991b1b",
  },
  icons: {
    accordionDownArrow: "#227066",
    agent: {
      backgroundCircle: "#CFD9E1",
      background: "#171717",
      foreground: "#fff",
    },
    apple: "#171717",
    authorizations: "#171717",
    calendar: "#171717",
    chat: {
      background: "#171717",
      foreground: "#FAFAFA",
    },
    chatbot: {
      background: "#171717",
      foreground: "#fff",
    },
    chatbotClose: "#262626",
    chatBubble: "#262626",
    chatCircleDot: {
      background: "#171717",
      dot: "#22C55E",
      foreground: "#fff",
    },
    chatClose: "#262626",
    chatDot: {
      background: "#171717",
      dot: "#22C55E",
      foreground: "#fff",
    },
    chatInfo: "#262626",
    chatWeb: {
      background: "#E7ECF0",
      foreground: "#262626",
    },
    chatMinimize: "#171717",
    checkBig: "#227066",
    claims: "#171717",
    clock: {
      background: "#E7ECF0",
      foreground: "#262626",
    },
    closeBlock: "#171717",
    connectingAgent: {
      selectedDot: "#171717",
      unselectedDot: "#A3A3A3",
    },
    customerSupport: "#171717",
    doctor: "#262626",
    document: "#171717",
    eyeClosed: "#171717",
    eyeOpen: "#171717",
    formsAndDocs: "#171717",
    hamburger: "#171717",
    healthReimbursement: "#171717",
    healthSavings: "#171717",
    healthSavingsAccount: "#171717",
    help: "#171717",
    idCard: "#171717",
    insurance: "#171717",
    loading: {
      selectedDot: "#171717",
      unselectedDot: "#A3A3A3",
    },
    mail: "#171717",
    money: "#171717",
    myPlan: "#171717",
    phone: "#212529",
    payMyPremium: "#171717",
    print: "#171717",
    profile: "#171717",
    quoteEnrollManage: "#171717",
    retry: "#171717",
    rightArrow: "#171717",
    search: "#171717",
    settings: "#171717",
    snapshot: "#171717",
    stethoscope: "#171717",
    taxForm: "#171717",
    umbrella: "#171717",
    urgentCareSearch: "#171717",
    userBlack: "#171717",
    userBlackFull: "#171717",
    userWhite: "#fff",
    virtualVisit: "#171717",
  },
  inlineError: {
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    text: "#dc2626",
  },
  input: {
    background: "#fff",
    border: "#696363",
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    icon: "rgb(51, 49, 49)",
    placeholder: "#696363",
    text: "#696363",
  },
  inputError: {
    border: "#ed0707",
  },
  inputFocused: {
    border: "#0467eb",
    boxShadow: "rgba(5, 93, 242, 0.3)",
    outline: "#0467eb",
  },
  link: "#227066",
  loadingSpinner: "#227066",
  modalOverlay: {
    closeButton: "#333131",
  },
  navigationMenu: {
    background: "transparent",
    border: "rgb(222, 226, 230)",
    dropdownIcon: "#171717",
    dropdownItems: {
      background: "#fff",
      hovered: {
        background: "rgb(248, 249, 250)",
        text: "rgb(33, 37, 41)",
      },
      text: "#171717",
    },
    text: "#171717",
  },
  planDropdown: {
    dropdownIcon: "#343132",
    text: "#171717",
  },
  profileCard: {
    headings: {
      color: "#333131",
      fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    },
    syllablesCircle: {
      background: "#f5f0ee",
      fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
      text: "#333131",
    },
    text: {
      color: "#333131",
      fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    },
  },
  progressBars: {
    circle: {
      track: "#b4c4fc",
      progress: "#3b3ba8",
    },
  },
  radio: {
    background: "#fff",
    border: "#8d8585",
    selected: {
      radio: "#227066",
    },
  },
  select: {
    error: {},
    background: "#fff",
    border: "#696363",
    dropdown: "#0467eb",
    focused: {
      border: "#0467eb",
      boxShadow: "rgba(5, 93, 242, 0.3)",
      outline: "#0467eb",
    },
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    placeholder: "#696363",
    text: "#696363",
  },
  stepper: {
    activeConnector: "#227066",
    activeStep: {
      background: "#fff",
      border: "#227066",
      boxShadow: "#d3e2e0",
      text: "#227066",
    },
    inactiveStep: {
      background: "#fff",
      border: "#d3e2e0",
      text: "#171717",
    },
    inactiveConnector: "#eee7e4",
    selectedStep: {
      background: "#227066",
      border: "#eee7e4",
      boxShadow: "none",
      text: "#fff",
    },
  },
  submenu: {
    border: "#f5f0ee",
    links: {
      fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
      text: "#171717",
    },
  },
  table: {
    headers: {
      background: "#333131",
      text: "#fff",
    },
    rows: {
      even: {
        background: "#fcfafa",
        text: "#000",
      },
      odd: {
        background: "transparent",
        text: "#000",
      },
    },
  },
  tabs: {
    background: "#fff",
    border: "#fff",
    focused: {
      border: "#e9ecef",
    },
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    hovered: {
      border: "#f5f0ee",
    },
    selected: {
      background: "#fff",
      border: "#227066",
      text: "#227066",
    },
    text: "#171717",
  },
  textarea: {
    background: "#fff",
    border: "#696363",
    dropdown: "#0467eb",
    focused: {
      border: "#0467eb",
      boxShadow: "rgba(5, 93, 242, 0.3)",
      outline: "#0467eb",
    },
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    placeholder: "#696363",
    text: "#696363",
  },
  topNavigation: {
    background: "#fff",
    border: "#fff",
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    hovered: {
      background: "#f5f0ee",
      border: "#b3a9a3",
      text: "#171717",
    },
    text: "#8d8585",
  },
};
export type Theme = typeof DEFAULT_THEME;

export const DYNAMIC_RUNTIME_THEME: Theme = {
  accordion: {
    background: "#CBC3E3",
    text: "#171717",
  },
  accordionFAQ: {
    backgroundColor: "#fff",
    iconStroke: "#03646f",
    text: "#03646f",
  },
  alert: {
    danger: {
      background: "#6C3461",
      border: "#6C3461",
      text: "#fff",
    },
    fontFamily: "ISOCTEUR",
    info: {
      background: "#7a4988",
      border: "#7a4988",
      text: "#fff",
    },
    success: {
      background: "#311432",
      border: "#311432",
      text: "#fff",
    },
    warning: {
      background: "#e39ff6",
      border: "#e39ff6",
      text: "#fff",
    },
  },
  breadcrumbs: {
    fontFamily: "ISOCTEUR",
    icon: "purple",
    text: "purple",
  },
  button: {},
  buttonDropdown: {
    active: {
      background: "#301934",
      border: "purple",
      text: "white",
    },
    border: "purple",
    background: "purple",
    disabled: {
      border: "#CBC3E3",
      background: "#CBC3E3",
      text: "white",
    },
    focused: {
      background: "#301934",
      border: "purple",
      text: "white",
    },
    fontFamily: "ISOCTEUR",
    hovered: {
      background: "#301934",
      border: "purple",
      text: "white",
    },
    text: "white",
  },
  buttonLink: {
    background: "#fff",
    fontFamily: "ISOCTEUR",
    header: {
      background: "#fff",
      text: "orange",
      icon: "orange",
    },
    icon: "purple",
    text: "purple",
    underline: "purple",
  },
  buttonPrimary: {
    active: {
      background: "#301934",
      border: "purple",
      text: "white",
    },
    border: "purple",
    background: "purple",
    disabled: {
      border: "#CBC3E3",
      background: "#CBC3E3",
      text: "white",
    },
    focused: {
      background: "#301934",
      border: "purple",
      text: "white",
    },
    fontFamily: "ISOCTEUR",
    hovered: {
      background: "#301934",
      border: "purple",
      text: "white",
    },
    text: "white",
  },
  buttonSecondary: {
    active: {
      background: "white",
      border: "#301934",
      text: "#301934",
    },
    border: "purple",
    background: "white",
    disabled: {
      border: "#CBC3E3",
      background: "white",
      text: "#CBC3E3",
    },
    focused: {
      background: "white",
      border: "#301934",
      text: "#301934",
    },
    fontFamily: "ISOCTEUR",
    hovered: {
      background: "white",
      border: "#301934",
      text: "#301934",
    },
    text: "purple",
  },
  buttonTabs: {
    background: "#CBC3E3",
    border: "#CBC3E3",
    fontFamily: "ISOCTEUR",
    selected: {
      background: "purple",
      border: "purple",
      text: "#fff",
    },
    text: "#333131",
  },
  callButton: {
    icon: "purple",
  },
  chatBubble: "purple",
  checkbox: {
    background: "#fff",
    border: "#8d8585",
    fontFamily: "ISOCTEUR",
    selected: {
      checkbox: "purple",
    },
    text: "purple",
  },
  commonText: {
    disclaimers: {
      content: "purple",
      contentFontFamily: "ISOCTEUR",
      title: "purple",
      titleFontFamily: "ISOCTEUR",
    },
    footerDisclaimers: "purple",
    footerDisclaimersFontFamily: "ISOCTEUR",
    labels: "purple",
    labelsFontFamily: "ISOCTEUR",
    headings: {
      h1: "purple",
      h1FontFamilies: {
        black: "ISOCTEUR",
        body: "ISOCTEUR",
        bold: "ISOCTEUR",
        extraBold: "ISOCTEUR",
        light: "ISOCTEUR",
        medium: "ISOCTEUR",
        regular: "ISOCTEUR",
        semiBold: "ISOCTEUR",
      },
      h2: "purple",
      h2FontFamilies: {
        black: "ISOCTEUR",
        body: "ISOCTEUR",
        bold: "ISOCTEUR",
        extraBold: "ISOCTEUR",
        light: "ISOCTEUR",
        medium: "ISOCTEUR",
        regular: "ISOCTEUR",
        semiBold: "ISOCTEUR",
      },
      h3: "purple",
      h3FontFamilies: {
        black: "ISOCTEUR",
        body: "ISOCTEUR",
        bold: "ISOCTEUR",
        extraBold: "ISOCTEUR",
        light: "ISOCTEUR",
        medium: "ISOCTEUR",
        regular: "ISOCTEUR",
        semiBold: "ISOCTEUR",
      },
      h4: "purple",
      h4FontFamilies: {
        black: "ISOCTEUR",
        body: "ISOCTEUR",
        bold: "ISOCTEUR",
        extraBold: "ISOCTEUR",
        light: "ISOCTEUR",
        medium: "ISOCTEUR",
        regular: "ISOCTEUR",
        semiBold: "ISOCTEUR",
      },
      h5: "purple",
      h5FontFamilies: {
        black: "ISOCTEUR",
        body: "ISOCTEUR",
        bold: "ISOCTEUR",
        extraBold: "ISOCTEUR",
        light: "ISOCTEUR",
        medium: "ISOCTEUR",
        regular: "ISOCTEUR",
        semiBold: "ISOCTEUR",
      },
      h6: "purple",
      h6FontFamilies: {
        black: "ISOCTEUR",
        body: "ISOCTEUR",
        bold: "ISOCTEUR",
        extraBold: "ISOCTEUR",
        light: "ISOCTEUR",
        medium: "ISOCTEUR",
        regular: "ISOCTEUR",
        semiBold: "ISOCTEUR",
      },
    },
    links: "purple",
    linksFontFamily: "ISOCTEUR",
    paragraphs: "purple",
    paragraphsFontFamily: "ISOCTEUR",
  },
  contactInfo: {
    text: "purple",
  },
  dropdownButton: {
    border: "purple",
    background: "#fff",
    dropdownIcon: "purple",
    icon: "purple",
    text: "purple",
  },
  footerLinks: {
    background: "#CBC3E3",
    fontFamily: "ISOCTEUR",
    text: "#000",
  },
  globalError: {
    backgroundColor: "#fef2f2",
    borderColor: "#fecaca",
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    text: "#991b1b",
  },
  icons: {
    accordionDownArrow: "purple",
    agent: {
      backgroundCircle: "#cbc3e3",
      background: "purple",
      foreground: "#fff",
    },
    apple: "purple",
    authorizations: "purple",
    calendar: "purple",
    chat: {
      background: "purple",
      foreground: "#FAFAFA",
    },
    chatbot: {
      background: "purple",
      foreground: "#fff",
    },
    chatbotClose: "purple",
    chatBubble: "purple",
    chatCircleDot: {
      background: "purple",
      dot: "orange",
      foreground: "#fff",
    },
    chatClose: "purple",
    chatDot: {
      background: "purple",
      dot: "orange",
      foreground: "#fff",
    },
    chatInfo: "purple",
    chatMinimize: "purple",
    chatWeb: {
      background: "#cbc3e3",
      foreground: "purple",
    },
    checkBig: "purple",
    claims: "purple",
    clock: {
      background: "#cbc3e3",
      foreground: "purple",
    },
    closeBlock: "purple",
    connectingAgent: {
      selectedDot: "purple",
      unselectedDot: "#cbc3e3",
    },
    customerSupport: "purple",
    doctor: "purple",
    document: "purple",
    eyeClosed: "purple",
    eyeOpen: "purple",
    formsAndDocs: "purple",
    hamburger: "purple",
    healthReimbursement: "purple",
    healthSavings: "purple",
    healthSavingsAccount: "purple",
    help: "purple",
    idCard: "purple",
    insurance: "purple",
    loading: {
      selectedDot: "purple",
      unselectedDot: "#cbc3e3",
    },
    mail: "purple",
    money: "purple",
    myPlan: "purple",
    payMyPremium: "purple",
    phone: "purple",
    print: "purple",
    profile: "purple",
    quoteEnrollManage: "purple",
    retry: "purple",
    rightArrow: "purple",
    search: "purple",
    settings: "purple",
    snapshot: "purple",
    stethoscope: "purple",
    taxForm: "purple",
    umbrella: "purple",
    urgentCareSearch: "purple",
    userBlack: "black",
    userBlackFull: "black",
    userWhite: "white",
    virtualVisit: "purple",
  },
  inlineError: {
    fontFamily: "ISOCTEUR",
    text: "red",
  },
  input: {
    background: "#fff",
    border: "purple",
    fontFamily: "ISOCTEUR",
    icon: "purple",
    placeholder: "purple",
    text: "purple",
  },
  inputError: {
    border: "#ed0707",
  },
  inputFocused: {
    border: "purple",
    boxShadow: "rgba(191, 85, 236, 0.3)",
    outline: "purple",
  },
  link: "purple",
  loadingSpinner: "purple",
  modalOverlay: {
    closeButton: "purple",
  },
  navigationMenu: {
    background: "transparent",
    dropdownIcon: "purple",
    dropdownItems: {
      background: "#fff",
      hovered: {
        background: "#CBC3E3",
        text: "black",
      },
      text: "purple",
    },
    border: "purple",
    text: "purple",
  },
  planDropdown: {
    dropdownIcon: "purple",
    text: "purple",
  },
  profileCard: {
    headings: {
      color: "purple",
      fontFamily: "ISOCTEUR",
    },
    syllablesCircle: {
      background: "purple",
      fontFamily: "ISOCTEUR",
      text: "white",
    },
    text: {
      color: "purple",
      fontFamily: "ISOCTEUR",
    },
  },
  progressBars: {
    circle: {
      track: "#CBC3E3",
      progress: "purple",
    },
  },
  radio: {
    background: "#fff",
    border: "#8d8585",
    selected: {
      radio: "purple",
    },
  },
  select: {
    background: "#fff",
    border: "purple",
    dropdown: "purple",
    focused: {
      border: "purple",
      boxShadow: "rgba(191, 85, 236, 0.3)",
      outline: "purple",
    },
    fontFamily: "ISOCTEUR",
    placeholder: "purple",
    text: "purple",
  },
  stepper: {
    activeConnector: "purple",
    activeStep: {
      background: "#fff",
      border: "purple",
      boxShadow: "#d3e2e0",
      text: "purple",
    },
    inactiveConnector: "#CBC3E3",
    inactiveStep: {
      background: "#fff",
      border: "#CBC3E3",
      text: "#171717",
    },
    selectedStep: {
      background: "purple",
      border: "purple",
      boxShadow: "none",
      text: "#fff",
    },
  },
  submenu: {
    border: "purple",
    links: {
      fontFamily: "ISOCTEUR",
      text: "purple",
    },
  },
  table: {
    headers: {
      background: "purple",
      text: "#fff",
    },
    rows: {
      even: {
        background: "#CBC3E3",
        text: "#171717",
      },
      odd: {
        background: "#CF9FFF",
        text: "#171717",
      },
    },
  },
  tabs: {
    background: "#fff",
    border: "#CBC3E3",
    focused: {
      border: "orange",
    },
    fontFamily: "ISOCTEUR",
    hovered: {
      border: "orange",
    },
    selected: {
      background: "white",
      border: "purple",
      text: "purple",
    },
    text: "#CBC3E3",
  },
  textarea: {
    background: "#fff",
    border: "purple",
    dropdown: "purple",
    focused: {
      border: "purple",
      boxShadow: "rgba(191, 85, 236, 0.3)",
      outline: "purple",
    },
    fontFamily: "ISOCTEUR",
    placeholder: "purple",
    text: "purple",
  },
  topNavigation: {
    background: "#fff",
    border: "#fff",
    fontFamily: "ISOCTEUR",
    hovered: {
      background: "#cbc3e3",
      border: "purple",
      text: "purple",
    },
    text: "#cbc3e3",
  },
};
