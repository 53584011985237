import React from 'react';
import './locationButtonsStyles.scss'
import { PrimaryButton } from '@sentaraui/optimahealth_web';
interface LocationButtonsProps {
    handleClose: () => void;
    handleSave: (e: any) => void;
}

const LocationButtons: React.FC<LocationButtonsProps> = ({
    handleClose,
    handleSave,
}) => {
    return (
        <div className='button-wrapper'>
            <PrimaryButton
                className="primary-button"
                id="save-button"
                onClick={handleSave}
            >Save
            </PrimaryButton>
            <PrimaryButton
                className="primary-button"
                id="cancel-button"
                onClick={handleClose}
            >Cancel
            </PrimaryButton>
        </div>
    )
}
export default LocationButtons;