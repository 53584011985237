import React from 'react';
import { Table, Button, ModalOverlayWithoutClose } from "@sentaraui/optimahealth_web";
import NewAuthoritativeSource from '../newAuthoritativeSource/newAuthoritativeSource';
import DeleteModal from '../modal/deleteModal';
import './authoritativeSourceStyle.scss';
import { useAuthoritativeSourceViewModel } from './AuthoritativeSourceViewModel';
import { PracticeManager } from '../interface/PracticeManager';

const Row: React.FC<{ text: string | boolean | null, id: number, selectedRow: number | null, onClick: (id: number) => void }> = ({ text, id, selectedRow, onClick }) => (
    <div
        data-testid={`row-${id}`}
        className={id === selectedRow ? 'selected-row' : ''}
        onClick={() => onClick(id)}
    >
        {text ? text.toString() : ''}
    </div>
);

const AuthoritativeSource: React.FC<{ practiceId: number | null, practiceName: string | null, onClose: any }> = ({ practiceId, practiceName, onClose }) => {
    const {
        commonHeader,
        authoritativesSourceData,
        isNewModalOpen,
        selectedRow,
        isItemSelected,
        showModal,
        isModalOpen,
        newAuthoritativeSource,
        selectedItem,
        handleRowClick,
        handleRemove,
        confirmRemove,
        handleNewClick,
        closeModal,
        setShowModal,
        setIsModalOpen
    } = useAuthoritativeSourceViewModel(practiceId, onClose);

    return (
        <div id='container'>
            <div className='authoritative-modal-header' id='title'>
                <span>Authoritative Source for {practiceName} </span>
                <button id='btn-close' onClick={onClose}>x</button>
            </div>
            <div id='table'>
                <Table
                    className="table"
                    header={commonHeader}
                    rows={authoritativesSourceData?.items?.map((item: PracticeManager) =>[
                        { text: <Row text={item.lastName} id={item.personID} selectedRow={selectedRow} onClick={(id: number) => handleRowClick(id, item)} /> },
                        { text: <Row text={item.firstName} id={item.personID} selectedRow={selectedRow} onClick={(id: number) => handleRowClick(id, item)} /> }
                    ])}
                />
            </div>
            <hr className='supergroup-baseline' />
            <div className='footer-button'>
                <Button className="button" onClick={handleNewClick}>New</Button>
                <Button className={isItemSelected ? "button-disabled" : "button"} disabled={isItemSelected} onClick={handleRemove}>Remove</Button>
            </div>
            <ModalOverlayWithoutClose isOpen={isNewModalOpen} onClose={closeModal}>
                <NewAuthoritativeSource practiceId={newAuthoritativeSource} practiceName={practiceName} onClose={closeModal} />
            </ModalOverlayWithoutClose>
            <ModalOverlayWithoutClose isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} className="delete-modal" >
                {showModal &&
                    <DeleteModal
                        header='Confirm Remove'
                        message={`Remove ${selectedItem.firstName} ${selectedItem.lastName} as the authoritative source of ${practiceName}?`}
                        onConfirm={confirmRemove}
                        onCancel={() => {
                            setShowModal(false);
                            setIsModalOpen(false);
                        }} />}
            </ModalOverlayWithoutClose>
        </div>
    );
};

export default AuthoritativeSource;