import { useEffect, useState } from 'react';
import { IStaffSearch, ISearchFilter } from '../interface/staff';
import { useGetStaffQuery } from '../services/staffApi';
import { HandleErrorMessage } from '../common/errorMessage';

const useSearchViewModel = (
  practiceId: number | null,
  setSearchResults: React.Dispatch<React.SetStateAction<IStaffSearch[]>>,
  isSkipValue: boolean,
  searchCriteria: ISearchFilter,
  setSearchCriteria: React.Dispatch<React.SetStateAction<ISearchFilter>>

) => {


  const [isSkip, setIsSkip] = useState<boolean>(isSkipValue);
  const { data, isSuccess, isLoading,error: err } = useGetStaffQuery(searchCriteria, { skip: isSkip });
  const [isSpinner, setIsSpinner] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ id: string; message: string }[]>([]);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    let errorMessage:any = null;
    switch (id) {
      case 'ssn':
        if (!/^\d*$/.test(value) || value.length < 9) {
          errorMessage = 'SSN must be numeric and up to 9 digits.';
        }
        break;
      case 'dob':
        if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
          errorMessage = 'DOB must be in proper format';
        }
        
        break;
      default:
        break;
    }

    setErrors(prevErrors => {
      const newErrors = prevErrors.filter(error => error.id !== id);
      if (errorMessage) {
        newErrors.push({ id, message: errorMessage });
      }
      if(value === "") {
        newErrors.pop();
      }
      return newErrors;
    });

    setSearchCriteria(prevCriteria => ({ ...prevCriteria, [id]: value }));
    setIsSkip(true);
  };
  const getErrorMessage = (id: string) => {
    const error = errors.find(error => error.id === id);
    return error?.message ;
  };
  const filterData = () => {
    if (isSuccess && data) {
      setSearchResults(data);
    }
  };

  const onSubmit = (e: any) => {
      e.preventDefault();
      const { dob } = searchCriteria;
      if (dob) {
        // Assuming dob is in 'MM/DD/YYYY' format
        const [month, day, year] = dob.split('/').map(Number); // Split and convert to numbers
        const enteredDate = new Date(year, month - 1, day); // month is 0-based in JS Date
        const currentDate = new Date();
    
        // Check if the entered date is in the future
        if (enteredDate > currentDate) {
          setErrors(prevErrors => [
            ...prevErrors.filter(error => error.id !== 'dob'),
            { id: 'dob', message: 'Date of Birth cannot be in the future.' }
          ]);
          return; // Prevent form submission
        }
      }
      setIsSkip(false);
  };
  useEffect(() => {
    if (err) {
      HandleErrorMessage(err);
      setIsSpinner(false);
      setIsSkip(true);
    }
  }, [err,data, setIsSpinner, setIsSkip]);
  useEffect(() => {
    if (!isSkip) {
      filterData();
      setIsSpinner(false);
    }
    if (isLoading) {
      setIsSpinner(true);
    }
  }, [data, isSuccess, setIsSpinner, isLoading, isSkip, filterData]);

  return { onChange, onSubmit, searchCriteria, isLoading, isSpinner, errors,  getErrorMessage };
};
export default useSearchViewModel;