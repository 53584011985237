/**
 * `authSlice` is a slice of the Redux store that manages authentication state.
 *
 * The `AuthState` interface defines the shape of the authentication state, which includes the user's name and token.
 *
 * The `initialState` is the initial state of the authentication slice, with `name` and `token` set to null.
 *
 * The `authSlice` object is created using Redux Toolkit's `createSlice` function. It includes:
 * - The name of the slice: "auth".
 * - The initial state.
 * - An object defining the reducers for this slice.
 *
 * The `setAuth` reducer updates the state with the user's name and token, and also stores these values in local storage.
 * The `logout` reducer clears the state and local storage.
 *
 * The `selectAuth` selector function is used to select the `auth` slice of the state from the Redux store.
 *
 * The `setAuth` and `logout` actions are exported for use in components.
 */

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

export interface AuthState {
    name: string | null;
    token: string | null;
}

const initialState: AuthState = {
    name: null,
    token: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuth: (
            state,
            action: PayloadAction<{ name: string; token: string }>
        ) => {
            const { name, token } = action.payload;
            localStorage.setItem("user", JSON.stringify({ name, token }));
            state.name = name;
            state.token = token;
        },
        logout: (state) => {
            localStorage.clear();
            state.name = initialState.name;
            state.token = initialState.token;
        },
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const { setAuth, logout } = authSlice.actions;
export default authSlice.reducer;