import React from 'react';
import './experienceInfo.scss';
import { Table, Button } from "@sentaraui/optimahealth_web";
import useExperienceInfoViewModel from './useExperienceInfoViewModel';
import { IExperienceByEPI } from '../../../interface/staff';

const TABLE_HEADERS = [
    { text: 'Type' },
    { text: 'Position' },
    { text: 'Start' },
    { text: 'End' }
];

const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
};
interface ExperienceInfoViewProps {
    personId: number;
    setOpenComponent: (component: string) => void;
    isVisible: boolean;
}


const ExperienceInfoView: React.FC<ExperienceInfoViewProps> = ({ setOpenComponent, isVisible, personId }) => {

    const {
        experienceData = [], // Default to an empty array if undefined
        handlePopUp
    } = useExperienceInfoViewModel(personId);

    return (
        <div className='main-container'>
            <Button
                className="staff-specialities-button"
                data-testid="staff-specialities-button"
                onClick={() => {
                    handlePopUp();
                    setOpenComponent('experience');
                }}
            >
                <span className={`staff-specialities-arrow ${isVisible ? 'down' : 'right'}`}>{isVisible ? '▼' : '▶'} Experience</span>
            </Button>
            {isVisible && (
                <div className="experience-popup">
                    <Table
                        className='experienceInfo-table'
                        header={TABLE_HEADERS}
                        rows={experienceData?.map((experience: IExperienceByEPI) => [
                            { text: experience.physicianExperienceTypeDesc},
                            { text: experience.position},
                            { text: experience.startDate ? formatDate(experience.startDate.toString()) : '' },
                            { text: experience.endDate ? formatDate(experience.endDate.toString()) : '' }
                        ])}
                    />
                </div>
            )}
        </div>
    );
};

export default ExperienceInfoView;
