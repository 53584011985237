import { useEffect } from "react";
import { useSaveAssignLocationMutation } from "../../../services/staffApi"
import { HandleErrorMessage } from "../../../common/errorMessage";

export const useSaveAssignLocationHooks = (setIsSpiner: (value: boolean) => void) => {
    const [saveAssignedLocation, { error, isSuccess }] = useSaveAssignLocationMutation();

    useEffect(() => {
        if (isSuccess) {
            
            setIsSpiner(false)
        }
    }, [isSuccess, setIsSpiner]);

    useEffect(() => {
        if (error) {
            HandleErrorMessage(error);
            setIsSpiner(false);
        }
    }, [error, setIsSpiner])
    return { saveAssignedLocation };
};
