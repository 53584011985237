import React from 'react';
import { Table, Button } from "@sentaraui/optimahealth_web";
import './newAuthoritativeSourceStyle.scss';
import { useNewAuthoritativeSourceViewModel } from './NewAuthoritativeSourceViewModel';
import { PracticeManager } from '../interface/PracticeManager';
 
const Row: React.FC<{ text: string | boolean | null, id: number, selectedRow: number | null, onClick: (id: number) => void }> = ({ text, id, selectedRow, onClick }) => (
    <div
        data-testid={`row-${id}`}
        className={id === selectedRow ? 'selected-row' : ''}
        onClick={() => onClick(id)}
    >
        {text ? text.toString() : ''}
    </div>
);
 
const NewAuthoritativeSource: React.FC<{ practiceId: number | null, practiceName: string | null, onClose: any }> = ({ practiceId, practiceName, onClose }) => {
    const {
        header,
        selectedRow,
        handleRowClick,
        handleOkClick,
        newAuthoritativeSourceData,
        isItemSelected
    } = useNewAuthoritativeSourceViewModel(practiceId, onClose);
 
    return (
        <div id='container' data-testid='new-authoritative-source'>
            <div className='newAuthoritative-modal-header' id='title'>
                <span>Select Authoritative Source for {practiceName} </span>
                <button id='btn-close' onClick={onClose}>x</button>
            </div>
            <div id='newtable'>
                <Table
                    className="table"
                    header={header}
                    rows={newAuthoritativeSourceData?.items?.map((manager: PracticeManager) =>[
                        { text: <Row text={manager.lastName} id={manager.personID} selectedRow={selectedRow} onClick={(id: number) => handleRowClick(id, manager)} /> },
                        { text: <Row text={manager.firstName} id={manager.personID} selectedRow={selectedRow} onClick={(id: number) => handleRowClick(id, manager)} /> }
                    ]) || []}
                />
            </div>
            <hr className='supergroup-baseline' />
            <div className='footer-button'>
                <Button className={isItemSelected ? "button-disabled" : "button"} disabled={isItemSelected} onClick={handleOkClick}>Ok</Button>
            </div>
        </div>
    )
}

export default NewAuthoritativeSource;