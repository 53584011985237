import { useEffect, useRef, useState } from 'react';
import { useEditStaffQuery } from '../../services/staffApi';
import ToastNotification from '../../common/toast/toastNotification';
import { EditLocationButtons as editAction } from "../location/actionTypes";
import {  useStaffApiUpdateClinicianHook } from '../../hooks/useStaffApiHooks';
import { IPracticeStaffMember, IUpdateGroup } from '../../interface/staff';
import { HandleErrorMessage } from '../../common/errorMessage';

interface StaffEditViewModelProps {
    onClose: () => void;
    staffId: number;
    practiceId: number;
    isSentara:boolean;
    practiceStaffMember:IPracticeStaffMember | null;
}

export const useStaffEditViewModel = ({onClose, staffId, practiceId, isSentara, practiceStaffMember}: StaffEditViewModelProps) => {
    const { data, error } = useEditStaffQuery({ practiceId, staffId }) || {};
    const [activeTab, setActiveTab] = useState('ClinicalInfo');
    const toastShown = useRef(false);
    const {updateClinician} = useStaffApiUpdateClinicianHook() || {};
    const [isSpiner, setIsSpiner] = useState<boolean>(false);

    useEffect(() => {
        if (error && !toastShown.current) {
            HandleErrorMessage(error, 'get staff data');
            toastShown.current = true;
            onClose(); 
        }
    }, [error, onClose, toastShown]);  

    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);
    };

    const handleButtonClick = (action: editAction) => async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (action === editAction.Cancel) {
            onClose();
        }
        try{
            if (action === editAction.Submit) {
                const data: IUpdateGroup = {
                    personId: practiceStaffMember?.staffInfo?.personId ?? 0,
                    supressFromSentaraDotCom: (isSentara === true && practiceStaffMember?.physicianInfo.supressFromSentaraDotCom === "No") ? "Yes" : "No",
                };
                setIsSpiner(true);
                await updateClinician(data).unwrap();
                setIsSpiner(false);
                ToastNotification("success", "clinician data successfully updated");
                onClose();
            }
        }
        catch (error: unknown) {
            if (error instanceof Error) {
                HandleErrorMessage(error, 'update clinician');
            } else {
                HandleErrorMessage(new Error('An unknown error occurred'), 'update clinician');
            }
        }
    };

    return { activeTab, handleTabClick, handleButtonClick ,data, isSpiner};
};

