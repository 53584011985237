import './listLocation.scss';
import { LocationList } from './locationList';

interface LocationContainerProps {
    name: string;
    staffId: number;
    practiceId: number;
    practiceName: string;
    onClose: () => void;
}
export const LocationContainer: React.FC<LocationContainerProps> = ({ name, staffId, practiceId ,practiceName, onClose}) => {
    
    return (
        <div>
            <div className="modal-title" id='title'>
                Assigned Locations for: {name} @ {practiceName}
                <button id='close-btn' onClick={onClose}>x</button>
            </div>
            <LocationList staffId={staffId} practiceId={practiceId} staffName={name} />
        </div>
    )
}