import React from "react";
import { Table } from "@sentaraui/optimahealth_web";
import useEducationViewController from "./useEducationViewController";
import "./education.styles.scss";

const colHeaders = [
  { key: "physicianEducationTypeDesc", name: "Type" },
  { key: "schoolName", name: "School" },
  { key: "yearGraduated", name: "Graduated" },
  { key: "physicianEducationDegreeTypeDesc", name: "Degree" },
  { key: "physicianEducationId", name: "DB ID" },
];

const formatYear = (datetime: string) => {
  const date = new Date(datetime);
  return date.getFullYear();
};

const EducationViewModel = (personId: { personId: number }) => {
  const { educationData } = useEducationViewController(personId);
  return (
    <div>
      <div className="edu-pop-up">
        <Table
          className="table1"
          header={colHeaders.map((item: any) => {
            return { text: item.name };
          })}
          rows={educationData?.map((item: any) =>
            colHeaders.map((col: any) => ({
              text: (
                <td>
                  {col.key === "yearGraduated"
                    ? formatYear(item[col.key])
                    : item[col.key]}
                </td>
              ),
            }))
          )}
        />
      </div>
    </div>
  );
};

export default EducationViewModel;
