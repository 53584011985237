import React from "react";
import useLanguageViewModel from "./useLanguageViewModel";
import { Table, Button } from "@sentaraui/optimahealth_web";
import "./language.scss";
import { ILanguageList, IPracticeStaffMember } from "../../../interface/staff";

interface LanguageProps {
    personId: number;
    practiceStaffMember: IPracticeStaffMember | null;
    isVisible: boolean;
    setOpenComponent: (component: string) => void;
}

const TABLE_HEADERS = [
    { text: 'Code' },
    { text: 'Language' }
];

export const Language = ({ personId, practiceStaffMember, isVisible, setOpenComponent }: LanguageProps) => {
    const {
        languageData,
        handlePopUp
    } = useLanguageViewModel(personId, practiceStaffMember);

    return (
        <div>
            <div className='main-container'>
                <Button
                    className="staff-language-button"
                    data-testid="staff-language-button"
                    onClick={() => {
                        handlePopUp();
                        setOpenComponent('language');
                    }}
                >
                    <span className={`staff-specialities-arrow ${isVisible ? 'down' : 'right'}`}>{isVisible ? '▼' : '▶'} Language </span>
                </Button>
                {isVisible && (
                    <div className="language-popup">
                        <Table
                            className='languageInfo-table'
                            header={TABLE_HEADERS}
                            rows= {
                                languageData?.map((language: ILanguageList) => [
                                    { text: language.code },
                                    { text: language.description }
                                ])
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};