import { useEffect, useState } from "react";
import { useEditPracticeQuery } from "../../services/practicesApi";
import { IEditPractice } from "../../interface/Practice";
import { initialPracticeData } from "../../../constants/constants";
import ToastNotification from "../../common/toast/toastNotification";
import { usePracticeApiSaveEditPracticeHook } from "../../hooks/usePracticeApiHooks";
import { HandleErrorMessage } from "../../common/errorMessage";

interface EditPracticeComponentViewModelProps {
    practiceId: number;
}
export const useEditPracticeComponentViewModel = ({practiceId }: EditPracticeComponentViewModelProps) => {
    const { data, isLoading } = useEditPracticeQuery({ practiceId });
    const {updatePractice} = usePracticeApiSaveEditPracticeHook() || {};
    const [editPracticeData, setEditPracticeData] = useState<IEditPractice | null>(initialPracticeData);
    const [activeTab, setActiveTab] = useState('general');
    const practiceData: IEditPractice | null = data ?? null;
    
    //ToDo: Need to refactor this function.
    useEffect(() => {
        if (practiceData && practiceData?.practiceSqcn === null) {
            setEditPracticeData({
                ...practiceData,
                practiceSqcn: {
                    mcKessonOrgId: '',
                    mcKessons: [],
                    mcKesson: 0,
                    sqcnCareManagerId: 0,
                    emrListItems: [],
                    sqcnEmrId: 0,
                    deactivationListItems: [],
                    sqcnDeactivationReasonId: 0,
                    activationDate: new Date(),
                    deactivationDate: new Date(),
                    claimStatus: false,
                    affiliate: false,
                    edI837Status: false,
                    emrStatus: false,
                    deferred: false,
                }
            } as IEditPractice);
        } else if (practiceData) {
            setEditPracticeData(practiceData);
        }
    }, [practiceData]);

    // ToDo: Need to implement the save functionality
    const handleSave = async () => {
        if (!editPracticeData) {
            ToastNotification("info", "No data to save.");
            return;
        }
    
        try {
            const response:any = await updatePractice(editPracticeData).unwrap();
            ToastNotification("success", response );
            return true;
        } catch (error: unknown) {
            if (error instanceof Error) {
                HandleErrorMessage(error, 'update practice');
            } else {
                HandleErrorMessage(new Error('An unknown error occurred'), 'update practice');
            }
        }
        return false;
    };

    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);
    };
    return {
        isLoading,
        editPracticeData,
        setEditPracticeData,
        handleSave,
        activeTab,
        handleTabClick,
    };
}