import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    IStaffSearch, ISearchFilter, IStaffLocation, IPracticeStaffMember,
    IUploadSelectedImage, SpecialityByEPIArray, IClinicalInterestByEPI, IExperienceByEPI,
    IRemoveStaffLocation,
    IUpdateStaffLocation,
    IUpdateGroup
} from '../interface/staff';
import { Response } from '../interface/response';
import { prepHeaders } from './prepHeaders';
import { IAssigneLocation } from '../interface/Filter';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/staff/`;
const tag = 'staffTag';

export const staffApi = createApi({
    reducerPath: 'staffApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl, prepareHeaders: prepHeaders }),
    tagTypes: [tag],
    endpoints: (builder) => ({
        getStaff: builder.query<IStaffSearch[], ISearchFilter>({
            query: (body) => ({
                url: 'findStaff',
                method: 'POST',
                body,
            }),
            transformResponse: (res: IStaffSearch[]) => {
                return res;
            },
            providesTags: [tag],
        }),
        getStaffLocation: builder.query<IStaffLocation, { staffId: number, practiceId: number }>({
            query: ({ staffId, practiceId }) => {
                return `ListLocation?staffId=${staffId}&locationId=${practiceId}`;
            },
            transformResponse: (response: IStaffLocation) => {
                return response
            },
            providesTags: [tag],
        }),
        editStaff: builder.query<IPracticeStaffMember, { practiceId: number, staffId: number }>({
            query: ({ practiceId, staffId }) => {
                return `editpracticestaffmember?practiceId=${practiceId}&staffId=${staffId}`;
            },
            transformResponse: (response: IPracticeStaffMember) => response,
            providesTags: [tag],
        }),
        removeStaff: builder.mutation<IStaffLocation, { practiceId: number, locationId: number, staffId: number }>({
            query: ({ practiceId, locationId, staffId }) => ({
                url: `remove?practiceId=${practiceId}&locationId=${locationId}&staffId=${staffId}`,
                method: 'DELETE',
            }),
            transformResponse: (response: IStaffLocation) => response,
            invalidatesTags: [tag],
        }),
        uploadPicture: builder.mutation<Response, IUploadSelectedImage>({
            query: (formData: IUploadSelectedImage) => ({
                url: 'uploadpicture',
                method: 'POST',
                body: formData
            }),
            invalidatesTags: [tag],
        }),
        getSpecialtiesInfo: builder.query<any, { personId: number }>({
            query: ({ personId }) => {
                return `specialtiesinfo/?personId=${personId}`;
            },
            transformResponse: (response: SpecialityByEPIArray) => response,
            providesTags: [tag],
        }),
        getExperienceInfoTypes: builder.query<IExperienceByEPI[], { personId: number }>({
            query: ({ personId }) => `experienceinfo/?personId=${personId}`,
            providesTags: [tag],
        }),
        getClinicalInterestsTypes: builder.query<IClinicalInterestByEPI[], { personId: number }>({
            query: ({ personId }) => `clinicalinterests/?personId=${personId}`,
            providesTags: [tag],
        }),

        assignLocation: builder.query<IAssigneLocation, { staffId: number, locationId: number | undefined }>({
            query: ({ staffId, locationId }) => {
                return `assignlocation?staffId=${staffId}&locationId=${locationId}`;
            },
            transformResponse: (response: IAssigneLocation) => response,
            providesTags: [tag],
        }),

        saveAssignLocation: builder.mutation<boolean, IAssigneLocation>({
            query: (body) => ({
                url: `assignlocation`,
                method: 'POST',
                body,
            }),
            transformResponse: (res: boolean) => {
                return res;
            },
            invalidatesTags: [tag],
        }),
        removeLocation: builder.mutation<boolean, IRemoveStaffLocation>({
            query: (body) => ({
                url: `removeLocation`,
                method: 'DELETE',
                body,
            }),
            transformResponse: (res: boolean) => {
                return res;
            },
            invalidatesTags: [tag],
        }),
        updateLocation: builder.mutation<boolean, IUpdateStaffLocation>({
            query: (body) => ({
                url: `updateLocation`,
                method: 'POST',
                body,
            }),
            transformResponse: (res: boolean) => {
                return res;
            },
            invalidatesTags: [tag],
        }),
        updateClinician: builder.mutation<boolean, IUpdateGroup>({
            query: (body) => ({
                url: `UpdateClinician`,
                method: 'POST',
                body,
            }),
            transformResponse: (res: boolean) => {
                return res;
            },
            invalidatesTags: [tag],
        })
    }),
});

export const {
    useGetStaffQuery,
    useGetStaffLocationQuery,
    useEditStaffQuery,
    useRemoveStaffMutation,
    useUploadPictureMutation,
    useGetSpecialtiesInfoQuery,
    useGetExperienceInfoTypesQuery,
    useGetClinicalInterestsTypesQuery,
    useAssignLocationQuery,
    useSaveAssignLocationMutation,
    useUpdateLocationMutation,
    useRemoveLocationMutation,
    useUpdateClinicianMutation,
} = staffApi;

