import { useState } from 'react';
import { FindStaffJsonResult } from '../../interface/findStaffJsonResult';
import { useRemoveSmgManagerForLocationMutation } from '../../services/locationApi';
import ToastNotification from '../../common/toast/toastNotification';
import { ILocation } from '../../models/location/location';
import { HandleErrorMessage } from '../../common/errorMessage';

interface ManagerDetail {
    managerType: number;
    typeName: string;
}

interface ManagersTabViewModelProps {
    selectedLocationDetails: ILocation;
    setSelectedLocationDetails: (location: ILocation) => void;
    inputValues: { [key: string]: string }; 
    setInputValues: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>; 
}

const useManagersTabViewModel = ({ selectedLocationDetails, setSelectedLocationDetails,inputValues, setInputValues }: ManagersTabViewModelProps) => {
    const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [removeSmgManagerForLocation] = useRemoveSmgManagerForLocationMutation();
    const [managerDetail, setManagerDetail] = useState<ManagerDetail>({ managerType: 0, typeName: '' });
    const [managerData, setManagerData] = useState<FindStaffJsonResult[]>([]);

    const searchIconClickHandler = (managerType: number, typeName: string, key: string) => {

        setManagerDetail({ managerType, typeName });
        setInputValues(prevValues => ({ ...prevValues, [key]: prevValues[key] }));
        setSearchModalOpen(true);
    };

    const onCloseHandler = () => {
        setSearchModalOpen(false);
    };

    const onDeleteHandler = (managerType: number, typeName: string, key: string) => {
        setManagerDetail({ managerType, typeName });
        setInputValues(prevValues => ({ ...prevValues, [key]: prevValues[key] }));
        setIsModalOpen(true);
        setShowModal(true);
    };

    const handleSelectItem = (name: string) => {
        setInputValues(prevValues => ({ ...prevValues, [managerDetail.typeName]: name }));
    };
    // TODO: Need to modify this function as per requirement
    const handleDeleteConfirm = async () => {
        const personId = selectedLocationDetails.smgManagersForLocation?.find((item: any) => item.smgManagerType === managerDetail.typeName)?.personId;
        try {
            const data:any = {
                locationId: selectedLocationDetails.locationID,
                managerType: managerDetail.managerType,
                personId: personId ?? 0, // Implement the logic to get the personId
                name: managerDetail.typeName ?? '',
                typeName: managerDetail.managerType,
            };
            const response:any = await removeSmgManagerForLocation(data).unwrap();
            setIsModalOpen(false);
            setShowModal(false);
            ToastNotification('success',response);
        } catch (error: unknown) {
            if (error instanceof Error) {
                HandleErrorMessage(error, 'remove smg manager location');
            } else {
                HandleErrorMessage(new Error('An unknown error occurred'), 'remove smg manager location');
            }
        }
    };

    return {
        searchModalOpen,
        isModalOpen,
        showModal,
        managerDetail,
        managerData,
        setSearchModalOpen,
        setIsModalOpen,
        setShowModal,
        setManagerDetail,
        setInputValues,
        setManagerData,
        searchIconClickHandler,
        onCloseHandler,
        onDeleteHandler,
        handleSelectItem,
        handleDeleteConfirm,
    };
};
export default useManagersTabViewModel;
