import { ChangeEvent, useEffect, useState } from 'react';
import { ILocation } from '../../models/location/location';
import { DAYS_OF_WEEK_SHORT, initialLocationState } from '../../../constants/constants';
import { useLocationApiGetLocationTypeListHook, useLocationApiValidateZipCode } from '../useLocationApi';

export const useLocationDetailsViewModel = (selectedLocationDetails: ILocation, setSelectedLocationDetails: (location: ILocation) => void, setErrors: (errors: { [key: string]: string }) => void) => {
    const { validateZipcode } = useLocationApiValidateZipCode() || {};
    const { data: locationTypeList } = useLocationApiGetLocationTypeListHook() || {};
    const [primaryLocation, setPrimaryLocation] = useState<boolean>(selectedLocationDetails.isPrimary);
    const [publicTransportation, setPublicTransportation] = useState<boolean>(selectedLocationDetails.publicTrans);
    const [eCare, setECare] = useState<boolean>(selectedLocationDetails.eCare);
    const [myChart, setMyChart] = useState<boolean>(selectedLocationDetails.myChart);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        if (!selectedLocationDetails.schedule || selectedLocationDetails.schedule.length === 0) {
            setSelectedLocationDetails({
                ...selectedLocationDetails,
                schedule: initialLocationState.schedule,
            });
        }
        // if(isSuccess){
        //     setSelectedLocationDetails({
        //         ...selectedLocationDetails,
        //         type: locationTypeList !== undefined ? locationTypeList[0].value,
        //     });
        // }
         // Update the local states based on the fetched selectedLocationDetails
         setPrimaryLocation(selectedLocationDetails.isPrimary);
         setPublicTransportation(selectedLocationDetails.publicTrans);
         setECare(selectedLocationDetails.eCare);
         setMyChart(selectedLocationDetails.myChart);
    }, [selectedLocationDetails, setSelectedLocationDetails]);
    
    const handleSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSelectedLocationDetails({
            ...selectedLocationDetails,
            type: e.target.value,
        });
    };

    const handleLostFocus = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { name, value } = e.target;
        setErrors({});
        if (value === '') {
            setErrors({ [name]: 'This field is required' });
            return;
        }
        try {
            const msg = await validateZipcode(value); // Validate the zipcode
            if (msg?.data?.toLowerCase() === 'false') {
                setErrors({ zip: 'Zip is invalid' });
                return;
            }
            if (msg?.data) {
                const [city, state] = msg.data.split(',');
                setSelectedLocationDetails({
                    ...selectedLocationDetails,
                    city: city || selectedLocationDetails.city,
                    state: state || selectedLocationDetails.state
                });
            }
        } catch (error) {
            setErrors({ zip: 'Error occurred while validating zipcode' });
        }
    };

    const handleToggleChange = (name: string, value: boolean) => {
        switch (name) {
            case 'primaryLocation':
                setPrimaryLocation(value);
                setSelectedLocationDetails({
                    ...selectedLocationDetails,
                    isPrimary: value,
                });
                break;
            case 'publicTransportation':
                setPublicTransportation(value);
                setSelectedLocationDetails({
                    ...selectedLocationDetails,
                    publicTrans: value,
                });
                break;
            case 'eCare':
                setECare(value);
                setSelectedLocationDetails({
                    ...selectedLocationDetails,
                    eCare: value,
                });
                break;
            case 'myChart':
                setMyChart(value);
                setSelectedLocationDetails({
                    ...selectedLocationDetails,
                    myChart: value,
                });
                break;
            default:
                break;
        }
    };

    const handleDateChange = (date: Date, name: string) => {
        setSelectedLocationDetails({
            ...selectedLocationDetails,
            [name]: date,
        });
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { name, value } = e.target;
        setErrors({ [name]: '' });
        setSelectedLocationDetails({
            ...selectedLocationDetails,
            [name]: value,
        });
    };

    const onDayTimeChangeHandler = (day: string, timeType: 'startTime' | 'stopTime') => (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const newTime = event.target.value;
        const updatedSchedule = selectedLocationDetails.schedule ?? DAYS_OF_WEEK_SHORT.map(d => ({ day: d, startTime: '', stopTime: '' }));

        const newState = {
            ...selectedLocationDetails,
            schedule: updatedSchedule.map(scheduleDay =>
                scheduleDay.day === day ? { ...scheduleDay, [timeType]: newTime } : scheduleDay
            ),
        };

        setSelectedLocationDetails(newState);

        const startTimesFilled = newState.schedule.filter(schedule => schedule.startTime).length;
        const stopTimesFilled = newState.schedule.filter(schedule => schedule.stopTime).length;

        if (startTimesFilled === 1 && stopTimesFilled === 1) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    };

    const copyTimeToWeekdays = () => {
        const startTimeSource = selectedLocationDetails.schedule.find(schedule => schedule.startTime);
        const stopTimeSource = selectedLocationDetails.schedule.find(schedule => schedule.stopTime);

        if (!startTimeSource || !stopTimeSource) {
            return;
        }

        const updatedSchedule = selectedLocationDetails.schedule.map(schedule => {
            if (DAYS_OF_WEEK_SHORT.includes(schedule.day) && !['Sat', 'Sun'].includes(schedule.day)) {
                return {
                    ...schedule,
                    startTime: startTimeSource.startTime,
                    stopTime: stopTimeSource.stopTime,
                };
            }
            return schedule;
        });

        setSelectedLocationDetails({
            ...selectedLocationDetails,
            schedule: updatedSchedule,
        });

        setShowModal(false);
    };

    const handleModalConfirm = () => {
        copyTimeToWeekdays();
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return {
        primaryLocation,
        publicTransportation,
        eCare,
        myChart,
        showModal,
        handleSelectChange,
        handleLostFocus,
        handleToggleChange,
        handleDateChange,
        handleInputChange,
        onDayTimeChangeHandler,
        handleModalConfirm,
        handleCloseModal,
        locationTypeList,
    };
};
