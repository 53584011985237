import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { FindStaffJsonResult } from '../interface/findStaffJsonResult';
import { prepHeaders } from './prepHeaders';
import { ILocation } from '../models/location/location';
import { ISearchFilterLocation} from '../interface/Filter';
import { ILocationTypeMaster, IStaffLocation } from '../interface/staff';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/location/`;
const tag = 'locationTag';

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders: prepHeaders }),
  tagTypes:[tag],
  endpoints: (builder) => ({
    getLocationList: builder.query<IStaffLocation, ISearchFilterLocation>({
      query: (body) =>({
          url: 'list',
          method: 'Post',
          body,
      }),
      keepUnusedDataFor: 1.0001,
      transformResponse:(res:IStaffLocation) =>{
        return res;
      },
      providesTags:[tag],
    }),

    findStaff: builder.mutation<FindStaffJsonResult[], { fname: string; lname: string }>({
      query: ({ fname, lname }) => ({
        url: `findstaff?fname=${fname}&lname=${lname}`,
        method: 'GET',
      }),
      invalidatesTags: [tag],
    }),

    addSmgManagerForLocation: builder.mutation<void, { locationId: number; personId: number; managerType: number; name: string; typeName: string }>({
      query: ({ locationId, personId, managerType, name, typeName }) => ({
        url: `AddSmgManagerForLocation?locationId=${locationId}&personId=${personId}&managerType=${managerType}&name=${name}&typeName=${typeName}`,
        method: 'GET',
      }),
      invalidatesTags: [tag],
    }),

    edit: builder.query<ILocation, { practiceId: number | null | undefined,locationId: number }>({
      query: ({ practiceId,locationId}) => ({
        url: `edit?practiceId=${practiceId}&locationId=${locationId}`,
        method: 'GET',
      }),
      transformResponse:(res:any)=>{
        return res;
      },
      providesTags: [tag],
    }),

    save: builder.mutation<string, ILocation>({
      query: (body) => ({
        url: `save`,
        method: 'POST',
        body
      }),
      invalidatesTags:[tag]
    }),

    getValidateZipCode: builder.query<string, string>({
      query: (zipcode) => ({
        url: `validatezipcode/${zipcode}`,
        method: 'GET',
      }),
    }),
    getLocationTypeList: builder.query<ILocationTypeMaster[], void>({
      query: () => ({
        url: `getLocationTypeList`,
        method: 'GET',
      }),
      providesTags: [tag],
      transformResponse:(res:ILocationTypeMaster[]) => {
        return res ?? [];
      }
    }),
    removeSmgManagerForLocation: builder.mutation<void, { locationId: number; personId: number; managerType: string | number; name: string; typeName: string }>({
      query: ({ locationId, personId, managerType, name, typeName }) => ({
        url: `RemoveSmgManagerForLocation?locationId=${locationId}&personId=${personId}&managerType=${managerType}&name=${name}&typeName=${typeName}`,
        method: 'GET',
      }),
      invalidatesTags: [tag],
    }),
  }),
});

export const {
  useEditQuery,
  useFindStaffMutation,
  useAddSmgManagerForLocationMutation,
  useRemoveSmgManagerForLocationMutation,
  useGetLocationListQuery,
  useSaveMutation,
  useLazyGetValidateZipCodeQuery,
  useGetLocationTypeListQuery
} = locationApi;