import { useState } from 'react';
import { useGetClinicalInterestsTypesQuery } from '../../../services/staffApi';

const useExperienceInfoViewModel = (personId: number) => {
    const [isVisible, setIsVisible] = useState(false);
    const [triggerQuery, setTriggerQuery] = useState(false);
    const { data } = useGetClinicalInterestsTypesQuery({ personId: personId }, { skip: !triggerQuery });
    const clinicalData = data ?? [];
    const handlePopUp = () => {
        setIsVisible(!isVisible);
        if (!isVisible) {
            setTriggerQuery(true);
        }
    };

    return {
        clinicalData,
        isVisible,
        handlePopUp
    };
};

export default useExperienceInfoViewModel;
