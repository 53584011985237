import { ChangeEvent } from 'react';
import { IEditPractice } from '../../../interface/Practice';


interface SqcnTabViewModelProps {
    editPracticeData: IEditPractice | null;
    setEditPracticeData: (editPracticeData: IEditPractice) => void;
}

export const useSqcnTabViewModel = ({ editPracticeData, setEditPracticeData }: SqcnTabViewModelProps) => {
    const handleDateChange = (date: Date, name: string) => {
        if (editPracticeData) {
            setEditPracticeData({
                ...editPracticeData,
                practiceSqcn: {
                    ...editPracticeData.practiceSqcn,
                    [name]: date,
                },
                [name]: date,
            });
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (editPracticeData) {
            setEditPracticeData({
                ...editPracticeData,
                practiceSqcn: {
                    ...editPracticeData.practiceSqcn,
                    [name]: value,
                },
            });
        }
    };

    const handleCheckboxInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        if (editPracticeData) {
            setEditPracticeData({
                ...editPracticeData,
                practiceSqcn: {
                    ...editPracticeData.practiceSqcn,
                    [name]: checked,
                },
            });
        }
    };

    // Handle Dropdown Selection for mcKessons
    const handleMcKessonChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;

        if (editPracticeData?.practiceSqcn?.mcKessons) {
            // Update mcKessons array directly by marking the selected value as true and others as false
            const updatedMcKessons = editPracticeData?.practiceSqcn.mcKessons.map(item => ({
                ...item,
                selected: item.value === selectedValue, // Mark the selected item
            }));

            // Update editPracticeData with the modified mcKessons array
            setEditPracticeData({
                ...editPracticeData,
                practiceSqcn: {
                    ...editPracticeData.practiceSqcn,
                    mcKessons: updatedMcKessons, // Update with the modified array
                },
            });
        }
    };

    // Handle Dropdown Selection for emrListItems
    const handleEmrChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;

        if (editPracticeData?.practiceSqcn?.emrListItems) {
            // Update emrListItems array by marking the selected value as true and others as false
            const updatedEmrListItems = editPracticeData?.practiceSqcn.emrListItems.map(item => ({
                ...item,
                selected: item.value === selectedValue, // Mark the selected item
            }));

            // Update editPracticeData with the modified emrListItems array
            setEditPracticeData({
                ...editPracticeData,
                practiceSqcn: {
                    ...editPracticeData.practiceSqcn,
                    emrListItems: updatedEmrListItems, // Update with the modified array
                },
            });
        }
    };
    // Handle Dropdown Selection for deactivationListItems
    const handleDeactivationChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        if (editPracticeData?.practiceSqcn?.deactivationListItems) {
            const updatedDeactivationListItems = editPracticeData?.practiceSqcn.deactivationListItems.map(item => ({
                ...item,
                selected: item.value === selectedValue,
            }));

            setEditPracticeData({
                ...editPracticeData,
                practiceSqcn: {
                    ...editPracticeData.practiceSqcn,
                    deactivationListItems: updatedDeactivationListItems,
                },
            });
        }
    };

    // Return only the selected item to be visible in the dropdowns
    const visibleMcKessons = editPracticeData?.practiceSqcn?.mcKessons?.filter(item => item.selected) || [];
    const visibleEmrListItems = editPracticeData?.practiceSqcn?.emrListItems?.filter(item => item.selected) || [];
    const visibleDeactivationListItems = editPracticeData?.practiceSqcn?.deactivationListItems?.filter(item => item.selected) || [];

    return {
        editPracticeData,
        handleDateChange,
        handleInputChange,
        handleMcKessonChange,
        handleEmrChange,
        handleDeactivationChange,
        mcKessons: visibleMcKessons,
        emrListItems: visibleEmrListItems,
        deactivationListItems: visibleDeactivationListItems,
        handleCheckboxInputChange,
    };
};