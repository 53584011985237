import ToastNotification from "./toast/toastNotification";

export const HandleErrorMessage = (error: any,logMsg?: string) => {
    const getErrorMessage = (error: any) => {
        if (!error) return "Unknown error occurred.";
        
        return (
            error.error ||
            error.data?.error ||
            error.data?.title ||
            error.data?.message ||
            "An unexpected error occurred."
        );
    };

    const errMsg = getErrorMessage(error);
    ToastNotification("error", errMsg);
    console.error(`Error occurred while ${logMsg} :`, error);
};