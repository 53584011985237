import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import MsalService from "./auth-modules/msalService";
import App from "./components/app/app";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./components/store/store";
import { DEFAULT_THEME } from "./theme";
import { ThemeContext } from "styled-components";

import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "@sentaraui/optimahealth_web";
import "react-toastify/dist/ReactToastify.css";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  const msalService = new MsalService(); // Create an instance of the MsalService class

  root.render(
    <ThemeProvider>
      <ThemeContext.Provider value={DEFAULT_THEME}>
        <Provider store={store}>
          <ToastContainer position="top-right" />
          <BrowserRouter>
            <App instance={msalService.getInstance()} />
          </BrowserRouter>
        </Provider>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
}
