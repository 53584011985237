import { Practice } from "../interface/PracticesLists";
import { ISearchFilter, IStaffSearch} from "../interface/staff"
import { Search } from "./search"
import StaffList from "./staffList"
interface StaffScreenProps {
    searchResults: IStaffSearch[];
    setSearchResults: React.Dispatch<React.SetStateAction<IStaffSearch[]>>;
    practiceId: number | null;
    isSkip: boolean;
    selectedPractice: Practice | null;
    searchCriteria: ISearchFilter;
    setSearchCriteria: React.Dispatch<React.SetStateAction<ISearchFilter>>;
}

export const StaffScreen = ( props: StaffScreenProps) => {
    return (
        <div className='staff-container'  >
            <div className='staff-title'> View / Edit Staff</div>
            <Search setSearchResults={props.setSearchResults} practiceId={props.practiceId} isSkip= {props.isSkip} searchCriteria={props.searchCriteria} setSearchCriteria={props.setSearchCriteria}/>
            <StaffList searchResults={props.searchResults} practiceId={props.practiceId}
             selectedPractice ={props.selectedPractice}/>
        </div>
    )
}