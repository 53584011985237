import { useState } from 'react';
import { useGetExperienceInfoTypesQuery } from '../../../services/staffApi';

const useExperienceInfoViewModel = (personId: number) => {
    const [triggerQuery, setTriggerQuery] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const { data } = useGetExperienceInfoTypesQuery({ personId: personId }, { skip: !triggerQuery });
    const experienceData = data ?? [];
    const handlePopUp = () => {
        setIsVisible(!isVisible);
        if (!isVisible) {
            setTriggerQuery(true);
        }
    };

    return {
        experienceData,
        isVisible,
        handlePopUp
    };
};

export default useExperienceInfoViewModel;
