import React, { useEffect, useState, useCallback } from 'react';
import './physicianProfile.scss';
import { usePhysicianProfileData, storePhysicianData } from './usePhysicianProfileData';
import ToggleSwitch from './toggleSwitch';
import {
    Input,
    Label,
    SelectDropdown,
    DatePicker,
} from "@sentaraui/optimahealth_web";
import { IPracticeStaffMember } from '../../interface/staff';

interface IPhysicianProfileProps {
    practiceStaffMember: IPracticeStaffMember | null,
    isSentara:boolean,
    setIsSentara:React.Dispatch<React.SetStateAction<boolean>>,
}

const PhysicianProfile: React.FC<IPhysicianProfileProps> = ({
    practiceStaffMember,
    isSentara,
    setIsSentara,
}) => {
    const { physicianData, setPhysicianData } = usePhysicianProfileData(practiceStaffMember);
    const [showCalendar, setShowCalendar] = useState<boolean>(false);

    const [toggles, setToggles] = useState({
        newAppointments: false,
        participatesInResearch: false,
        sentara: (practiceStaffMember?.physicianInfo?.supressFromSentaraDotCom === 'Yes'),
    });


    useEffect(() => {
        storePhysicianData(physicianData, practiceStaffMember);
    }, [physicianData, practiceStaffMember, toggles]);

    const handleToggleChange = useCallback((value: boolean, name: string) => {
        setToggles(prevState => ({
            ...prevState,
            [name]: value
        }));
        name === 'sentara' && setIsSentara((prev) => !prev);
    }, [setIsSentara]);

    const handleDateChange = (date: Date, name: string) => {
        setPhysicianData((prevState) => ({
            ...prevState,
            [name]: date
        }));
    };

    const handleInputChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        if (name === 'practicingStatus') {
            const updatedPracticingStatus = practicingStatusTypes.find(practicingStatus => practicingStatus.value === newValue)?.label;
            setPhysicianData((prevState) => ({
                ...prevState,
                [name]: updatedPracticingStatus
            }));
            setShowCalendar(newValue !== '0' && newValue !== '1');
        } else {
            setPhysicianData((prevState) => ({
                ...prevState,
                [name]: newValue
            }));
        }
    };

    const practicingStatusTypes = [
        { value: '0', label: 'N/A' },
        { value: '1', label: 'Active' },
        { value: '2', label: 'Deceased' },
        { value: '3', label: 'Other' }
    ];

    const statusTypes = [
        { value: '0', label: ' ' },
        { value: '1', label: 'A001' },
        { value: '2', label: 'A002' },
        { value: '3', label: 'A003' },
        { value: '4', label: 'A004' },
        { value: '5', label: 'A005' },
        { value: '6', label: 'A006' }
    ];

    return (
        <div className='main-physician-wrapper'>
            <div className='physician-profile-header-wrapper'>
                <div className='physician-profile-practicing-wrapper dissable-contents'>
                    <div className='physician-row'>
                        <div className='practice-status'>
                            <Label className="physician-label" htmlFor="html">
                                Practicing Status:
                            </Label>
                            <Input
                                className="practicingStatus-tab-input"
                                type="text"
                                id="practicingStatus"
                                name="practicingStatus"
                                dataTestId="practicingStatus"
                                value={practiceStaffMember?.physicianInfo?.practicingStatus ?? ''}
                            />
                        </div>
                        <div className='since-calender'>
                            {showCalendar && (
                                <div className='physician-calender-side-by-side'>
                                    <Label className="physician-label" htmlFor="html">
                                        Since:
                                    </Label>
                                    <DatePicker
                                        className="physician-input"
                                        id="since"
                                        name="since"
                                        onChange={handleDateChange}
                                        data-testid="since"
                                        placeholder={practiceStaffMember?.physicianInfo?.since ?? ''}
                                        selectedValue={physicianData.since}
                                        updateValue={(date: Date) => handleDateChange(date, 'since')}
                                        disabled
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='physician-profile-appointment-wrapper'>
                    <div className='sentaradiv'>
                        <div className='physician-row'>
                            <Label className="physician-label" htmlFor="html">
                                Sentara.com?
                            </Label>
                            <ToggleSwitch
                                selected={toggles.sentara}
                                onToggle={(value) => handleToggleChange(value, 'sentara')}
                                name="sentara"
                                optionLabels={{ falseOption: 'Show', trueOption: 'Hide'  }}
                                dataTestId="sentaraToggle"
                            />
                        </div>
                    </div>
                    <div className='appointmentdiv dissable-contents'>
                        <div className='physician-row'>
                            <Label className="physician-label" htmlFor="html">
                                New Appointments?
                            </Label>
                            <ToggleSwitch
                                selected={toggles.newAppointments}
                                onToggle={(value) => handleToggleChange(value, 'newAppointments')}
                                name="newAppointments"
                                optionLabels={{ trueOption: 'Yes', falseOption: 'No' }}
                                dataTestId="newAppointmentsToggle"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='dissable-contents'>
            <div className='physician-profile-homeaddress-wrapper'>
                <div className='homeaddress-header'>
                    Home Address
                </div>
                <hr className='solid-line' />
                <div className='homeaddress-content'>
                    <div className='physician-row'>
                        <div className='streetaddress'>
                            <Label className="physician-label" htmlFor="html">
                                Street:
                            </Label>
                            <Input
                                className="physician-content-tab-input"
                                type="text"
                                id="street"
                                name="street"
                                dataTestId="street"
                                value={practiceStaffMember?.physicianInfo?.homeAddress ?? ''}
                            />
                        </div>
                    </div>
                    <div className='physician-row'>
                        <div className='homeaddress'>
                            <div className='cityaddress'>
                                <Label className="physician-label" htmlFor="html">
                                    City:
                                </Label>
                                <Input
                                    className="physician-content-tab-input"
                                    type="text"
                                    id="city"
                                    name="city"
                                    dataTestId="city"
                                    value={practiceStaffMember?.physicianInfo?.homeCity ?? ''}
                                />
                            </div>
                            <div className='stateaddress'>
                                <Label className="physician-label" htmlFor="html">
                                    State:
                                </Label>
                                <Input
                                    className="physician-content-tab-input"
                                    type="text"
                                    id="state"
                                    name="state"
                                    dataTestId="state"
                                    value={practiceStaffMember?.physicianInfo?.homeState ?? ''}
                                />
                            </div>
                            <div className='zipaddress'>
                                <Label className="physician-label" htmlFor="html">
                                    Zip:
                                </Label>
                                <Input
                                    className="physician-content-tab-input"
                                    type="text"
                                    id="zip"
                                    name="zip"
                                    dataTestId="zip"
                                    value={practiceStaffMember?.physicianInfo?.homeZip ?? ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='physian-profile-info-wrapper'>
                <div className='physician-profile-participation-wrapper'>
                    <div className='content-header'>
                        Participation
                    </div>
                    <hr className='solid-line' />
                    <div className='participation-toggleWrapper'>
                        <div className='physician-row'>
                            <Label className="physician-label" htmlFor="html">
                                Participates In Research?:
                            </Label>
                            <ToggleSwitch
                                selected={toggles.participatesInResearch}
                                onToggle={handleToggleChange}
                                name="participatesInResearch"
                                optionLabels={{ trueOption: 'Yes', falseOption: 'No' }}
                                dataTestId="participatesInResearchToggle"
                            />
                        </div>
                    </div>
                    <div className='physician-row'>
                        <Label className="physician-label" htmlFor="html">
                            Biocard:
                        </Label>
                        <Input
                            className="physician-input"
                            type="text"
                            id="biocard"
                            name="biocard"
                            onChange={handleInputChange}
                            dataTestId="biocard"
                            placeholder={practiceStaffMember?.physicianInfo?.bioCard ?? ''}
                            value={practiceStaffMember?.physicianInfo?.bioCard ?? ''}
                        />
                    </div>
                    <div className='physician-row'>
                        <Label className="physician-label" htmlFor="html">
                            Commitment Training:
                        </Label>
                        <DatePicker
                            className="physician-input"
                            id="commitmentTraining"
                            name="commitmentTraining"
                            onChange={handleDateChange}
                            dataTestId="commitmentTraining"
                            placeholder={practiceStaffMember?.physicianInfo?.commitmentTraining ?? ''}
                            selectedValue={practiceStaffMember?.physicianInfo?.commitmentTrainingDate ?? ''}
                            updateValue={(date: Date) => handleDateChange(date, 'commitmentTraining')}
                            disabled
                        />
                    </div>
                    <div className='physician-row'>
                        <Label className="physician-label" htmlFor="html">
                            Saftey Champian Training:
                        </Label>
                        <DatePicker
                            className="physician-input"
                            id="safteyChampianTraining"
                            name="safteyChampianTraining"
                            onChange={handleDateChange}
                            dataTestId="safteyChampianTraining"
                            placeholder={practiceStaffMember?.physicianInfo?.safteyChampianTraining ?? ''}
                            selectedValue={practiceStaffMember?.physicianInfo?.safetyChampionTrainingDate ?? ''}
                            updateValue={(date: Date) => handleDateChange(date, 'safteyChampianTraining')}
                            disabled
                        />
                    </div>
                </div>
                <div className='physician-profile-medicalGroup-wrapper'>
                    <div className='content-header'>
                        Sentara Medical Group
                    </div>
                    <hr className='solid-line' />
                    <div className='physician-medical-row'>
                        <Label className="physician-medical-label" htmlFor="html" >
                            Status:
                        </Label>
                        <SelectDropdown
                            id="status"
                            dataTestId='status'
                            value={practiceStaffMember?.physicianInfo?.smgStatus ?? ''}
                            name="Status"
                            onChange={handleInputChange}
                            className="physician-medical-dropdown"
                            disabled={true}
                        >
                            {statusTypes.map(type => (
                                <option key={type.value} value={type.value}>
                                    {type.label}
                                </option>
                            ))}
                        </SelectDropdown>
                    </div>
                    <div className='physician-medical-row'>
                        <Label className="physician-medical-label" htmlFor="html">
                            Content:
                        </Label>
                        <Input
                            className="physician-medical-input"
                            type="number"
                            id="content"
                            name="content"
                            onChange={handleInputChange}
                            dataTestId="content"
                            placeholder={practiceStaffMember?.physicianInfo?.content ?? ''}
                            value={practiceStaffMember?.physicianInfo?.smgContent ?? ''}
                        />
                    </div>
                    <div className='physician-medical-row'>
                        <Label className="physician-medical-label" htmlFor="html">
                            Start:
                        </Label>
                        <Input
                            className="physician-medical-input"
                            type="text"
                            id="start"
                            name="start"
                            onChange={handleInputChange}
                            dataTestId="start"
                            placeholder={practiceStaffMember?.physicianInfo?.start ?? ''}
                            value={practiceStaffMember?.physicianInfo?.smgStartDate ?? ''}
                        />
                    </div>
                    <div className='physician-medical-row'>
                        <Label className="physician-medical-label" htmlFor="html">
                            End:
                        </Label>
                        <Input
                            className="physician-medical-input"
                            type="text"
                            id="end"
                            name="end"
                            onChange={handleInputChange}
                            dataTestId="end"
                            placeholder={practiceStaffMember?.physicianInfo?.end ?? ''}
                            value={practiceStaffMember?.physicianInfo?.smgEndDate ?? ''}
                        />
                    </div>
                </div>
            </div>
            <div className='physician-profile-contentwrapper'>
                <div className='content-header'>
                    Content
                </div>
                <hr className='solid-line' />
                <div className='physician-row'>
                    <Label className="physician-label" htmlFor="html">
                        Video link:
                    </Label>
                    <Input
                        className="physician-content-tab-input"
                        type="url"
                        id="videoLink"
                        name="videoLink"
                        onChange={handleInputChange}
                        dataTestId="videoLink"
                        placeholder={practiceStaffMember?.physicianInfo?.videoLink ?? ''}
                        value={practiceStaffMember?.physicianInfo?.videoUrl ?? ''}
                    />
                </div>
                <div className='physician-row'>
                    <Label className="physician-label" htmlFor="html">
                        Video link text:
                    </Label>
                    <Input
                        className="physician-content-tab-input"
                        type="text"
                        id="videoLinkText"
                        name="videoLinkText"
                        onChange={handleInputChange}
                        dataTestId="videoLinkText"
                        placeholder={practiceStaffMember?.physicianInfo?.videoLinkText ?? ''}
                        value={practiceStaffMember?.physicianInfo?.videoUrlText ?? ''}
                    />
                </div>
                <div className='physician-row'>
                    <Label className="physician-label" htmlFor="html">
                        Website:
                    </Label>
                    <Input
                        className="physician-content-tab-input"
                        type="url"
                        id="website"
                        name="website"
                        onChange={handleInputChange}
                        dataTestId="website"
                        placeholder={practiceStaffMember?.physicianInfo?.website ?? ''}
                        value={practiceStaffMember?.physicianInfo?.webSiteUrl ?? ''}
                    />
                </div>
                <div className='physician-row'>
                    <Label className="physician-label" htmlFor="html">
                        Website link text:
                    </Label>
                    <Input
                        className="physician-content-tab-input"
                        type="text"
                        id="websiteLinkText"
                        name="websiteLinkText"
                        onChange={handleInputChange}
                        dataTestId="websiteLinkText"
                        placeholder={practiceStaffMember?.physicianInfo?.websiteLinkText ?? ''}
                        value={practiceStaffMember?.physicianInfo?.webSiteUrlText ?? ''}
                    />
                </div>
            </div>
            </div>
        </div>
    );
};

export default PhysicianProfile;
