import React, { useEffect, useRef, useState } from "react";
import "./headerStyle.scss";
import { ModalOverlayWithoutClose } from "@sentaraui/optimahealth_web";
import { About } from "../about/about";
import { Supergroup } from "../supergroup/supergroup";

export const CustomSelectOption = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuperGroupModalOpen, setIsSuperGroupModalOpen] = useState(false);
  const menuItemsData = [
    {
      title: "Super Groups",
      component: <Supergroup onClose={() => setIsSuperGroupModalOpen(false)} />,
      setIsModalOpen: setIsSuperGroupModalOpen,
    },
    {
      title: "Proxy List",
      component: <div>Proxy List</div>,
    },
    {
      title: "About",
      url: "/about",
      component: <About onClose={() => setIsModalOpen(false)} />,
      setIsModalOpen: setIsModalOpen,
    },
  ];
  const handleItemClick = (
    event: React.MouseEvent,
    item: (typeof menuItemsData)[0]
  ) => {
    event.preventDefault();
    setModalContent(item.component);
    if (item.setIsModalOpen) {
      item.setIsModalOpen(true);
      setIsOpen(false);
    }
  };
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, []);
  return (
    <div
      className="custom-select-dropdown"
      data-testid="custom-select"
      ref={dropdownRef}
    >
      <button
        data-testid="select-button"
        className="select-button ui-corner-left ui-state-default"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span style={{ fontSize: "8px" }}>⚙️ ▼</span>
      </button>
      {isOpen && (
        <ul className="select-option-list" data-testid="select-option-list">
          <li className="li-title">Settings</li>
          {menuItemsData.map((item, index) => (
            <li key={index} className="menu-item">
              <button
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  handleItemClick(event, item)
                }
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: "0",
                  textAlign: "left",
                }} // Customize styles as needed
              >
                {item.title}
              </button>
            </li>
          ))}
        </ul>
      )}
      <ModalOverlayWithoutClose
        isOpen={isSuperGroupModalOpen}
        onClose={() => setIsSuperGroupModalOpen(false)}
        data-testid="modal-overlay-close"
        className="modal-w-medium"
      >
        {modalContent}
      </ModalOverlayWithoutClose>
      <ModalOverlayWithoutClose
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data-testid="modal-overlay-close"
        className="modal-w-medium"
      >
        {modalContent}
      </ModalOverlayWithoutClose>
    </div>
  );
};
export default CustomSelectOption;
