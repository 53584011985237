import React from 'react';
import { SelectDropdown, Input, Label, DatePicker, TextArea, ModalOverlayWithoutClose } from "@sentaraui/optimahealth_web";
import ToggleButton from '../../toggleButton/toggleButton';
import ConfirmationModal from '../../modal/confirmationModal';
import { ILocation } from '../../models/location/location';
import { useLocationDetailsViewModel } from './useLocationDetailsViewModel ';
import { DAYS_OF_WEEK_SHORT } from '../../../constants/constants';
import "./locationDetailsStyle.scss";
import { ILocationTypeMaster } from '../../interface/staff';

const LocationTypeOptions = ({ locationTypeList }:{locationTypeList?: ILocationTypeMaster[]}) => (
    <>
        {locationTypeList?.map((locationType: any) => (
            <option key={locationType.type} value={locationType.type}>
                {locationType.description}
            </option>
        ))}
    </>
);

interface LocationDetailsProps {
    isEdit: boolean;
    selectedLocationDetails: ILocation;
    setSelectedLocationDetails: (location: ILocation) => void;
    errors: { [key: string]: string };
    setErrors: (errors: { [key: string]: string }) => void;
}

const LocationDetails: React.FC<LocationDetailsProps> = ({
    isEdit,
    selectedLocationDetails,
    setSelectedLocationDetails,
    errors,
    setErrors
}) => {
    const {
        primaryLocation,
        publicTransportation,
        eCare,
        myChart,
        showModal,
        handleSelectChange,
        handleLostFocus,
        handleToggleChange,
        handleDateChange,
        handleInputChange,
        onDayTimeChangeHandler,
        handleModalConfirm,
        handleCloseModal,
        locationTypeList,
    } = useLocationDetailsViewModel(selectedLocationDetails, setSelectedLocationDetails, setErrors);

    return (
        <>
            <div className="location-dropdown">
                <Label className='location-label'>{`EDP LocationID ${isEdit ? selectedLocationDetails?.locationID : '0'}`}</Label>
                <div className="location-type-dropdown">
                    <Label
                        className="location-label"
                        htmlFor="html"
                    >Location Type</Label>
                    <span>
                        <SelectDropdown
                            className="form-select"
                            dataTestId="custom-select"
                            onChange={handleSelectChange}
                            name="type"
                            disabled={isEdit}
                            value={selectedLocationDetails?.type || ''}
                        >
                            <LocationTypeOptions locationTypeList={locationTypeList} />
                        </SelectDropdown>
                    </span>
                </div>
            </div>
            <div className='horizontal-line'></div>
            <div className="inner-container">
                <div className="form-wrapper">
                    <div>
                        <Label
                            className="location-label"
                            htmlFor="html">
                            Name
                        </Label>
                        <Input
                            className="location-input"
                            type="text"
                            id="name"
                            name="name"
                            dataTestId="name_field"
                            onChange={handleInputChange}
                            value={selectedLocationDetails?.name || selectedLocationDetails?.location || ''}
                        />
                        {errors.name && <div className="error">{errors.name}</div>}
                    </div>
                    <div>
                        <Label
                            className="location-label"
                            htmlFor="html">
                            Address
                        </Label>
                        <Input
                            className="location-input"
                            type="text"
                            id="address1"
                            name="address1"
                            dataTestId="input_field"
                            onChange={handleInputChange}
                            value={selectedLocationDetails?.address1 || ''}
                        />
                        {errors.address1 && <div className="error">{errors.address1}</div>}
                    </div>
                    <div>
                        <Label
                            className="location-label"
                            dataTestId="inputLabel_1"
                            htmlFor="html">
                            Address2
                        </Label>
                        <Input
                            className="location-input"
                            type="text"
                            id="address2"
                            name="address2"
                            dataTestId="input_field"
                            onChange={handleInputChange}
                            value={selectedLocationDetails?.address2 || ''}
                        />
                    </div>
                    <div>
                        <Label
                            className="location-label"
                            dataTestId="inputLabel_1"
                            htmlFor="html">
                            Address3
                        </Label>
                        <Input
                            className="location-input"
                            type="text"
                            id="Address3"
                            name="address3"
                            dataTestId="input_field"
                            onChange={handleInputChange}
                            value={selectedLocationDetails?.address3 || ''}
                        />
                    </div>
                    <div>
                        <Label
                            className="location-label"
                            dataTestId="inputLabel_1"
                            htmlFor="html"
                        >
                            City / State / Zip
                        </Label>
                    </div>
                    <div>
                        <div>
                            <Input
                                className="location-input-medium"
                                type="text"
                                id="city"
                                name="city"
                                dataTestId="city_field"
                                onChange={handleInputChange}
                                placeholder="City"
                                value={selectedLocationDetails?.city || ''}
                            />
                            {errors.city && <div className="error">{errors.city}</div>}
                            <Input
                                className="location-input-small"
                                type="text"
                                id="state"
                                name="state"
                                dataTestId="input_field"
                                onChange={handleInputChange}
                                placeholder="State"
                                value={selectedLocationDetails?.state || ''}
                            />
                            {errors.state && <div className="error">{errors.state}</div>}
                        </div>
                        <Input
                            className="location-input-medium"
                            type="text"
                            id="zip"
                            name='zip'
                            dataTestId="zip_field"
                            onChange={handleInputChange}
                            onBlur={handleLostFocus}
                            placeholder="zip"
                            value={selectedLocationDetails?.zip || ''}
                        />
                        {errors.zip && <div className="error">{errors.zip}</div>}
                    </div>
                    <div className="phone-fax-wrapper">
                        <div>
                            <Label
                                className="location-label"
                                htmlFor="html">
                                Telephone
                            </Label>
                            <Input
                                className="location-phone-input"
                                type="text"
                                id="phone"
                                name="phone"
                                dataTestId="input_field"
                                onChange={handleInputChange}
                                value={selectedLocationDetails?.phone || ''}
                            />
                        </div>
                        <div>
                            <Label
                                className="location-label"
                                htmlFor="html">
                                Fax
                            </Label>
                            <Input
                                className="location-input"
                                type="text"
                                id="fax"
                                name="fax"
                                dataTestId="input_field"
                                onChange={handleInputChange}
                                value={selectedLocationDetails?.fax || ''}
                            />
                        </div>
                    </div>
                </div>
                <div className='vertical-line'></div>
                <div>
                    <div className='date-picker-box'>
                        <Label className="location-label">
                            Effective Start
                        </Label>
                        <DatePicker
                            className="custom-date-picker"
                            name="effectiveStart"
                            dataTestId="start_date_picker"
                            placeholder="Select Date"
                            selectedValue={selectedLocationDetails?.effectiveStart ? new Date(selectedLocationDetails.effectiveStart).toLocaleDateString('en-CA') : ''}
                            updateValue={(date: Date) => handleDateChange(date, 'effectiveStart')}
                        />
                        <Label className="location-label">
                            Effective Stop
                        </Label>
                        <DatePicker
                            className="custom-date-picker"
                            name="effectiveStop"
                            dataTestId="stop_date_picker"
                            placeholder="Select Date"
                            selectedValue={selectedLocationDetails?.effectiveStop ? new Date(selectedLocationDetails.effectiveStop).toLocaleDateString('en-CA') : ''}
                            updateValue={(date: Date) => handleDateChange(date, 'effectiveStop')}
                        />
                    </div>
                    <div className='toggle-wrapper'>
                        <Label className="location-label">
                            Primary location?
                        </Label>
                        <hr />
                        <ToggleButton
                            dataTestId="toggle_isPrimary"
                            value={primaryLocation ?? false}
                            onToggle={(newValue) => handleToggleChange('primaryLocation', newValue)}
                        />
                    </div>
                    <div className='toggle-wrapper'>
                        <Label className="location-label">
                            Public Transportation?
                        </Label>
                        <hr />
                        <ToggleButton
                            dataTestId="toggle_publicTrans"
                            value={publicTransportation ?? false}
                            onToggle={(newValue) => handleToggleChange('publicTransportation', newValue)}
                        />
                    </div>
                    <div className='toggle-wrapper'>
                        <Label className="location-label">
                            eCare?
                        </Label>
                        <hr />
                        <ToggleButton
                            dataTestId='toggle_eCare'
                            value={eCare ?? false}
                            onToggle={(newValue) => handleToggleChange('eCare', newValue)}
                        />
                    </div>
                    <div className='toggle-wrapper'>
                        <Label className="location-label">
                            myChart?
                        </Label>
                        <hr />
                        <ToggleButton
                            dataTestId='toggle_myChart'
                            value={myChart ?? false}
                            onToggle={(newValue) => handleToggleChange('myChart', newValue)}
                        />
                    </div>
                    <div>
                        <Label className="location-label" htmlFor="labHtml">
                            Lab Corp Facility ID
                        </Label>
                        <Input
                            className="location-input"
                            type="text"
                            id="lab"
                            name="labCorpFacilityId"
                            dataTestId="input_field"
                            onChange={handleInputChange}
                            value={selectedLocationDetails?.labCorpFacilityId || ''}
                        />
                    </div>
                </div>
            </div>
            <div className='form-wrapper'>
                <Label
                    className="location-label"
                    htmlFor="businessHours">
                    Business Hours
                </Label>
                <div className='business-horizontal-line'></div>
                <div className='names-of-days'>
                    {DAYS_OF_WEEK_SHORT?.map(day => (
                        <Label key={day} className="location-label">{day}</Label>
                    ))}
                </div>
                <div className="days-row">
                    <Label className="location-label">Start</Label>
                    {DAYS_OF_WEEK_SHORT?.map(day => (
                        <Input
                            key={day}
                            dataTestId='time_start'
                            className="time-input-small"
                            id={`start-${day.toLowerCase()}`}
                            onChange={onDayTimeChangeHandler(day, 'startTime')}
                            type="time"
                            name={`start-${day.toLowerCase()}`}
                            value={selectedLocationDetails?.schedule?.find(schedule => schedule.day === day)?.startTime || ''}
                        />
                    ))}
                </div>
                <div className="days-row">
                    <Label className="location-label">Stop</Label>
                    {DAYS_OF_WEEK_SHORT?.map(day => (
                        <Input
                            key={day}
                            dataTestId='time_stop'
                            className="time-input-small"
                            id={`stop-${day.toLowerCase()}`}
                            type="time"
                            name={`stop-${day.toLowerCase()}`}
                            value={selectedLocationDetails?.schedule?.find(schedule => schedule.day === day)?.stopTime || ''}
                            onChange={onDayTimeChangeHandler(day, 'stopTime')}
                        />
                    ))}
                </div>
                <hr />
                <div className='text-area-box'>
                    <div className='external-comments'>
                        <Label
                            className="location-label"
                            htmlFor="internalComments">
                            Internal Comments
                        </Label>
                        <TextArea
                            className="form-control-custom"
                            cols={25}
                            id="textareaId"
                            name="internalComments"
                            type="text"
                            dataTestId="internal-comments-field"
                            onChange={handleInputChange}
                            rows={2}
                            placeholder="internal Comments"
                            value={selectedLocationDetails?.internalComments || ''}
                        />
                    </div>
                    <div className='external-comments'>
                        <Label
                            className="location-label"
                            htmlFor="externalComments">
                            External Comments
                        </Label>
                        <TextArea
                            className="form-control-custom"
                            cols={25}
                            rows={2}
                            type="text"
                            id="textareaId"
                            dataTestId='external-comments-field'
                            name="externalComments"
                            onChange={handleInputChange}
                            placeholder="External Comments"
                            value={selectedLocationDetails?.externalComments || ''}
                        />
                    </div>
                </div>
            </div>
            {showModal && (
                <ModalOverlayWithoutClose
                    isOpen={showModal}
                    onClose={handleCloseModal}
                    className="confirm-weekdays-time-modal"
                >
                    <ConfirmationModal
                        header='Copy Weekdays'
                        message='Would you like to copy this to all weekdays?'
                        onConfirm={handleModalConfirm}
                        onCancel={handleCloseModal}
                        confirmText='Yes'
                        cancelText='No'
                    />
                </ModalOverlayWithoutClose>
            )}
        </>
    )
};
export default LocationDetails;