import { ModalOverlayWithoutClose, Button } from '@sentaraui/optimahealth_web'
import { useEffect } from 'react';
import { useLocationViewModel } from './useLocationViewModel';
import './listLocation.scss';
import { IStaffLocation, IStaffLocationItem } from '../../interface/staff';
import { ListLocationButtons } from './actionTypes';
interface LocationListProps {
    staffId: number;
    practiceId: number;
    staffName:string;

}
export const LocationList: React.FC<LocationListProps> = ({ staffId, practiceId, staffName }) => {

    const { data, fieldToHeaderMapping, setSelectedLocation,
        selectedStaffData, setSelectedStaffData, setSelectedRowIndex,
        selectedRowIndex, modalTitle, setIsModalOpen, handleButtonClick,
        modalContent, isModalOpen } = useLocationViewModel({ staffId, practiceId, staffName });
    const tableHeaders = Object.values(fieldToHeaderMapping || {});

    useEffect(() => {
        if (data) {
            setSelectedStaffData(data);
        }
    }, [data, selectedStaffData, setSelectedStaffData]);
    const handleRowClick = (location: IStaffLocationItem, index: number, data: IStaffLocation) => {
        setSelectedRowIndex(index);
        setSelectedLocation(location);
    }
    return (
        <div className='location-list-wrapper'>
            <table className="loc-list-table">
                <thead>
                    <tr>
                        {tableHeaders?.map(header => (
                            <th key={header}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>

                    {data?.locations?.map((location: IStaffLocationItem, index: number) => (
                        <tr
                            key={location.locationID}
                            onClick={() => handleRowClick(location, index, data)}
                            data-testid={`staff-row-${index}`}
                            className={`location-item ${index === selectedRowIndex ? 'selected' : ''}`}
                        >
                            {Object.keys(fieldToHeaderMapping || {}).map((field: string) => {
                                const typedField = field as keyof IStaffLocationItem;
                                let value = location[typedField] || location[typedField.toLowerCase() as keyof IStaffLocationItem];
                                if (field === 'isPrimaryLocation' || field === 'isPrimaryPractice') {
                                    value = value ? 'Yes' : 'No';
                                }
                                return (
                                    <td key={`${location.locationID}_${field}_${index}`}>
                                        {value}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}

                </tbody>
            </table>

            <div>
                <hr />
                <div className='button-wrapper'>
                    {Object.values(ListLocationButtons).map(action => (
                        <div key={action}>
                            <Button
                                className="list-button"
                                id="primary"
                                data-testid={`staff-${action.toLowerCase()}-button`}
                                onClick={handleButtonClick(action)}
                                disabled={action !== 'Assign' && selectedRowIndex === null}
                            >
                                {action}
                            </Button>
                        </div>
                    ))}
                    <ModalOverlayWithoutClose
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        data-testid="modal-overlay-close"
                        className="modal-search-location"
                    >
                        <div className="location-modal-title" id='title'>
                            {modalTitle}
                            <Button id='close-btn' onClick={() => setIsModalOpen(false)}>x</Button>
                        </div>
                        {modalContent}
                    </ModalOverlayWithoutClose>
                </div>
            </div>
        </div>
    )
}