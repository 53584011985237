/**
 * `toastNotification.tsx` is a React component that renders a toast notification.
 *
 * Toast notifications are small, temporary pop-up windows that provide feedback about an operation, such as saving a form or copying an item to the clipboard.
 *
 * The `ToastNotification` component receives several props:
 * - `message`: the message to be displayed in the notification.
 * - `type`: the type of the notification (e.g., "success", "error", "info").
 * - `isOpen`: a boolean indicating whether the notification is currently open.
 * - `onClose`: a function to be executed when the notification is closed.
 *
 * The `ToastNotification` component is exported so it can be imported and used in other parts of the application.
 */

import { toast } from "react-toastify";

function ToastNotification (
    type: string,
    message?: string){
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "info":
        toast.info(message);
        break;
      default:
        break;
    }
}

export default ToastNotification;
