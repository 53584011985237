import { Route, Routes } from "react-router-dom";
import {
  IAppRoute,
  AppRouteType,
} from "../../auth-modules/Interfaces/AuthInterfaces";
import Layout from "../layout/layout";
import { AuthGuard } from "../../auth-modules/components/AuthGuard";

export const routes: IAppRoute[] = [
  {
    name: "Home",
    path: "/",
    element: (route: IAppRoute) => (
        <Layout />
    ),
    routeType: AppRouteType.Authenticated,
  },
];

const AppRoutes = () => {

  return (
    <Routes>
      {routes.map((route: IAppRoute) => (
        <Route
          key={route.name}
          path={route.path}
          element={<AuthGuard {...route} />}
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
