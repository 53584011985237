import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { User } from '../interface/home';
import { prepHeaders } from './prepHeaders';
import { ISuperGroup } from '../interface/SuperGroup';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/home/`;
const tag = 'HomeTag';

export const homeApi = createApi({
    reducerPath: 'homeApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl, prepareHeaders: prepHeaders }),
    tagTypes: [tag],
    endpoints: (builder) => ({
        getAboutUs: builder.query<User, void>({
            query: () => 'getabout',
            providesTags: [tag],
        }),
        getsupergroups: builder.query<ISuperGroup, void>({
            query: () => 'getsupergroups',
            providesTags: [tag],
        }),
    }),
});

export const { useGetAboutUsQuery,
    useGetsupergroupsQuery
 } = homeApi;
