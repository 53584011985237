import React from 'react';
import './toggleSwitch.scss';

interface ToggleSwitchProps {
    selected: boolean;
    onToggle: (value: boolean, name: string) => void;
    name: string;
    optionLabels: {
        trueOption: string;
        falseOption: string;
    };
    dataTestId?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ selected, onToggle, name, optionLabels, dataTestId }) => {
    const handleChange = (value: boolean) => {
        onToggle(value, name);
    };

    const yesInputId = `${name}-yes`;
    const noInputId = `${name}-no`;

    const renderToggle = (isSentara: boolean) => (
        <>
            <input
                type="radio"
                id={isSentara ? noInputId : yesInputId}
                name={name}
                value={isSentara ? "no" : "yes"}
                checked={isSentara ? !selected : selected}
                onChange={() => handleChange(isSentara ? false : true)}
            />
            <label
                htmlFor={isSentara ? noInputId : yesInputId}
                className={isSentara ? (!selected ? 'active' : '') : (selected ? 'active' : '')}
            >
                {isSentara ? optionLabels.falseOption : optionLabels.trueOption}
            </label>
            <input
                type="radio"
                id={isSentara ? yesInputId : noInputId}
                name={name}
                value={isSentara ? "yes" : "no"}
                checked={isSentara ? selected : !selected}
                onChange={() => handleChange(isSentara ? true : false)}
            />
            <label
                htmlFor={isSentara ? yesInputId : noInputId}
                className={isSentara ? (selected ? 'active' : '') : (!selected ? 'active' : '')}
            >
                {isSentara ? optionLabels.trueOption : optionLabels.falseOption}
            </label>
        </>
    );

    return (
        <fieldset className="toggle-switch" data-testid={dataTestId}>
            <div className="toggle">
                {renderToggle(dataTestId === "sentaraToggle")}
            </div>
        </fieldset>
    );
};

export default ToggleSwitch;
