import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IAutoCompleteItem } from '../interface/autocompleteModel';
import { prepHeaders } from './prepHeaders';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/api/v1/autocomplete/`;
const tag = 'AutoCompleteTag';

export const autoCompleteApi = createApi({
  reducerPath: 'autoCompleteApi',
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl, prepareHeaders: prepHeaders }),
  tagTypes: [tag],
  endpoints: (builder) => ({
    getPracticeNames: builder.query<IAutoCompleteItem[], string >({
        query: ( practiceNamePrefix ) => ({
          url: `getpracticenames?practiceNamePrefix=${practiceNamePrefix}`,
          method: 'GET',
        }),
        providesTags: [tag],
        transformResponse(res:any, meta, arg) {
          return res.data
        },
      }),
    getPracticeStates: builder.query<IAutoCompleteItem[], void>({
        query: () => ({
          url: `getpracticestates`,
          method: 'GET',
        }),
        providesTags: [tag],
        transformResponse(res:any, meta, arg) {
          return res.data;
        },
      }),
      getPracticeCities: builder.query<IAutoCompleteItem[], string>({
        query: ( cityPrefix ) => ({
          url: `getpracticecities?cityPrefix=${cityPrefix}`,
          method: 'GET',
        }),
        providesTags: [tag],
        transformResponse(res:any, meta, arg) {
          return res.data;
        },
      }),
      getLocationNames: builder.query<IAutoCompleteItem[], string>({
        query: ( locationPrefix ) => ({
          url: `getlocationnames?locationPrefix=${locationPrefix}`,
          method: 'GET',
        }),
        providesTags: [tag],
        transformResponse(res:any, meta, arg) {
          return res.data;
        },
      }),
      getPracticeZips: builder.query<IAutoCompleteItem[], string>({
        query: ( zipPrefix ) => ({
          url: `getpracticezips?zipPrefix=${zipPrefix}`,
          method: 'GET',
        }),
        providesTags: [tag],
        transformResponse(res:any, meta, arg) {
          return res.data;
        },
      }),
  }),
});

export const {
    useLazyGetPracticeNamesQuery,
    useGetPracticeStatesQuery,
    useLazyGetPracticeCitiesQuery,
    useLazyGetLocationNamesQuery,
    useLazyGetPracticeZipsQuery,

} = autoCompleteApi;