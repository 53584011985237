import React from 'react'
import './layout.scss'
import Header from '../header/header'
import Footer from '../footer/footer'
import Home from '../home/home'
import { useGetCurrentLoggedInUser, useGetAccessTokenString } from '../../auth-modules/hooks/authHooks'
import { useAppDispatch } from '../Hooks'
import { setAuth } from '../slices/authSlice'

const scopes = `${process.env.REACT_APP_AZUREAD_SCOPES}`.split(",") || [];

const Layout = () => {
  // Authoriztion & Authentication MSal Service
  const accountInfo = useGetCurrentLoggedInUser();
  const dispatch = useAppDispatch();
  const accessToken =  useGetAccessTokenString(scopes);

  if (accountInfo !== undefined) {
    dispatch(
      setAuth({ name: accountInfo.name as string, token: `${accessToken}` })
    );
  }

  return (
    <div>
      <div className='container' style={{ maxWidth: '100%' }}>
        <header>
          <Header />
        </header>
        <main>
          {accessToken && <Home/>}
        </main>
      </div>
    </div>
  );
}

export default Layout;