import React, { useEffect, useState } from 'react';
import { Input, Label, ModalOverlayWithoutClose, Button } from '@sentaraui/optimahealth_web';
import './searchLocationsStyles.scss';
import { useSearchLocationViewModel } from './useSearchLocationViewModel';
import AssignLocationModal from '../../../modal/assignLocation/assignLocationModal';
import { IStaffLocation, IStaffLocationItem } from '../../../interface/staff';
import Loader from '../../../common/spinner/loader';
import Table from '../../../common/Table/table';

export interface SearchLocationProps {
    staffId: number;
    practiceId: number;
    selectedStaffData: IStaffLocation;
    setIsModalOpen: (value: boolean) => void;
}

const SearchLocations: React.FC<SearchLocationProps> = ({
    staffId,
    practiceId,
    selectedStaffData,
    setIsModalOpen
}) => {
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

    const {
        selectedLocation,
        setSelectedLocation,
        fieldToHeaderMapping,
        inputChangeHandler,
        onSubmitHandler,
        checkboxHandler,
        onConfirmHandler,
        buttonClickHandler,
        handleModalClose,
        isModalOpen,
        searchLocationData,
        assignLocation,
        isSpinner
    } = useSearchLocationViewModel({
        practiceId,
        staffId,
        setSearchModel: setIsModalOpen,
    });

    const tableHeaders = Object.values(fieldToHeaderMapping);

    useEffect(() => {
        if (searchLocationData?.locations?.length > 0) {
            setIsRowSelected(false);
            setSelectedRowIndex(null);
        }
    }, [searchLocationData]);

    const handleRowSelect = (index: number, location: IStaffLocationItem) => {
        // Update the state and highlight the selected row
        setSelectedLocation(location)
        setIsRowSelected(true);
        setSelectedRowIndex(index);
        highlightTableRow(index);
    };

    function highlightTableRow(rowIndex: number) {
        // Remove highlight from previously selected row, if any
        const previouslySelectedRow = document.querySelector('tr.selected');
        if (previouslySelectedRow) {
            previouslySelectedRow.classList.remove('selected');
        }

        // Highlight the clicked row
        const tableRows = document.querySelectorAll(".search-location-table tr");
        if (tableRows[rowIndex + 1]) { // Ensure the row exists
            tableRows[rowIndex + 1].classList.add('selected');
        }
    }

    return (
        <>
            <div className='main-search-location-wrapper'>
                <div className='input-search-location'>
                    <div className='search-label-input'>
                        <Label
                            className="search-location-label"
                            htmlFor="name">
                            Name:
                        </Label>
                        <Input
                            className="form-input-search-location"
                            type="text"
                            name="locationName"
                            dataTestId="locationName"
                            placeholder="Location Name"
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className='search-location-input'>
                        <div className='search-location'>
                            <Label
                                className="search-location-label"
                                htmlFor="city">
                                City:
                            </Label>
                            <Input
                                className="form-input-search-location"
                                type="text"
                                name="city"
                                dataTestId="city"
                                placeholder="City"
                                onChange={inputChangeHandler}
                            />
                        </div>
                        <Label
                            className="search-location-label"
                            htmlFor="state">
                            State:
                        </Label>
                        <Input
                            className="form-input-state-input"
                            type="text"
                            name="state"
                            dataTestId="state"
                            placeholder="State"
                            onChange={inputChangeHandler}
                        />
                        <Label
                            className="search-location-label"
                            htmlFor="zip">
                            Zip:
                        </Label>
                        <Input
                            className="form-input-zip-input"
                            type="text"
                            name="zip"
                            dataTestId="zip"
                            placeholder="Zip"
                            onChange={inputChangeHandler}
                        />
                    </div>
                </div>
                <div className='location-button-wrapper'>
                    <Button
                        className="location-search-button"
                        data-testid="search-button"
                        onClick={onSubmitHandler}>
                        <Label className="location-button-text">Search</Label>
                    </Button>
                </div>
            </div>
            <div className='search-location-table-container'>
                <Table
                    className="search-location-table"
                    header={tableHeaders.map((headerText) => ({ text: headerText }))}
                    rows={searchLocationData?.locations?.map((location: IStaffLocationItem, index: number) =>
                        Object.keys(fieldToHeaderMapping).map((fieldKey) => {
                            const typedFieldKey = fieldKey as keyof IStaffLocationItem;
                            const value = location[typedFieldKey] || '';
                            return {
                                text: (
                                    <td
                                        key={fieldKey}
                                        data-testId={typedFieldKey}
                                        className={`location-item ${index === selectedRowIndex ? 'selected' : ''}`}
                                        onClick={() => handleRowSelect(index, location)}
                                    >
                                        {value}
                                    </td>
                                ),
                            };
                        })
                    )}
                    rowClickHandler={(rowIndex) => {
                        const selectedLocation = searchLocationData?.locations?.[rowIndex];
                        if (selectedLocation) {
                            handleRowSelect(rowIndex, selectedLocation);
                        }
                    }}
                />
            </div>
            <hr />
            <div className='ok-button'>
                <Button
                    className="btn"
                    disabled={!isRowSelected}
                    onClick={buttonClickHandler}
                >
                   <Label className='location-button-text'>Ok</Label>
                </Button>
            </div>
            <ModalOverlayWithoutClose
                isOpen={isModalOpen}
                onClose={handleModalClose}
                className="assign-location"
            >
                {isModalOpen && (
                    <AssignLocationModal
                        header="Assign Location"
                        message={`Are you sure you want to assign ${selectedStaffData.staffName} to ${selectedLocation?.locationName}?`}
                        onConfirm={onConfirmHandler}
                        onCancel={handleModalClose}
                        confirmText="Confirm"
                        checked={assignLocation?.isPrimaryLocation}
                        handleCheckboxInputChange={checkboxHandler}
                        
                    />
                )}
            </ModalOverlayWithoutClose>
            {isSpinner && <Loader />}
        </>
    );
};

export default SearchLocations;
