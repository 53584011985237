import * as AuthInterfaces from '../Interfaces/AuthInterfaces';

export const parseToken = async (token: string | undefined):Promise<AuthInterfaces.TokenParts | undefined> => {
    if (token && token !== "" && token !== "undefined" && token !== "null") {
        const token_parts = token.split(".");
        if (token_parts && token_parts.length === 3) {
            const token_header = JSON.parse(atob(token_parts[0]));
            const token_payload = JSON.parse(atob(token_parts[1]));
            return {
                header: token_header,
                payload: token_payload,
            };
        } else {
            throw new Error("Invalid token");
        }
    } else {
        return undefined;
    }
}

export const isInRoles = async (roles:string[], token:string):Promise<boolean> => {
    let tokenRoles: string[] = [];
    if(roles && roles.length > 0) {
        const tokenParts = await parseToken(token);
        tokenRoles = [...(tokenParts?.payload?.roles || [])];
        return (roles.some((role) => tokenRoles?.includes(role)) || false);
    }
    return false;
};

export const isExpired = async (token:string):Promise<boolean> => {
        const tokenParts = await parseToken(token);
        if(tokenParts?.payload?.exp) {
            return (Date.now() >= tokenParts.payload.exp * 1000);
        }
    return false;
};

