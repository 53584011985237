
import { useRemoveLocationMutation, useUpdateClinicianMutation, useUpdateLocationMutation } from "../services/staffApi";

export const useStaffApiRemoveLocationHook = () => {
    const [removeLocation, { data, error, isError,isSuccess }] = useRemoveLocationMutation() || {};

    return { removeLocation, data, error, isError,isSuccess };
};

export const useStaffApiUpdateLocationHook = () => {
    const [updateLocation, { data, error, isError,isSuccess }] = useUpdateLocationMutation()  || {};

    return { updateLocation, data, error, isError,isSuccess };
};
 

export const useStaffApiUpdateClinicianHook = () => {
    const [updateClinician, { data, error, isError,isSuccess }] = useUpdateClinicianMutation()  || {};

    return { updateClinician, data, error, isError,isSuccess };
};