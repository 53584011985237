import React from 'react';
import { Label, Input, PrimaryButton, Table } from '@sentaraui/optimahealth_web';
import './searchModalStyle.scss';
import { ILocation } from '../../models/location/location';
import { FindStaffJsonResult } from '../../interface/findStaffJsonResult';
import useSearchModalViewModel from './useSearchModalViewModel';
interface SearchModalProps {
    selectedLocationDetails: ILocation;
    setSelectedLocationDetails: (location: ILocation) => void;
    searchModalOpen: boolean;
    setSearchModalOpen: (searchModalOpen: boolean) => void;
    managerDetail: { managerType: number; typeName: string };
    onSelectItem: (name: string) => void;
    setManagerData: React.Dispatch<React.SetStateAction<FindStaffJsonResult[]>>; 
}

const SearchModal: React.FC<SearchModalProps> = ({
    selectedLocationDetails,
    setSelectedLocationDetails,
    searchModalOpen,
    setSearchModalOpen,
    managerDetail,
    onSelectItem,
    setManagerData,
}) => {
    const {
        searchResults,
        selectedRowIndex,
        searchName,
        searchClick,
        handleInputChange,
        handleSearch,
        handleRowClick
    } = useSearchModalViewModel({
        selectedLocationDetails,
        managerDetail,
        onSelectItem,
        setManagerData,
        searchModalOpen,
        setSearchModalOpen
    });

    const handleClose = () => {
        setSearchModalOpen(false);
    };

    return (
        <div className='search-modal-container'>
            <div className='top-container'>
                <div>Search for {managerDetail?.typeName}</div>
                <button data-testid='close-search-modal-button' onClick={handleClose}>x</button>
            </div>
            <div className='label-input-wrapper'>
                <div>
                    <Label className="search-label" htmlFor="firstName">
                        First Name:
                    </Label>
                    <Input
                        className="search-input"
                        type="text"
                        id="firstName"
                        name="firstName"
                        dataTestId="firstName_field"
                        onChange={handleInputChange}
                        value={searchName.firstName}
                    />
                </div>
                <div>
                    <Label className="search-label" htmlFor="lastName">
                        Last Name:
                    </Label>
                    <Input
                        className="search-input"
                        type="text"
                        id="lastName"
                        name="lastName"
                        dataTestId="lastName_field"
                        onChange={handleInputChange}
                        value={searchName.lastName}
                    />
                </div>
            </div>
            <hr />
            {
                searchClick && (
                    searchResults?.length > 0 ? (
                        <div className='search-table-container'>
                    <Table
                        className='search-table'
                        header={[
                            { text: 'Employee Name' },
                            { text: 'Job Title' },
                        ]}
                        rows={searchResults.map((item: FindStaffJsonResult, index: number) => [
                            {
                                text: (
                                    <div
                                        className={index === selectedRowIndex ? 'search-selected' : 'search-selected'}
                                        onClick={() => handleRowClick(index, item)}
                                    >
                                        {item.name}
                                    </div>
                                ),
                            },
                            {
                                text: <div>{item.jobTitle}</div>,
                            },
                        ])}
                    />
                    </div>
                ) : (
                    <div>
                        <Label
                            data-testid="no-data"
                            className="no-data"
                            htmlFor="html">
                            No Data Found!
                        </Label>
                    </div>
                )
            )}
            <div className='button-wrapper'>
                <PrimaryButton
                    className="primary-button"
                    id="search-button"
                    data-testid="search-button"
                    onClick={handleSearch}
                >
                    Search
                </PrimaryButton>
                <PrimaryButton
                    className="primary-button"
                    id="cancel-button"
                    data-testid="cancel-button"
                    onClick={handleClose}
                >
                    Cancel
                </PrimaryButton>
            </div>
        </div>
    );
};

export default SearchModal;
