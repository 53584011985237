import React, { useEffect, useState } from 'react';
import './clinicianInfoStyles.scss';
import {
    Input,
    Label,
    SelectDropdown,
    Checkbox,
    Loader
} from "@sentaraui/optimahealth_web";
import { IPracticeStaffMember } from '../../interface/staff';
import UploadImage from './uploadImage';
import { useMsal } from '@azure/msal-react';

interface IClinicianInfoProps {
    practiceStaffMember: IPracticeStaffMember | null;
    staffId: number;
}

const ClinicianInfo: React.FC<IClinicianInfoProps> = ({
    practiceStaffMember,
    staffId,
}) => {

    const { instance } = useMsal();
    const currentUser = instance.getActiveAccount();
    const userRoles = currentUser?.idTokenClaims?.roles || [];
    const hasPracticeAdminRole = userRoles.includes('SENTARA\\sg-sen-az-si_ptl_practice_admin');
    const hasOHWPracticeAdminRole = userRoles.includes('SENTARA\\sg-sen-az-si_ptl_ohw_practice_admin');
    let roleAdmin = hasPracticeAdminRole && hasOHWPracticeAdminRole ? true : false;

    const [isSpinner, setIsSpinner] = useState(true);

    useEffect(() => {
        if (practiceStaffMember) {
            setIsSpinner(false);
        }
    }, [practiceStaffMember]);

    function formatInput(input: string | undefined) {
        if (input === "") {
            return input;
        }
        if (input?.includes("(")) {
            return input;
        }
        if (input?.includes(" ")) {
            const parts = input.split(" ");
            const phoneNumber = parts[1];
            return formatPhoneNumber(phoneNumber);
        }
        return formatPhoneNumber(input);
    }

    function formatPhoneNumber(phone: string | undefined) {
        const phoneRegex = /^\d{10}$/;
        if (phoneRegex.test(phone!)) {
            return `(${phone?.slice(0, 3)}) ${phone?.slice(3, 6)}-${phone?.slice(6)}`;
        } else {
            return phone;
        }
    }

    return (
        <div className='manin-clinician-wrapper'>
            {isSpinner && <Loader />}
            {!isSpinner && (
                <div className='clinician-info-wrapper'>
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            Name:
                        </Label>
                        <Input
                            className="clinician-input"
                            type="text"
                            id="fname"
                            name="FirstName"
                            dataTestId="fname_field"
                            placeholder="First Name"
                            value={practiceStaffMember?.staffInfo.firstName}
                            disabled={true}
                        />
                        <Input
                            className="clinician-input"
                            type="text"
                            id="lname"
                            name="LastName"
                            dataTestId="lname_field"
                            placeholder="Last Name"
                            value={practiceStaffMember?.staffInfo.lastName}
                            disabled={true}
                        />
                        <Input
                            className="clinician-input-title"
                            type="text"
                            id="cli-title"
                            name="Title"
                            dataTestId="title"
                            placeholder="Title"
                            value={practiceStaffMember?.staffInfo.title}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            DOB:
                        </Label>
                        <Input
                            className="clinician-input-dob"
                            type="text"
                            id="dob"
                            name="Dob"
                            dataTestId="dob"
                            placeholder="DOB"
                            value={practiceStaffMember?.staffInfo.dob ? new Date(practiceStaffMember.staffInfo.dob).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : ''}
                            disabled={true}
                        />
                        <Label
                            className="clinician-label-gender"
                            htmlFor="html">
                            Gender:
                        </Label>
                        <SelectDropdown
                            value={practiceStaffMember?.staffInfo.gender}
                            className="clinician-dropdown"
                            id="Gender"
                            name="Gender"
                            disabled={true}
                        >
                            <option value={0}>Male</option>
                            <option value={1}>Female</option>
                        </SelectDropdown>
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            NPI:
                        </Label>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            {practiceStaffMember?.staffInfo?.npi}
                        </Label>
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            Role:
                        </Label>
                        <SelectDropdown
                            id="Role"
                            value={practiceStaffMember?.staffInfo.role}
                            name="Role"
                            className="clinician-dropdown"
                            disabled={true}
                        >
                            <option value={0}>None</option>
                            <option value={1}>Physician</option>
                            <option value={2}>Clinical</option>
                            <option value={3}>Business</option>
                            <option value={4}>Billing</option>
                            <option value={5}>Employee</option>
                            <option value={6}>Associates</option>
                            <option value={7}>Resident</option>
                        </SelectDropdown>
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            Phone:
                        </Label>
                        <Input
                            className="clinician-input"
                            type="text"
                            id="name"
                            name="name"
                            dataTestId="name_field"
                            placeholder="Phone Number"
                            value={formatInput(practiceStaffMember?.staffInfo?.phone)}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            Pager:
                        </Label>
                        <Input
                            className="clinician-input"
                            type="text"
                            id="pager"
                            name="PagerNo"
                            dataTestId="pager"
                            placeholder="PagerNo"
                            value={formatInput(practiceStaffMember?.staffInfo.pager)}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                    <div className='staff-phone-fax-wrapper'>
                        <div className='clinician-row'>
                            <Label
                                className="clinician-label"
                                htmlFor="html">
                                Staff Phone:
                            </Label>
                            <Input
                                className="clinician-input"
                                type="text"
                                id="staff-phone"
                                name="StaffPhone"
                                dataTestId="staff-phone"
                                placeholder="StaffPhone"
                                value={formatInput(practiceStaffMember?.staffInfo.staffPhone)}
                                disabled={true}
                            />
                        </div>
                        <div className='clinician-row'>
                            <Label
                                className="clinician-label-fax"
                                htmlFor="html">
                                Fax:
                            </Label>
                            <Input
                                className="clinician-input"
                                type="text"
                                id="fax"
                                name="FaxNo"
                                dataTestId="fax"
                                placeholder="FaxNo"
                                value={formatInput(practiceStaffMember?.staffInfo.fax)}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            Email:
                        </Label>
                        <Input
                            className="clinician-input-email"
                            type="Email"
                            id="email"
                            name="EmailAddress"
                            dataTestId="email"
                            placeholder="EmailAddress"
                            value={practiceStaffMember?.staffInfo.emailAddress}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            SQCN:
                        </Label>
                        <Checkbox
                            id="SQCN"
                            name="SQCN"
                            className="form-check-input mt-0"
                            type="checkbox"
                            dataTestId="SQCN"
                            children={practiceStaffMember?.staffInfo.sqcnmember ? "Member" : ""}
                            checked={practiceStaffMember?.staffInfo.sqcnmember}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                    <div className='clinician-row'>
                        <Label
                            className="clinician-label-pcp"
                            htmlFor="html">
                            Provider PCP:
                        </Label>
                        <Checkbox
                            id="ProviderPCP"
                            name='ProviderPCP'
                            className="form-check-input mt-0"
                            type="checkbox"
                            dataTestId="provider-pcp"
                            checked={practiceStaffMember?.staffInfo.providerPcpflag}
                            disabled={true}
                        />
                    </div>
                    <hr className='dotted-line' />
                    {roleAdmin && <div className='clinician-row'>
                        <Label
                            className="clinician-label"
                            htmlFor="html">
                            Marketing Override:
                        </Label>
                        <Checkbox
                            id="MarketingOverride"
                            name='MarketingOverride'
                            className="form-check-input mt-0"
                            type="checkbox"
                            dataTestId="marketing-override"
                            checked={practiceStaffMember?.staffInfo.mktOverride}
                            children={"(will disable data updates from physician credentialing)"}
                            disabled={true}
                        />
                        <hr className='dotted-line' />
                    </div>}
                </div>
            )}
            <div className='image-upload-wrapper'>
                <UploadImage staffId={staffId} photo={practiceStaffMember?.physicianInfo?.photo ?? ""} />
            </div>
        </div>
    );
};

export default ClinicianInfo;