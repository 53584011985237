import { IPracticeStaffMember } from "../../../interface/staff";
import PrivilegesTable from "./privilegesTable";
import { Button } from "@sentaraui/optimahealth_web";
import './privileges.scss';
interface PrivilegesProps {
    isVisible: boolean;
    setOpenComponent: (component: string) => void;
    practiceStaffMember: IPracticeStaffMember | null;
}
export const Privileges : React.FC<PrivilegesProps> = ({setOpenComponent, isVisible, practiceStaffMember }) => {

    return (
        <div>
            <Button
                className="staff-specialities-button"
                data-testid="staff-specialities-button"
                onClick={() => setOpenComponent('privileges')}

            >
                <span className={`staff-specialities-arrow ${isVisible ? 'down' : 'right'}`}>{isVisible ? '▼' : '▶'} Privileges</span>
            </Button>
            {isVisible && <PrivilegesTable practiceStaffMember={practiceStaffMember} />}
        </div>
    )
}