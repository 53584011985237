import { IStaffLocationItem } from "../../../interface/staff";
import { Label, Button } from "@sentaraui/optimahealth_web";
import "../../../location/locationDetails/locationDetailsStyle.scss";
import ToggleButton from '../../../toggleButton/toggleButton';
import { UseViewEditLocation } from "./useViewEditLocation";
import React from "react";
import {EditLocationButtons as actionTypes} from '../actionTypes';
import Loader from "../../../common/spinner/loader";

interface EditLocationProps {
    staffId:number;
    staffName:string;
    selectedLocation: IStaffLocationItem;
    onClose: () => void;
}

export const EditLocation: React.FC<EditLocationProps> = (props) => {
    const { handleButtonClick, handleToggleChange, isSpiner } = UseViewEditLocation(props.staffId,props.staffName,props.selectedLocation, props.onClose);
    
    return (
        <div>
            <div>
                <div className='toggle-wrapper'>
                    <Label className="location-label">
                        Primary location?
                    </Label>
                    <hr />
                    <ToggleButton
                        dataTestId="toggle_location"
                        value={props.selectedLocation?.isPrimaryLocation}
                        onToggle={(newValue) => handleToggleChange('primaryLocation', newValue)}
                    />
                </div>
            </div>
            <hr />
            <div className='button-wrapper'>
                {Object.values(actionTypes).map((action) => (
                    <div key={action}>
                        <Button
                            className="list-button"
                            id="primary"
                            data-testid={`staff-${action.toLowerCase()}-button`}
                            onClick={handleButtonClick(action)}
                        >
                            {action}
                        </Button>
                    </div>
                ))}
            </div>
            {isSpiner && <Loader/>}
        </div>
    );
};