import { useState, useEffect, useRef } from 'react';
import { useAddPracticeManagerMutation,useGetNewPracticeManagersByIdQuery } from '../services/practiceManagersApi';
import { PracticeManager } from '../interface/PracticeManager';
import ToastNotification from '../common/toast/toastNotification';
 
const NO_STAFF_ASSIGNMENTS_MESSAGE = "You can only make authoritative sources from assigned staff and this practice has no staff assignments.";
const ADD_PRE_SUCCESS_MESSAGE = "Authoritative Source: ";
const ADD_POST_SUCCESS_MESSAGE = " added successfully.";
const ADD_WARNING_MESSAGE = "You can have only one manager and one SQCN Authority assigned at a time.";
const ADD_FAILURE_MESSAGE = "Failed to add authoritative source.";
 
export const useNewAuthoritativeSourceViewModel = (practiceId: number | null, onClose: () => void) => {
    const { data, isLoading, refetch } = useGetNewPracticeManagersByIdQuery(practiceId);
    const newAuthoritativeSourceData = data ?? [];
    let allowNewPM = newAuthoritativeSourceData?.allowNewPM;
    let allowNewSQCN = newAuthoritativeSourceData?.allowNewSQCN;
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [selectedItem, setSelectedItem] = useState<PracticeManager | null>(null);
    const [addPracticeManager] = useAddPracticeManagerMutation();
    const [isItemSelected, setIsItemSelected] = useState(true);
    const toastShown = useRef(false);
 
    useEffect(() => {
        if (!isLoading && data) {
            if (data && data.count === 0  && !toastShown.current) {
                ToastNotification('info',NO_STAFF_ASSIGNMENTS_MESSAGE);
                toastShown.current = true;
                onClose();
            }

            if (!allowNewPM && !allowNewSQCN && !toastShown.current) {
                ToastNotification('error',ADD_WARNING_MESSAGE);
                toastShown.current = true;
                onClose();
            }
        } 
    }, [allowNewPM, allowNewSQCN, data, isLoading, onClose, toastShown]);

    useEffect(() => {
        refetch();
    }, [refetch]);
 
    const handleRowClick = (id: number, item: PracticeManager) => {
        setSelectedRow(id);
        setSelectedItem(item);
        setIsItemSelected(false);
    }
 
    const handleOkClick = async () => {
        if (practiceId === null || selectedItem === null) return;
 
        try {
            await addPracticeManager({ practiceId, managerId: selectedItem.personID, type: selectedItem.typeId}).unwrap();
            ToastNotification('success',`${ADD_PRE_SUCCESS_MESSAGE} ${selectedItem.lastName} ${ADD_POST_SUCCESS_MESSAGE}`);
            onClose();
        } catch (error) {
            ToastNotification('error',ADD_FAILURE_MESSAGE);
        }
    };
 
    const header = [
        { text: 'Last Name' },
        { text: 'First Name' }
    ];
 
    return {
        header,
        selectedRow,
        handleRowClick,
        handleOkClick,
        isItemSelected,
        newAuthoritativeSourceData
    };
};