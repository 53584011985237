import './staffScreen.scss';
import { ISearchFilter, IStaffSearch } from '../interface/staff';
import { Input, Button, Label } from '@sentaraui/optimahealth_web'
import useSearchViewModel from './useSearchViewModel';
import React from 'react';
import Loader from '../common/spinner/loader';
interface SearchProps {
    setSearchResults: React.Dispatch<React.SetStateAction<IStaffSearch[]>>;
    practiceId: number | null;
    isSkip: boolean;
    searchCriteria: ISearchFilter;
    setSearchCriteria: React.Dispatch<React.SetStateAction<ISearchFilter>>;
}
export const Search = ({ setSearchResults, practiceId, isSkip, searchCriteria, setSearchCriteria }: SearchProps) => {
    const { onChange, onSubmit, isSpinner, getErrorMessage, errors } = useSearchViewModel(practiceId, setSearchResults, isSkip, searchCriteria, setSearchCriteria);

    return (
        <div className='staff-form' id="staff-form">
            {isSpinner && <Loader />}
            <div className='input-wrapper'>
                <div className='input-row'>
                    <div className='first-last-name'>
                    <div className='staff-input-wrapper'>
                        <span className="menu-search-label">Last Name </span>
                        <Input
                            className="form-staff-input"
                            type="text"
                            id="lastName"
                            dataTestId="last-name"
                            placeholder="Last Name"
                            onChange={onChange}
                            value={searchCriteria.lastName}
                        />
                    </div>
                    <div className='staff-input-wrapper'>
                        <span className="menu-search-label">First </span>
                        <Input
                            className="form-staff-input"
                            type="text"
                            id="firstName"
                            dataTestId="first-name"
                            placeholder="First Name"
                            onChange={onChange}
                            value={searchCriteria.firstName}
                        />
                    </div>
                    </div>
                    <div className='first-last-name'>
                    <div className='staff-input-wrapper, ssn-label'>
                        <span className="menu-search-label">SSN </span>
                        <Input
                            className={`form-staff-input ${getErrorMessage('ssn') ? 'error-input' : ''}`}
                            type="text"
                            id="ssn"
                            dataTestId="ssn"
                            placeholder="SSN"
                            onChange={onChange}
                            value={searchCriteria.ssn}
                        />
                        {getErrorMessage('ssn') && <div className='error-message'>{getErrorMessage('ssn')}</div>}

                    </div>
                    <div className='staff-input-wrapper'>
                        <span className="menu-search-label">DOB </span>
                        <span>
                            <Input
                                className={`form-staff-input ${getErrorMessage('dob') ? 'error-input' : ''}`}
                                type="text"
                                id="dob"
                                placeholder="MM/DD/YYYY"
                                dataTestId="dob"
                                onChange={onChange}
                                value={searchCriteria.dob}
                            />
                        </span>
                        {getErrorMessage('dob') && <span className='error-message'>{getErrorMessage('dob')}</span>}

                    </div>
                    </div>
                </div>
            </div>
            <div className='button-wrapper'>
                <Button className='search-button' onClick={onSubmit} disabled={errors.length > 0}>
                    <Label className='btn-text'>Search</Label>
                </Button>
            </div>
        </div>


    )
}