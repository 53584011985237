import { IPracticeStaffMember } from "../../interface/staff";
import { useState } from "react";
import ExperienceInfo from "./experienceInfo/experienceInfo";
import { Privileges } from "./privileges/privileges";
import EducationView from "../../education/educationView";
import { ClinicalInterests } from "./clinicalInterests/clinicalInterests";
import { Language } from "./language/language";
import { Specialities } from "./specialities/specialities";

interface PhysicianDetailsProps {
    personId: number;
    practiceStaffMember: IPracticeStaffMember | null;
}

export const PhysicianDetails = ({ personId, practiceStaffMember }: PhysicianDetailsProps) => {
    const [openComponent, setOpenComponent] = useState<string>('education');
    const components = [
        { name: 'education', element: <EducationView personId={personId} setOpenComponent={setOpenComponent} isVisible={openComponent === 'education'} /> },
        { name: 'experience', element: <ExperienceInfo personId={personId} setOpenComponent={setOpenComponent} isVisible={openComponent === 'experience'} /> },
        { name: 'clinicalInterests', element: <ClinicalInterests personId={personId} setOpenComponent={setOpenComponent} isVisible={openComponent === 'clinicalInterests'} /> },
        { name: 'specialities', element: <Specialities personId={personId} setOpenComponent={setOpenComponent} isVisible={openComponent === 'specialities'} /> },
        { name: 'language', element: <Language personId={personId} setOpenComponent={setOpenComponent} isVisible={openComponent === 'language'} practiceStaffMember={practiceStaffMember} /> },
        { name: 'privileges', element: <Privileges setOpenComponent={setOpenComponent} isVisible={openComponent === 'privileges'} practiceStaffMember={practiceStaffMember} /> }
    ];
    return (
        <div>
            {components.map(({ name, element }) => (
                <div key={name}>
                    {element}
                </div>
            ))}
        </div>
    );
}