import { useEffect, useState } from 'react';
import { useGetStaffLocationQuery } from '../../services/staffApi';
import ToastNotification from '../../common/toast/toastNotification';
import { IStaffLocation, IStaffLocationItem } from '../../interface/staff';
import { EditLocation } from './editLocation/editLocation';
import { RemoveLocation } from './removeLocation/removeLocation';
import SearchLocations from './searchLocations/searchLocations';
import { ListLocationButtons as actionType } from './actionTypes';
import { initialStaffLocation } from '../../../constants/constants';

interface LocationViewModelProps {
    staffId: number;
    practiceId: number;
    staffName: string;
}
export const useLocationViewModel = ({ staffId, practiceId, staffName }: LocationViewModelProps) => {
    const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStaffData, setSelectedStaffData] = useState<IStaffLocation>({} as IStaffLocation)
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<IStaffLocationItem>(initialStaffLocation);
    const [modalTitle, setModalTitle] = useState<string>('');
    const { data, error } = useGetStaffLocationQuery({ staffId: staffId, practiceId: practiceId });
    const fieldToHeaderMapping = {
        'locationName': 'LocationName',
        'address1': 'Address1',
        'address2': 'Address2',
        'city': 'City',
        'state': 'State',
        'zip': 'Zip',
        'phone': 'Phone',
        'fax': 'Fax',
        'isPrimaryLocation': 'Prim Loc',
        'isPrimaryPractice': 'Prim Prc'
    };

    const handleButtonClick = (action: actionType ) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (action === actionType.Assign) {
            setModalTitle('Search Locations');
            setModalContent(<div>
                <SearchLocations
                    staffId={staffId}
                    practiceId={practiceId}
                    selectedStaffData={selectedStaffData}
                    setIsModalOpen ={setIsModalOpen}
                />
            </div>);
        }
        else if (action === actionType.Edit) {
            setModalTitle('Edit Location');
            setModalContent(<EditLocation staffId={staffId} staffName={staffName} selectedLocation={selectedLocation} onClose={() => setIsModalOpen(false)} />);
        }
        else if (action === actionType.Remove) {
            setModalTitle('Remove Location');
            setModalContent(<RemoveLocation staffId={staffId} staffName={staffName} onClose={() => setIsModalOpen(false)} selectedLocation={selectedLocation} /> );
        }
        setIsModalOpen(true);
    };
    useEffect(() => {
        if (error) {
            let message = "";
            if (error.hasOwnProperty('error')) {
                message = (error as any).error;
            } else if (error.hasOwnProperty('data')) {
                message = (error as any).data?.error;
            }
            ToastNotification("error", message);
        }
    }, [error]);
    return {
        data,
        fieldToHeaderMapping,
        modalContent,
        isModalOpen,
        selectedStaffData,
        selectedRowIndex,
        selectedLocation,
        modalTitle,
        handleButtonClick,
        setModalContent,
        setIsModalOpen,
        setSelectedStaffData, setSelectedRowIndex, setSelectedLocation,
        setModalTitle,
        error
    };
}