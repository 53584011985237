import React from "react";
import {
  Label,
  Input,
  SearchIcon,
  ModalOverlayWithoutClose,
  Image,
} from "@sentaraui/optimahealth_web";
import SearchModal from "../searchModal/searchModal";
import ConfirmationModal from "../../modal/confirmationModal";
import useManagersTabViewModel from "./useManagersTabViewModel";

import "./managersTabStyle.scss";
import { ILocation } from "../../models/location/location";
interface ManagersTabProps {
  selectedLocationDetails: ILocation;
  setSelectedLocationDetails: (location: ILocation) => void;
  inputValues: { [key: string]: string };
  setInputValues: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
}

const ManagersTab: React.FC<ManagersTabProps> = ({
  selectedLocationDetails,
  setSelectedLocationDetails,
  inputValues,
  setInputValues,
}) => {
  const {
    searchModalOpen,
    isModalOpen,
    showModal,
    managerDetail,
    setSearchModalOpen,
    setIsModalOpen,
    setShowModal,
    setManagerData,
    searchIconClickHandler,
    onCloseHandler,
    onDeleteHandler,
    handleSelectItem,
    handleDeleteConfirm,
  } = useManagersTabViewModel({
    selectedLocationDetails,
    setSelectedLocationDetails,
    inputValues,
    setInputValues,
  });

  return (
    <>
      <div>
        {selectedLocationDetails &&
          selectedLocationDetails.smgManagerListItems.map((manager, key) => (
            <div className="managers-wappper" key={manager.value}>
              <div className="manager-label-input">
                <Label className="managers-label" htmlFor={key}>
                  {manager.text}:
                </Label>
                <Input
                  className="managers-form-input"
                  type="text"
                  id={key}
                  role="managerInput"
                  data-testid="managerInput"
                  disabled={true}
                  value={manager.managerDetails?.name ?? ""}
                />
              </div>
              <button
                className={
                  manager.managerDetails?.name
                    ? "delete-icon"
                    : "search-icon-wrapper"
                }
                data-testid={
                  manager.managerDetails?.name ? "deleteIcon" : "searchIcon"
                }
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                onClick={() => {
                  const id = manager.managerDetails?.smgManagerTypeId ?? 0;
                  const text = manager.text || "";
                  const value = manager.value ?? 0;

                  if (manager.managerDetails?.name) {
                    onDeleteHandler(id, text, "");
                  } else {
                    searchIconClickHandler(value, text, "");
                  }
                }}
              >
                {manager.managerDetails?.name ? (
                  <Image
                    alt="delete-icon"
                    className="delete-icon"
                    path="images/delete-icon.png"
                  />
                ) : (
                  <SearchIcon />
                )}
              </button>
            </div>
          ))}
      </div>

      <ModalOverlayWithoutClose
        isOpen={searchModalOpen}
        onClose={onCloseHandler}
        className="search-modal"
        data-testid="search-modal"
      >
        <SearchModal
          selectedLocationDetails={selectedLocationDetails}
          setSelectedLocationDetails={setSelectedLocationDetails}
          searchModalOpen={searchModalOpen}
          setSearchModalOpen={setSearchModalOpen}
          managerDetail={managerDetail}
          onSelectItem={handleSelectItem}
          setManagerData={setManagerData}
        />
      </ModalOverlayWithoutClose>
      <ModalOverlayWithoutClose
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="remove-smg-modal"
      >
        {showModal && (
          <ConfirmationModal
            header="Remove SMG Manager"
            message={`Would you like to remove ${
              managerDetail.managerType ?? ""
            } as ${managerDetail?.typeName ?? ""} from this location?`}
            onConfirm={handleDeleteConfirm}
            onCancel={() => {
              setShowModal(false);
              setIsModalOpen(false);
            }}
            confirmText="Yes"
            cancelText="No"
          />
        )}
      </ModalOverlayWithoutClose>
    </>
  );
};
export default ManagersTab;
