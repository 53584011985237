import React, { useState, useEffect, useCallback } from 'react';
import './toggleButtonStyle.scss';

interface ToggleButtonProps {
    value?: boolean;
    onToggle?: (value: boolean) => void;
    dataTestId?: string;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
    value = false,
    onToggle = () => {},
    dataTestId,
}) => {
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    const handleClick = useCallback(() => {
        const newValue = !internalValue;
        setInternalValue(newValue);
        onToggle(newValue);
    }, [internalValue, onToggle]);

    return (
        <div
            className={`toggle-button-container ${internalValue ? 'active' : ''}`}
            onClick={handleClick}
            data-testid={dataTestId}
            aria-pressed={internalValue}
        >
            <div className={`toggle-label no ${!internalValue ? 'active' : ''}`} data-testid="no-label">No</div>
            <div className={`toggle-button ${internalValue ? 'active' : ''}`} data-testid="toggle-button">
                <div className="toggle-thumb" />
            </div>
            <div className={`toggle-label yes ${internalValue ? 'active' : ''}`} data-testid="yes-label">Yes</div>
        </div>
    );
};

export default ToggleButton;
