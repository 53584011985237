import React from 'react';
import './about.scss';
import { Image } from '@sentaraui/optimahealth_web';
import { AboutProps } from '../interface/home';
import { useMsal } from '@azure/msal-react';

export const About: React.FC<AboutProps> = ({ onClose }) => {
    const { instance } = useMsal();
    const currentUser = instance.getActiveAccount();
    
    if (!currentUser) {
        return null;
    }
    const userRoles = currentUser?.idTokenClaims?.roles || [];
    const hasPracticeAdminRole = userRoles.includes('SENTARA\\sg-sen-az-si_ptl_practice_admin');
    const hasOHWPracticeAdminRole = userRoles.includes('SENTARA\\sg-sen-az-si_ptl_ohw_practice_admin');
    
    let roleMessage = hasPracticeAdminRole ? 'Practice Admin' : 'Manager';
    if (roleMessage == 'Manager')
    {
        roleMessage = hasOHWPracticeAdminRole ? 'Practice Admin' : 'Manager';
    }

    return (
        <div className='about-modal-header'>
            <div className='about-titlebar' id='title'>
                <span className='ui-dialog-title'>About Practice Manager Tool</span>
                <button id='btn-close' onClick={onClose}>x</button>
            </div>
            <div className='about-content-wrapper'>
                <div className='about-inner-wrapper'>
                    <span>
                        <Image
                            alttext="sentara-logo"
                            className="sentara-logo"
                            dataTestId="imgID"
                            path='images/mdOffice_logo.png'
                        />
                    </span>
                    <span className='about-inner-text'>
                        Practice Manager Tool
                        <br />
                        Version: {currentUser?.idTokenClaims?.ver}<br />
                        2013, 2014 Sentara Healthcare<br />
                        All rights reserved<br />
                    </span>
                </div>
                <hr />
                <span className='bottom-text'>Operated By: {currentUser?.name} as {roleMessage}</span>
            </div>
            <hr />
            <div className='button-wrapper'>
                <button className="button" onClick={onClose}>Ok</button>
            </div>
        </div>
    );
};