import { useState } from 'react';
import { useAddSmgManagerForLocationMutation, useFindStaffMutation } from '../../services/locationApi';
import { FindStaffJsonResult } from '../../interface/findStaffJsonResult';
import { ILocation } from '../../models/location/location';
import ToastNotification from '../../common/toast/toastNotification';
import { HandleErrorMessage } from '../../common/errorMessage';

interface ManagerDetail {
    managerType: number;
    typeName: string;
}

interface SearchModalViewModelProps {
    selectedLocationDetails: ILocation;
    managerDetail: ManagerDetail;
    onSelectItem: (name: string) => void;
    setManagerData: React.Dispatch<React.SetStateAction<FindStaffJsonResult[]>>; 
    searchModalOpen: boolean;
    setSearchModalOpen: (searchModalOpen: boolean) => void;
}

const useSearchModalViewModel = ({
    selectedLocationDetails,
    managerDetail,
    onSelectItem,
    setManagerData,
    searchModalOpen,
    setSearchModalOpen,
}: SearchModalViewModelProps) => {
    const [findStaff, { isLoading}] = useFindStaffMutation();
    const [searchResults, setSearchResults] = useState<FindStaffJsonResult[]>([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
    const [searchName, setSearchName] = useState({ firstName: '', lastName: '' });
    const [addSmgManagerForLocation] = useAddSmgManagerForLocationMutation();
    const [searchClick, setSearchClick] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSearchName(prevState => ({ ...prevState, [name]: value }));
        setSearchClick(false);
    };

    const handleSearch = async () => {
        setSearchClick(true);
        if (searchName.firstName || searchName.lastName) {
            try {
                const result = await findStaff({ fname: searchName.firstName, lname: searchName.lastName }) as any;
                setSearchResults(result.data as FindStaffJsonResult[]);
            } catch (error) {
                console.error('Error occurred while fetching staff:', error);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleRowClick = async (index: number, item: FindStaffJsonResult) => {
        setSelectedRowIndex(index);
        onSelectItem(item.name);
        try {
            // Payload for adding manager
            const data: any ={
                locationId: selectedLocationDetails.locationID,
                personId: item.personId,
                managerType: managerDetail.managerType,
                name: item.name,
                typeName: managerDetail.typeName
            };

            const response:any = await addSmgManagerForLocation(data).unwrap(); 
            setManagerData((prev) => [...prev, item]);
            setSearchModalOpen(false);
            setSearchClick(false);
            ToastNotification('success',response);
            setSearchModalOpen(false);
        } catch (error: unknown) {
            if (error instanceof Error) {
                HandleErrorMessage(error, 'add smg manager location');
            } else {
                HandleErrorMessage(new Error('An unknown error occurred'), 'add smg manager location');
            }
        }
    };
    return {
        isLoading,
        searchResults,
        selectedRowIndex,
        searchName,
        searchClick,
        handleInputChange,
        handleSearch,
        handleRowClick
    };
};

export default useSearchModalViewModel;
