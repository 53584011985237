import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { IEditPractice } from "../../../interface/Practice";
import { useGetSuperGroupsQuery } from "../../../services/practicesApi";
import { SuperGroupItem } from "../../../interface/PracticesLists";

interface EditPracticeViewModelProps {
    editPracticeData: IEditPractice | null;
    setEditPracticeData: (editPracticeData: IEditPractice) => void;
}
export const useEditPracticeViewModel = ({ editPracticeData, setEditPracticeData }: EditPracticeViewModelProps) => {
    const { data } = useGetSuperGroupsQuery();
    const [superGroupData, setSuperGroupData] = useState<SuperGroupItem[]>([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const superGroups = data ?? [];
    // ToDo: Need to get the practice types from the API
    const practiceTypes = [
        { value: '1', label: 'Hospital' },
        { value: '2', label: 'Practice' },
        { value: '3', label: 'PhysicianOffice' },
        { value: '4', label: 'UrgentCareCenter' },
        { value: '5', label: 'Billing' },
    ];

    useEffect(() => {
        if (superGroups?.length) {
            setSuperGroupData(superGroups);
        }
    }, [superGroups]);
    
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (editPracticeData) {
            setEditPracticeData({
                ...editPracticeData,
                [name]: value
            });
        }
    };

    const handleSelectChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            const selectedGroupId = Number(e.target.value);
            // Find the selected super group based on the selected ID
            const selectedSuperGroup = superGroupData?.find(group => group.superGroupID === selectedGroupId);
    
            // Update the edit practice data with the selected super group's details
            if (editPracticeData) {
                setEditPracticeData({
                    ...editPracticeData,
                    superGroupId: selectedGroupId,
                    superGroupName: selectedSuperGroup?.superGroupName ?? 'No Assignment',
                });
            }
        },
        [superGroupData, editPracticeData, setEditPracticeData] // dependencies
    );
    
    
    const handlePracticeTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        const selectedType = practiceTypes.find(type => type.value === selectedValue);
        if (editPracticeData) {
            setEditPracticeData({
                ...editPracticeData,
                practiceType: selectedValue ?? '0',
                description: selectedType?.label ?? '',
            });
        }
    };

    return {
        practiceTypes,
        editPracticeData,
        superGroupData,
        handleInputChange,
        handleSelectChange,
        handlePracticeTypeChange,
    };
}