import React, { FC } from 'react';
import './sqcnTabStyles.scss';
import {
    Input,
    Label,
    SelectDropdown,
    Checkbox,
    DatePicker
} from '@sentaraui/optimahealth_web';
import { IEditPractice } from '../../../interface/Practice';
import { useSqcnTabViewModel } from './useSqcnTabViewModel';

interface SqcnTabProps {
    editPracticeData: IEditPractice | null;
    setEditPracticeData: (editPracticeData: IEditPractice) => void;
}

const SqcnTab: FC<SqcnTabProps> = ({ editPracticeData, setEditPracticeData }) => {
    const {
        handleDateChange,
        handleInputChange,
        handleEmrChange,
        handleMcKessonChange,
        handleDeactivationChange,
        mcKessons,
        emrListItems,
        deactivationListItems,
        handleCheckboxInputChange,
    } = useSqcnTabViewModel({ editPracticeData, setEditPracticeData });

    return (
        <div className='edit-sqcn-wrapper'>
            <div className='edit-sqcn-row'>
                <Label
                    className="edit-sqcn-label"
                    htmlFor="mcKessonOrgId">
                    McKesson Org ID:
                </Label>
                <Input
                    className="edit-sqcn-input"
                    type="text"
                    id="mcKessonOrgId"
                    name="mcKessonOrgId"
                    dataTestId="input_field"
                    onChange={handleInputChange}
                    placeholder="Mckesson Org ID"
                    value={editPracticeData?.practiceSqcn?.mcKessonOrgId ?? ''}
                />
            </div>
            <div className='edit-sqcn-row'>
                <Label
                    className="edit-sqcn-label"
                    htmlFor="mcKessonSpecialty">
                    McKesson Specialty:
                </Label>
                <SelectDropdown
                    name="mcKessons"
                    value={mcKessons[0]?.value}
                    onChange={handleMcKessonChange}
                    className="edit-sqcn-dropdown"
                    dataTestId="mcKessons"
                >
                    {editPracticeData?.practiceSqcn?.mcKessons?.map(type => (
                        <option key={type.value} value={type.value}>
                            {type.text}
                        </option>
                    ))}
                </SelectDropdown>
            </div>
            <div className='edit-checkbox-row'>
                <Label
                    className="edit-sqcn-label"
                    htmlFor="sqcnStatus">
                    SQCN Status:
                </Label>
                <Checkbox
                    id="claimStatus"
                    name="claimStatus"
                    className="form-check-input mt-0"
                    type="checkbox"
                    disabled={false}
                    dataTestId="claimStatus"
                    checked={editPracticeData?.practiceSqcn?.claimStatus ?? false}
                    onChange={handleCheckboxInputChange}
                />
            </div>
            <div className='edit-checkbox-row'>
                <Label
                    className="edit-sqcn-label"
                    htmlFor="affiliate">
                    Affiliate:
                </Label>
                <Checkbox
                    id="affiliate"
                    name="affiliate"
                    className="form-check-input mt-0"
                    type="checkbox"
                    disabled={false}
                    dataTestId="affiliate"
                    checked={editPracticeData?.practiceSqcn?.affiliate ?? false} 
                    onChange={handleCheckboxInputChange}
                />
            </div>
            <div className='edit-checkbox-row'>
                <Label
                    className="edit-sqcn-label"
                    htmlFor="html">
                    837 Status:
                </Label>
                <Checkbox
                    id="EDI837Status"
                    name="edI837Status"
                    className="form-check-input mt-0"
                    type="checkbox"
                    disabled={false}
                    dataTestId="EDI837Status"
                    checked={editPracticeData?.practiceSqcn?.edI837Status ?? false}
                    onChange={handleCheckboxInputChange}
                />
            </div>
            <div className='edit-checkbox-row'>
                <Label
                    className="edit-sqcn-label"
                    htmlFor="html">
                    EMR Status:
                </Label>
                <Checkbox
                    id="EMRStatus"
                    name="emrStatus"
                    className="form-check-input mt-0"
                    type="checkbox"
                    disabled={false}
                    dataTestId="EMRStatus"
                    checked={editPracticeData?.practiceSqcn?.emrStatus ?? false}
                    onChange={handleCheckboxInputChange}
                />
            </div>
            <div className='edit-sqcn-row'>
                <Label
                    className="edit-sqcn-label"
                    htmlFor="EMRVendor">
                    EMR Vendor:
                </Label>
                <SelectDropdown
                    name="emrListItems"
                    value={emrListItems[0]?.value} 
                    onChange={handleEmrChange} 
                    className="edit-sqcn-dropdown"
                    dataTestId="emrListItems"
                >
                    {editPracticeData?.practiceSqcn?.emrListItems?.map(type => (
                        <option key={type.value} value={type.value}>
                            {type.text}
                        </option>
                    ))}
                </SelectDropdown>
            </div>
            <div className='edit-date-picker'>
                <Label
                    className="edit-sqcn-label"
                    htmlFor="html">
                    Activation Date:
                </Label>
                <DatePicker
                    className="custom-date-picker"
                    name="activationDate"
                    dataTestId="activation-date-picker"
                    placeholder="Select Date"
                    selectedValue={editPracticeData?.practiceSqcn?.activationDate ? new Date(editPracticeData?.practiceSqcn?.activationDate).toLocaleDateString('en-CA') : ''}
                    updateValue={(date: Date) => handleDateChange(date, 'activationDate')}
                />
            </div>
            <div className='edit-date-picker'>
                <Label
                    className="edit-sqcn-label"
                    htmlFor="deactivationDate">
                    Deactivation Date:
                </Label>
                <DatePicker
                    className="custom-date-picker"
                    name="deactivationDate"
                    dataTestId="deactivation-date-picker"
                    placeholder="Select Date"
                    selectedValue={editPracticeData?.practiceSqcn?.deactivationDate ? new Date(editPracticeData?.practiceSqcn?.deactivationDate).toLocaleDateString('en-CA') : ''}
                    updateValue={(date: Date) => handleDateChange(date, 'deactivationDate')}
                />
            </div>
            <div className='edit-sqcn-row'>
                <Label
                    className="edit-sqcn-label"
                    htmlFor="deactivationReason">
                    Deactivation Reason:
                </Label>
                <SelectDropdown
                    name="deactivationListItems"
                    value={deactivationListItems[0]?.value}
                    onChange={handleDeactivationChange} 
                    className="edit-sqcn-dropdown"
                    dataTestId="deactivationListItems"
                >
                    {editPracticeData?.practiceSqcn?.deactivationListItems?.map(type => (
                        <option key={type.value} value={type.value}>
                            {type.text}
                        </option>
                    ))}
                </SelectDropdown>
            </div>
            <div className='edit-checkbox-row'>
                <Label
                    className="edit-sqcn-label"
                    htmlFor="Deferred">
                    Deferred:
                </Label>
                <Checkbox
                    id="deferred"
                    name="deferred"
                    className="form-check-input mt-0"
                    type="checkbox"
                    disabled={false}
                    dataTestId="deferred"
                    checked={editPracticeData?.practiceSqcn?.deferred ?? false}
                    onChange={handleCheckboxInputChange}
                />
            </div>
        </div>
    )
};
export default SqcnTab;